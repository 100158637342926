const DatePickerIcon = () => {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0C4.41421 0 4.75 0.335786 4.75 0.75V2H9.25V0.75C9.25 0.335786 9.58579 0 10 0C10.4142 0 10.75 0.335786 10.75 0.75V2H12C13.1046 2 14 2.89543 14 4V12C14 13.1046 13.1046 14 12 14H2C0.89543 14 0 13.1046 0 12V4C0 2.89543 0.895431 2 2 2H3.25V0.75C3.25 0.335786 3.58579 0 4 0ZM9.25 3.5V4.25C9.25 4.66421 9.58579 5 10 5C10.4142 5 10.75 4.66421 10.75 4.25V3.5H11.5C12.0523 3.5 12.5 3.94772 12.5 4.5V6C12.5 6.55228 12.0523 7 11.5 7H2.5C1.94771 7 1.5 6.55228 1.5 6V4.5C1.5 3.94772 1.94772 3.5 2.5 3.5H3.25V4.25C3.25 4.66421 3.58579 5 4 5C4.41421 5 4.75 4.66421 4.75 4.25V3.5H9.25Z"
        fill="#333333"
      />
    </svg>
  )
}

export default DatePickerIcon