import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addGarmentTypes,
  deleteGarmentTypes,
  getGarmentTypesList,
  updateGarmentTypes,
} from "api/garmentTypes.api";
import { notificationController } from "controllers/notificationController";
import { GarmentTypes, GarmentTypesRequest } from "domain/GarmentTypes";

export interface GarmentTypesSlice {
  garmentTypesList: GarmentTypes[] | null;
}

const initialState: GarmentTypesSlice = {
  garmentTypesList: null,
};

export const doGetGarmentTypes = createAsyncThunk(
  "garmentTypes/doGetGarmentTypes",
  async (args: any) =>
    getGarmentTypesList(args).then((res: any) => {
      return res;
    })
);

export const doAddGarmentTypes = createAsyncThunk(
  "garmentTypes/doAddGarment",
  async (professionalPayload: GarmentTypesRequest, { dispatch }) =>
    addGarmentTypes(professionalPayload).then((res) => {
      dispatch(doGetGarmentTypes(false));
      return res;
    })
);
export const doUpdateGarmentTypes = createAsyncThunk(
  "garmentTypes/doUpdateGarment",
  async (professionalPayload: any, { dispatch }) =>
    updateGarmentTypes(professionalPayload.id, professionalPayload).then(
      (res) => {
        dispatch(doGetGarmentTypes(false));
        return res;
      }
    )
);

export const doDeleteGarmentTypes = createAsyncThunk(
  "garmentTypes/doDeleteGarmentTypes",
  async (garmentId: any, { dispatch }) => {
    deleteGarmentTypes(garmentId).then(
      (res) => {
        notificationController.error({
          message: "Garment Type Deleted successfully.",
        });
        dispatch(doGetGarmentTypes(false));
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);

const garmentTypesSlice = createSlice({
  name: "garmentTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetGarmentTypes.fulfilled, (state, action) => {
      state.garmentTypesList = action.payload.data;
    });
  },
});

export default garmentTypesSlice.reducer;
