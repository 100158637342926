import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createShippingDocument,
  deleteShipmentDoc,
  getBatchItems,
  getBatchesOfShipment,
  getShippingDocument,
  getShippingDocumentDetails,
  getShippingDocumentDetailsById,
} from "api/shippingV2.api";
import { notificationController } from "controllers/notificationController";

export interface ShippingSlice {
  shipping_document_list: any;
  shipping_batch_items: any;
  shipping_batch_items_loaded: boolean;
  shipping_document_details: any;
  shipping_document_list_loaded: boolean;
  allow_barcode_Scanning: boolean;
  rfid_Scanner_connected: boolean;
  shipment_list_update_flag: boolean
}

const initialState: ShippingSlice = {
  shipping_document_list: [],
  shipping_document_list_loaded: false,
  shipping_document_details: null,
  shipping_batch_items: [],
  shipping_batch_items_loaded: false,
  allow_barcode_Scanning: false,
  rfid_Scanner_connected: true,
  shipment_list_update_flag: false
};

export const doGetShippingDocument = createAsyncThunk(
  "shippingDocument/doGetShippingDocument",
  async (data: any, { dispatch }) =>
    getShippingDocument(data).then((res: any) => {
      return res;
    })
);
export const doGetBatchItems = createAsyncThunk(
  "shippingDocument/doGetBatchItems",
  async ({ id, batchNumber }: { id: string, batchNumber: string }) => {
    const res = await getBatchItems(id);
    return [...res, { batchNumber }];
  }
);

export const doGetShippingDocumentDetails = createAsyncThunk(
  "shippingDocument/doGetShippingDocumentDetails",
  async (id) =>
    getShippingDocumentDetails(id).then((res: any) => {
      return res;
    })
);
export const doGetShippingDocumentDetailsById = createAsyncThunk(
  "shippingDocument/doGetShippingDocumentDetailsById",
  async (id: any) =>
    getShippingDocumentDetailsById(id).then((res: any) => {
      return res;
    })
);

export const doGetDocumentDetails = createAsyncThunk(
  "shippingDocument/doGetDocumentDetails",
  async (id: any) =>
    getShippingDocumentDetails(id).then((res: any) => {
      return res;
    })
);

export const doGetBatchesOfDocument = createAsyncThunk(
  "shippingDocument/doGetNewScansOfDocument",
  async (id: any, { dispatch }) =>
    getBatchesOfShipment(id).then((res: any) => {
      return res;
    })
);

// export const doDeleteShipmentDoc = createAsyncThunk(
//   "shippingDocument/doDeleteShipmentDoc",
//   async (id: any, { dispatch }) =>
//     deleteShipmentDoc(id).then(
//       (res) => {
//         dispatch(doGetShippingDocument());
//         return res;
//       },
//       (error) => {
//         notificationController.error({
//           message: error.toString(),
//         });
//       }
//     )
// );

// export const doCreateShippingDocument = createAsyncThunk(
//   "auth/doCreateShippingDocument",
//   async (shipmentPayload: any, { dispatch }) =>
//     createShippingDocument(shipmentPayload).then((res: any) => {
//       dispatch(doGetShippingDocument());
//       return res;
//     })
// );

export const updateAllowBarcodeScanState = createAsyncThunk(
  "shippingDocument/updateAllowBarcodeScanState",
  async (scanState: boolean, { dispatch }) => {
    dispatch(setAllowBarcodeScanState(scanState));
  }
);
export const updateRfidScannerConnected = createAsyncThunk(
  "shippingDocument/updateRfidScannerConnected",
  async (scanState: boolean, { dispatch }) => {
    dispatch(setRfidScannerConnected(scanState));
  }
);

export const clearShippingDocumentDetails = createAction('shippingDocumentDetails/clear');
export const clearShippingBatchItems = createAction('shippingBatchItems/clear');

const shippingSlice = createSlice({
  name: "shippingDocument",
  initialState,
  reducers: {
    setAllowBarcodeScanState: (state, action) => {
      state.allow_barcode_Scanning = action.payload;
    },
    setRfidScannerConnected: (state, action) => {
      state.rfid_Scanner_connected = action.payload;
    },
    toggleShipmentListUpdateFlag: (state) => {
      state.shipment_list_update_flag = !state.shipment_list_update_flag;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(doGetShippingDocument.fulfilled, (state, action) => {
      state.shipping_document_list = action.payload;
      state.shipping_document_list_loaded = true;
    });
    builder.addCase(doGetBatchItems.fulfilled, (state, action) => {
      state.shipping_batch_items = action.payload;
      state.shipping_batch_items_loaded = true;
    });
    builder.addCase(doGetShippingDocumentDetailsById.fulfilled, (state, action) => {
      state.shipping_document_details = action.payload;
    });
    builder.addCase(clearShippingDocumentDetails, (state) => {
      state.shipping_document_details = null;
    });
    builder.addCase(clearShippingBatchItems, (state) => {
      state.shipping_batch_items = null;
    });
  },
});
export const { setAllowBarcodeScanState, setRfidScannerConnected, toggleShipmentListUpdateFlag } = shippingSlice.actions;
export default shippingSlice.reducer;
