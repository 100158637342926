import moment from "moment";
import { httpApi } from "api/http.api";
import {
  PriceListItems,
  PriceListItemsRequest,
  PriceListItemsResponse,
} from "domain/PriceListItems";

/**
 *
 * @returns PriceListItems[]
 */
export const getPriceListItemsList = (): Promise<PriceListItems[]> =>
  httpApi.get<PriceListItems[]>("PriceListItems").then(({ data }) => data);

export const addPriceListItems = (
  garmentsPayload: PriceListItemsRequest
): Promise<PriceListItemsResponse> =>
  httpApi
    .post<PriceListItemsResponse>("PriceListItems", {
      ...garmentsPayload,
    })
    .then(({ data }) => data);

export const updatePriceListItems = (
  id: any,
  priceListItemsPayload: PriceListItemsRequest
): Promise<PriceListItemsResponse> =>
  httpApi
    .put<PriceListItemsRequest>("PriceListItems/" + id, {
      ...priceListItemsPayload,
    })
    .then(({ data }) => data);

export const getPriceListItemsById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`PriceListItems/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const deletePriceListItems = async (
  id: number
): Promise<PriceListItemsResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<PriceListItemsResponse>(
    `PriceListItems/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<PriceListItemsResponse>(`PriceListItems/${id}`, {
    data: { hmac_time: time,  hmac: hmac_token?.data?.hmac },
  });
};


export const getPriceListItemTypesList = (): Promise<PriceListItems[]> =>
  httpApi.get<PriceListItems[]>("PriceListItemTypes").then((data: any) => data.data).then(({ data}) => data);


