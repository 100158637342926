import React from "react";
import styled, { useTheme } from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";
interface LoadingProps {
  size?: string;
  color?: string;
}

export const Loading: React.FC<LoadingProps> = ({ size, color }) => {
  const spinnerColor = color || "#0ff000";

  return (
    <SpinnerContainer>
      <LoadingOutlined style={{ fontSize: "60px" }} />
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
