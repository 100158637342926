import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getClientDashboard, getDashboardData } from "api/dashboard.api";

export interface DashboardSlice {
  dashboard_data: any;
  client_dashboard_data: any;
}

const initialState: DashboardSlice = {
  dashboard_data: null,
  client_dashboard_data: null,
};

export const doGetDashboard = createAsyncThunk(
  "dashboard/doGetDashboard",
  async (payload: any) =>
    getDashboardData(payload).then((res: any) => {
      return res;
    })
);
export const doGetClientDashboard = createAsyncThunk(
  "dashboard/doGetClientDashboard",
  async (payload: any) =>
    getClientDashboard(payload).then((res: any) => {
      return res;
    })
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetDashboard.fulfilled, (state, action) => {
      state.dashboard_data = action.payload;
    });
    builder.addCase(doGetClientDashboard.fulfilled, (state, action) => {
      state.client_dashboard_data = {
        pickup_documents:
          action.payload &&
          action.payload[0] &&
          action.payload[0].pickup_documents
            ? JSON.parse(action.payload[0].pickup_documents)
            : [],

        shipment_documents:
          action.payload &&
          action.payload[0] &&
          action.payload[0].shipment_documents
            ? JSON.parse(action.payload[0].shipment_documents)
            : [],
      };
    });
  },
});

export default dashboardSlice.reducer;
