import React from 'react';
import Icon from '@ant-design/icons';

interface IconProps {
  color?: string;
}

const DownloadSVGIcon: React.FC<IconProps> = ({ color = '#ffffff' }) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.71719 10.2828C7.79209 10.358 7.8939 10.4001 8 10.4C8.1061 10.4001 8.20786 10.358 8.28276 10.2828L11.8828 6.68281C12.0325 6.52778 12.0325 6.28203 11.8828 6.127C11.7293 5.96812 11.4761 5.96372 11.3172 6.11719L8.4 9.03442V0.4C8.4 0.179102 8.2209 0 8 0C7.7791 0 7.6 0.179102 7.6 0.4V9.03442L4.68276 6.11724C4.52778 5.96753 4.28203 5.96753 4.127 6.11724C3.96812 6.2707 3.96372 6.52393 4.11719 6.68281L7.71719 10.2828ZM15.6 9.6C15.3791 9.6 15.2 9.7791 15.2 10V14C15.1993 14.6625 14.6625 15.1993 14 15.2H2C1.33755 15.1993 0.800732 14.6625 0.8 14V10C0.8 9.7791 0.620898 9.6 0.4 9.6C0.179102 9.6 0 9.7791 0 10V14C0.00102536 15.1042 0.89585 15.999 2 16H14C15.1042 15.999 15.999 15.1042 16 14V10C16 9.7791 15.8209 9.6 15.6 9.6Z"
      fill={color}
    />
  </svg>
);

const DownloadIcon: React.FC<IconProps> = (props) => (
  <Icon component={() => <DownloadSVGIcon {...props} />} />
);

export default DownloadIcon;
