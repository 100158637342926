import { httpApi } from "api/http.api";
import { IRequestData, ISortOrder } from "domain/global";
import { addFilter } from "./utils";

export const fetchOData = async (apiUrl: string): Promise<any> => {
  try {
    const response = await httpApi.get<any>(apiUrl);
    return response.data.data ?? response.data;
  } catch (error) {
    console.error(`Error fetching data from ${apiUrl}:`, error);
    throw error;
  }
};
export const buildApiUrl = (baseUrl: string, data: IRequestData, additionalParams: { [key: string]: any } = {}): string => {
  const { pagination = {}, sort_order, sort_field, ...rest } = data;
  const { current = 1, pageSize = 10 } = pagination;

  let apiUrl = addPagination(baseUrl, current, pageSize);

  let filters: string[] = [];
  addSimpleFilters(filters, rest);

  if (filters.length > 0) {
    apiUrl += `&$filter=${filters.join(' and ')}`;
  }

  apiUrl = addSorting(apiUrl, sort_order, sort_field);

 if(additionalParams){
  Object.keys(additionalParams).forEach(key => {
    apiUrl += `&${key}=${encodeURIComponent(additionalParams[key])}`;
  });
 }

  return apiUrl;
};

export const addPagination = (baseUrl: string, current: number = 1, pageSize: number = 10): string => {
  let apiUrl = `${baseUrl}?$count=true`;
  apiUrl += current === 1 ? `&$top=${pageSize}` : `&$top=${pageSize}&$skip=${(current - 1) * pageSize}`;
  return apiUrl;
};

export const addSimpleFilters = (filters: string[], rest: { [key: string]: any }): void => {
  Object.keys(rest).forEach((key: string) => {
    addFilter(filters, key, rest[key]);
  });
};

export const addSorting = (apiUrl: string, sort_order?: ISortOrder, sort_field?: string): string => {
  if (sort_order && sort_field) {
    const sortOrderParam = sort_order === 'ascend' ? 'ASC' : 'DESC';
    apiUrl += `&$orderby=${sort_field} ${sortOrderParam}`;
  }
  return apiUrl;
};
