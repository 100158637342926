import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProfessionalReport } from "api/report.api";


export interface ReportSlice {
  report_list: any;
  complete_report_list?: any;
  sort_report?: any;
  report_list_loaded: boolean;
  is_all_active_item: string | boolean;
}

const initialState: ReportSlice = {
 report_list: [],
 complete_report_list: [],
 sort_report: "",
 report_list_loaded: false,
 is_all_active_item: true
};

export const doGetReport = createAsyncThunk(
  "orders/doGetReport",
  async (data) =>
      getProfessionalReport(data).then((res: any) => {
      return res;
    })
);



const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setCompleteReportList: (state, action) =>{
      state.complete_report_list = action?.payload
    },
    setReportSorting: (state, action) =>{
      state.sort_report = action?.payload
    },
    setIsAllActiveItem: (state, action) => {
      state.is_all_active_item = action?.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(doGetReport.fulfilled, (state, action) => {
      state.report_list = action.payload.data;
      state.report_list_loaded = true;
    });
  
  },
});
export const { setCompleteReportList, setReportSorting, setIsAllActiveItem } = reportSlice.actions;

export default reportSlice.reducer;
