import { httpApi } from "api/http.api";

export const getDashboardData = (id: any): Promise<any> =>
  httpApi
    .get<any>(`AccountDashboard/GetStats`)
    .then((data: any) => data.data.data)
    .then((data) => data);

export const getClientDashboard = (id: any): Promise<any> =>
  httpApi
    .get<any>(`ClientDashboard/GetStats`)
    .then((data: any) => data.data.data)
    .then((data) => data);
