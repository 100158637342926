import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RouteData, WorkSchedule, WorkScheduleDriver, WorkScheduleSite } from "domain/WorkScheduele";
import {
  addRoute,
  addWorkScheduleSite,
  assignDriverToWorkSchedule,
  assignVehicleToWorkSchedule,
  getRoutes,
  getWorkSchedules,
  updateWorkSchedule,
  updateWorkScheduleSite,
} from "api/workSchedule.api";
import moment from "moment";

export interface WorkScheduleSlice {
  routes: any[] | null;
  workScheduleClientSites: any[]
  routeData: RouteData[]
  selectedDate: moment.Moment
  selectedMomentDate: moment.Moment
  workScheduleSitesContainer: {
    data?: any,
    date?: any,
  },
  workScheduleCardPosition: {
    cardWidth?: string | number,
    cardHeight?: string | number
  }
  workScheduleSitesOpen? : boolean
  workScheduleSitesExpand? : boolean
}

const initialState: WorkScheduleSlice = {
  routes: null,
  workScheduleClientSites: [],
  routeData: [],
  selectedDate: moment().clone().weekday(1),
  selectedMomentDate: moment().clone().weekday(1),
  workScheduleSitesContainer: {
    data: null,
    date: null,
  },
  workScheduleCardPosition: {
    cardWidth: "",
    cardHeight: ""
  },
  workScheduleSitesOpen : false,
  workScheduleSitesExpand : false
};

export const doGetRoute = createAsyncThunk(
  "workSchedule/doGetRoute",
  async () =>
    getRoutes("").then((res: any) => {
      return res;
    })
);
export const doAssignVehicleToWorkSchedule = createAsyncThunk(
  "workSchedule/doAssignVehicleToWorkSchedule",
  async (professionalPayload: any, { dispatch }) =>
    assignVehicleToWorkSchedule(professionalPayload.routeId, {
      vehicle_id: professionalPayload.vehicle_id,
    }).then((res) => {
      return res;
    })
);

export const doAssignDriverToWorkSchedule = createAsyncThunk(
  "workSchedule/doAssignDriverToWorkSchedule",
  async (professionalPayload: any, { dispatch }) =>
    assignDriverToWorkSchedule(professionalPayload.routeId, {
      driver_id: professionalPayload.driver_id,
    }).then((res) => {
      return res;
    })
);
export const doAddSiteToWorkSchedule = createAsyncThunk(
  "workSchedule/doAddSiteToWorkSchedule",
  async (professionalPayload: any, { dispatch }) =>
    addWorkScheduleSite(professionalPayload).then((res) => {
      return res;
    })
);
export const doUpdateWorkScheduleSite = createAsyncThunk(
  "workSchedule/doUpdateWorkScheduleSite",
  async (sitePayload: any, { dispatch }) =>
    updateWorkScheduleSite(sitePayload.routeSiteId, sitePayload).then((res) => {
      return res;
    })
);

export const doAddRoute = createAsyncThunk(
  "workSchedule/doAddRoute",
  async (professionalPayload: any, { dispatch }) =>
    addRoute(professionalPayload).then((res) => {
      return res;
    })
);
export const doUpdateWorkSchedule = createAsyncThunk(
  "workSchedule/doUpdateWorkSchedule",
  async (professionalPayload: any, { dispatch }) =>
    updateWorkSchedule(professionalPayload.id, professionalPayload).then(
      (res: any) => {
        return res;
      }
    )
);


export const doUpdateRouteData = createAsyncThunk(
  'workSchedule/routeData',
  async (date: moment.Moment, { dispatch }) => {

    try {
      const convertedDate = moment(date).format(
        "YYYY-MM-DD"
      );
      const data: any = await getWorkSchedules(convertedDate);
      let parsedRouteData = [];
      if (data.data) {
        for (let index = 0; index < 7; index++) {
          const element = data.data[index];
          if (element) {
            let workSchedules: WorkSchedule[] = element.work_schedules
              ? JSON.parse(element.work_schedules) as WorkSchedule[]
              : [];
            // Also Parsing JSOn of Drivers key and Work Scheduele Sites
            workSchedules = workSchedules.map(((workSc: any) => (
              {
                ...workSc,
                work_schedule_drivers: workSc.work_schedule_drivers ? JSON.parse(workSc.work_schedule_drivers) as WorkScheduleDriver[] : [],
                work_schedule_sites: workSc.work_schedule_sites ? JSON.parse(workSc.work_schedule_sites) as WorkScheduleSite[] : [],
              })))
            parsedRouteData.push({ ...element, work_schedules: workSchedules });
          } else {
            parsedRouteData.push({ route_week_day: index + 1, routes: [] });
          }
        }
      }

      return parsedRouteData;
    } catch (error) {
      console.error('Error updating work schedule route data:', error);
      throw error; // Propagate the error to the caller
    }
  }
);



const workScheduleSlice = createSlice({
  name: "workSchedule",
  initialState,
  reducers: {
    setSelectedDate: (state, action: { payload: any }) => {
      state.selectedDate = action.payload
    },
    setSelectedMomentDate: (state, action: { payload: any }) => {
      state.selectedMomentDate = action.payload
    },
    setWorkScheduleSitesContainer: (state, action) => {
      state.workScheduleSitesContainer = action.payload;
    },
    setWorkSchedulePosition: (state, action) => {
      state.workScheduleCardPosition = action.payload;
    },
    clearWorkScheduleSitesContainer: (state) => {
      state.workScheduleSitesContainer = {
        data: null,
        date: null
      };
    },
    setWorkScheduleSiteOpen: (state, action) => {
      state.workScheduleSitesOpen = action.payload;
    },
    setExpandWorkSchedule: (state) => {
      state.workScheduleSitesExpand = !state.workScheduleSitesExpand;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doGetRoute.fulfilled, (state, action) => {
      let parsedRouteData = [];
      if (action.payload.data) {
        for (let index = 0; index < 7; index++) {
          const element = action.payload.data[index];
          if (element) {
            const clientSitesDat = element.routes
              ? JSON.parse(element.routes)
              : [];
            parsedRouteData.push({ ...element, routes: clientSitesDat });
          } else {
            parsedRouteData.push({ route_week_day: index + 1, routes: [] });
          }
        }
      }
      state.routes = parsedRouteData;
    })
      .addCase(doUpdateRouteData.fulfilled, (state, action) => {
        state.routeData = action.payload
      })
  },
});


export const { setSelectedDate, setSelectedMomentDate, setWorkScheduleSitesContainer, setWorkScheduleSiteOpen, clearWorkScheduleSitesContainer, setWorkSchedulePosition, setExpandWorkSchedule } = workScheduleSlice.actions

export default workScheduleSlice.reducer;
