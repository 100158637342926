import { httpApi } from "api/http.api";
import {
  AgreementItem,
  AgreementItemRequest,
  AgreementItemResponse,
} from "domain/AgreementItem";
import moment from "moment";

/**
 *
 * @param id Agreement Id
 * @returns AgreementItem[]
 */
export const getAgreementItemList = (id: any): Promise<AgreementItem[]> =>
  httpApi
    .get<AgreementItem[]>("AgreementItems/GetAgreementItems/" + id)
    .then(({ data }) => data);

export const GetAgreement2ProfessionalByID = (id: any): Promise<AgreementItem[]> =>
  httpApi
    .get<AgreementItem[]>("/Professionals/GetAgreement2ProfessionalByID/" + id)
    .then(({ data }) => data);

export const addAgreementItem = (
  agreementItemPayload: AgreementItemRequest
): Promise<AgreementItemResponse> =>
  httpApi
    .post<AgreementItemResponse>("AgreementItems", {
      ...agreementItemPayload,
    })
    .then(({ data }) => data);

export const updateAgreementItem = (
  id: any,
  agreementItemPayload: AgreementItemRequest
): Promise<AgreementItemResponse> =>
  httpApi
    .put<AgreementItemResponse>("AgreementItems/" + id, {
      ...agreementItemPayload,
    })
    .then(({ data }) => data);

export const getAgreementItem = (id: any): Promise<AgreementItem> =>
  httpApi
    .get<AgreementItem>("AgreementItems/" + id)
    .then((data: any) => data.data.data[0])
    .then((data: any) => data);

export const deleteAgreementItem = async (
  id: number
): Promise<AgreementItemResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<AgreementItemResponse>(
    `AgreementItems/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<AgreementItemResponse>(`AgreementItems/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};


