import { httpApi } from "api/http.api";
import { RFIDReaderData, ScanItem } from "domain/Shipment";
import moment from "moment";

export const getShippingDocument = (): Promise<any[]> =>
  httpApi
    .get<any[]>("Shipments/GetShipmentDocuments/")
    .then(({ data }) => data);

export const getShippingDocumentDetailsById = (id: any): Promise<any[]> =>
  httpApi
    .get<any[]>(`Shipments/${id}/GetShipmentDocumentByID`)
    .then((data: any) => data.data.data[0]);

export const getShippingDocumentDetails = (id: any): Promise<any[]> =>
  httpApi 
    .get<any[]>(`Shipments/${id}/GetShipmentDocument`)
    .then((data: any) => data.data.data[0]);

export const getWashingItems = (id: any): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/${id}/GetShipmentWashAndBulkItems`)
    .then((data: any) => data.data.data);
};
export const addWashingItem = (id: any, data: any): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Shipments/${id}/AddShipmentWashAndBulkItem/`, data)
    .then((data: any) => data.data);
};
export const newScan = (shipmentId: any, data: any): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Shipments/${shipmentId}/NewBatchScan`, { rfid_reader_id: 2 })
    .then((data: any) => data.data);
};

export const getShipmentItems = (
  shipmentDocumentId: any,
  scanType = 2
): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/${shipmentDocumentId}/ShipmentItems/${scanType}`)
    .then((data: any) => data.data.data);
};

export const createNewItem = (
  shipmentDocumentId: any,
  data: any
): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Shipments/${shipmentDocumentId}/NewItem/`, data)
    .then((data: any) => data.data.data);
};

export const deleteShipmentItem = async (shipment_item_id: number): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Shipments/DeleteShipmentItem/${shipment_item_id}`,
    {
      data: { hmac_time: time },
    }
  );
  return httpApi.delete<any>(`Shipments/DeleteShipmentItem/${shipment_item_id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};
// export const deleteScan = (batchId: any): Promise<any[]> => {
//   return httpApi.delete<any[]>(`Shipments/DeleteBatch/${batchId}`).then((data: any) => data.data);

// }

export const getBatchesOfShipment = (
  shipmentDocumentId: any,
  scanType = 2
): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/${shipmentDocumentId}/GetBatchesByShipmentID`)
    .then((data: any) => data.data.data);
};
export const getBatchesOfShipmentException = (
  shipmentDocumentId: any,
  scanType = 2
): Promise<any[]> => {
  return httpApi
    .get<any[]>(
      `Shipments/${shipmentDocumentId}/GetBatchesByShipmentID?showall=true`
    )
    .then((data: any) => data.data.data);
};
export const getAllScans = (
  shipmentDocumentId: string | undefined,
): Promise<ScanItem[]> => {
  return httpApi
    .get<ScanItem[]>(
      `Shipments/${shipmentDocumentId}/ShipmentItems`
    )
    .then((data: any) => data.data.data);
};

export const deleteScan = async (batchId: number): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Shipments/DeleteBatchScan/${batchId}`,
    {
      data: { hmac_time: time },
    }
  );
  return httpApi.delete<any>(`Shipments/DeleteBatchScan/${batchId}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};

export const deleteWashItemData = async (
  itemId: number,
  shipment_id: any
): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Shipments/DeleteShipmentPriceListItem/${itemId}`,
    {
      data: { hmac_time: time, shipment_id: shipment_id },
    }
  );
  return httpApi.delete<any>(
    `Shipments/DeleteShipmentPriceListItem/${itemId}`,
    {
      data: {
        hmac_time: time,
        shipment_id: shipment_id,
        hmac: hmac_token?.data?.hmac,
      },
    }
  );
};

export const createShippingDocument = (shipmentPayload: any): Promise<any> =>
  httpApi
    .post<any>("Shipments", { ...shipmentPayload })
    .then(({ data }) => data);

export const getBatchException = (batchId: any): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/GetExceptionsByBatchID/${batchId}`)
    .then((data: any) => data.data.data);
};
export const getBatchItems = (batchId: any): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/GetShipmentItemsByBatchID/${batchId}`)
    .then((data: any) => data.data.data);
};
export const getWashAndBulkItems = (shipmentId: any): Promise<any[]> => {
  return httpApi.get<any[]>(`Shipments/${shipmentId}/WashAndBulkItems`).then(({ data }) => data);
};

export const getAttachedRFIDReader = (): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/GetAttachedRFIDReaders`)
    .then((data: any) => data.data.data);
};

export const getRFIDReader = (): Promise<any[]> => {
  return httpApi
    .get<RFIDReaderData[]>(`Shipments/GetRFIDReaders`)
    .then((data: any) => data.data.data);
};

export const detachRFID = (rfIdData: any): Promise<any[]> => {
  return httpApi
    .post<any>("Shipments/DetachRFID/", { ...rfIdData })
    .then(({ data }) => data);
};

export const getRFIDReaders = (): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/GetRFIDReaders`)
    .then((data: any) => data.data.data);
};
export const attachRFID = (rfIdData: any): Promise<any[]> => {
  return httpApi
    .post<any>("Shipments/AttachRFID/", { ...rfIdData })
    .then(({ data }) => data);
};

export const deleteShipmentDoc = async (documentId: number): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Shipments/DeleteShipment/${documentId}`,
    {
      data: { hmac_time: time },
    }
  );
  return httpApi.delete<any>(`Shipments/DeleteShipment/${documentId}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};

export const getShipmentBulkItems = (shipment_id: any): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/${shipment_id}/GetShipmentBulkItems/`)
    .then((data: any) => data.data.data);
};

export const getShipmentBulkOrders = (shipment_id: any): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/${shipment_id}/GetShipmentBulkOrders/`)
    .then((data: any) => data.data.data);
};

export const getShipmentBulkItemDropdown = (): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/GetBulkItems/`)
    .then((data: any) => data.data.data);
};
export const getOrderOfBulkAgreement = (): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/GetOrdersOfBulkAgreement/`)
    .then((data: any) => data.data.data);
};
export const getOrderOfBulkAgreementByShipmentId = (shipment_id: number): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/${shipment_id}/GetOrdersOfBulkAgreement/`)
    .then((data: any) => data.data.data);
};
export const addShipmentBulkItem = (
  shipment_id: any,
  payload: any
): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Shipments/${shipment_id}/AddShipmentBulkItem/`, payload)
    .then((data: any) => data.data.data);
};

export const addShipmentBulkOrder = (
  shipment_id: any,
  payload: any
): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Shipments/${shipment_id}/AddShipmentBulkOrder/`, payload)
    .then((data: any) => data.data.data);
};

export const deleteShipmentBulkItem = async (
  shipment_bulk_item_id: number
): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Shipments/DeleteShipmentBulkItem/${shipment_bulk_item_id}`,
    {
      data: { hmac_time: time },
    }
  );
  return httpApi.delete<any>(
    `Shipments/DeleteShipmentBulkItem/${shipment_bulk_item_id}`,
    {
      data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
    }
  );
};

export const deleteWashOrBulkItemsItem = async (
  shipment_bulk_item_id: any
): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Shipments/DeleteShipmentWashAndBulkItem/${shipment_bulk_item_id}`,
    {
      data: { hmac_time: time },
    }
  );
  return httpApi.delete<any>(
    `Shipments/DeleteShipmentWashAndBulkItem/${shipment_bulk_item_id}`,
    {
      data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
    }
  );
};

export const deleteShipmentBulkOrder = async (
  shipment_bulk_order_id: number
): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Shipments/DeleteShipmentBulkOrder/${shipment_bulk_order_id}`,
    {
      data: { hmac_time: time },
    }
  );
  return httpApi.delete<any>(
    `Shipments/DeleteShipmentBulkOrder/${shipment_bulk_order_id}`,
    {
      data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
    }
  );
};

export const getShipmentApprovalStatus = (shipment_id: any): Promise<any[]> => {
  return httpApi
    .put<any[]>(`Shipments/${shipment_id}/CheckShipmentApproval/`)
    .then((data: any) => data.data);
};

export const updateManagerNote = (
  shipment_id: any,
  values: any
): Promise<any[]> => {
  return httpApi
    .put<any[]>(`Shipments/${shipment_id}/Approval/`, values)
    .then((data: any) => data.data.data);
};

export const getMissingItems = (
  shipmentId: any,
  timeFrameId: any
): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Shipments/${shipmentId}/MissingItems`, {
      time_frame: timeFrameId,
    })
    .then((data: any) => data.data.data);
};

export const getUncompletedItems = (shipmentId: any): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/${shipmentId}/UncompletedOrders`)
    .then((data: any) => data.data.data);
};
// export const sendApproval = (
//   shipment_id: any,
//   payload: {
//     manager_approved_note: string;
//   }
// ): Promise<any[]> => {
//   return httpApi
//     .post<any[]>(`Shipments/${shipment_id}/Approval/`, payload)
//     .then((data: any) => data.data.data);
// };

export const getShipmentsStatus = (): Promise<any[]> =>
  httpApi.get<any[]>("/Shipments/ShipmentStatus").then(({ data }) => data);

export const searchShipmentsDocuments = ({
  client_id,
  status,
  creation_date,
}: {
  client_id?: number;
  status?: number;
  creation_date?: string;
}): Promise<any[]> => {
  let url = "Shipments/SearchShipments?";
  console.log("client", client_id, status);
  if (client_id) {
    url = url + `client_id=${client_id}`;
  }

  url =
    status && !client_id
      ? url + `status=${status}`
      : status && client_id
        ? url + `&status=${status}`
        : url;

  url =
    creation_date && !status
      ? url + `creation_date=${creation_date}`
      : creation_date && (status || client_id)
        ? url + `&creation_date=${creation_date}`
        : url;

  return httpApi.get<any[]>(url).then(({ data }) => data);
};
