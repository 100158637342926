import { httpApi } from "api/http.api";
import { UserModel } from "../domain/UserModel";
import { httpLoginApi } from "./login.api";

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface RefreshClient {
  client_id: string;
}

export interface RefreshTokenRequest {
  refresh_token: string;
}


export interface LoginResponse {
  token: string;
  user: UserModel;
  refresh_token: string;
}

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> => {
  return httpLoginApi
    .post<LoginResponse>("CmsAuth?callType=login", { ...loginPayload })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};


export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>("signUp", { ...signUpData }).then(({ data }) => data);

  export const refreshToken = async (refreshToken: RefreshTokenRequest): Promise<LoginResponse> => {
    try {
      const response = await httpApi.post<LoginResponse>("CmsAuth?callType=persistent", { ...refreshToken })
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export const setClientToken = async (request: { client_id: string }): Promise<LoginResponse> => {
  try {
    const response = await httpApi.post<LoginResponse>("CmsAuth?callType=set_client_of_account", { ...request });
    return response.data;
  } catch (error) {
    throw error;
  }
};



export const resetPassword = (
  resetPasswordPayload: ResetPasswordRequest
): Promise<undefined> =>
  httpApi
    .post<undefined>("forgotPassword", { ...resetPasswordPayload })
    .then(({ data }) => data);

export const verifySecurityCode = (
  securityCodePayload: SecurityCodePayload
): Promise<undefined> =>
  httpApi
    .post<undefined>("verifySecurityCode", { ...securityCodePayload })
    .then(({ data }) => data);

export const setNewPassword = (
  newPasswordData: NewPasswordData
): Promise<undefined> =>
  httpApi
    .post<undefined>("setNewPassword", { ...newPasswordData })
    .then(({ data }) => data);
