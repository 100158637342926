import moment from "moment";
import { httpApi } from "api/http.api";
import {
  GarmentFabric,
  GarmentFabricRequest,
  GarmentFabricResponse,
} from "domain/garmentFabricInterface";

/**
 *
 * @returns GarmentFabric[]
 */
export const getGarmentFabricList = (): Promise<GarmentFabric[]> =>
  httpApi.get<GarmentFabric[]>("GarmentFabricTypes").then(({ data }) => data);

export const addGarmentFabric = (
  garmentsPayload: GarmentFabricRequest
): Promise<GarmentFabricResponse> =>
  httpApi
    .post<GarmentFabricResponse>("GarmentFabricTypes", {
      ...garmentsPayload,
    })
    .then(({ data }) => data);

export const updateGarmentFabric = (
  id: any,
  incomingSoilPayload: GarmentFabricRequest
): Promise<GarmentFabricResponse> =>
  httpApi
    .put<GarmentFabricRequest>("GarmentFabricTypes/" + id, {
      ...incomingSoilPayload,
    })
    .then(({ data }) => data);

export const getGarmentFabricById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`GarmentFabricTypes/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const deleteGarmentFabric = async (
  id: number
): Promise<GarmentFabricResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<GarmentFabricResponse>(
    `GarmentFabricTypes/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<GarmentFabricResponse>(`GarmentFabricTypes/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};
