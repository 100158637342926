import { Image } from "antd";
import { PageTitle } from "components/common/pageTitle/PageTitle";
import Logo from "components/layouts/Logo";
import React from "react";
import { LoginForm } from "../components/auth/LoginForm/LoginForm";

const LoginPage: React.FC = () => {
  return (
    <>
      <PageTitle>Login</PageTitle>

      <LoginForm />
    </>
  );
};

export default LoginPage;
