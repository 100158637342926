import moment from "moment";
import { httpApi } from "api/http.api";
import { Client, ClientRequest, ClientResponse } from "domain/ClientInterfaces";
import { getAccountInfo, getProfileID } from "services/localStorage.service";

/**
 *
 * @returns Client[]
 */
export const getClientList = (): Promise<Client[]> =>
  httpApi.get<Client[]>("Clients?show_active=true").then(({ data }) => data);

export const addClient = (
  garmentsPayload: ClientRequest
): Promise<ClientResponse> =>
  httpApi
    .post<ClientResponse>("Clients", {
      ...garmentsPayload,
    })
    .then(({ data }) => data);

export const updateClient = (
  id: any,
  incomingSoilPayload: ClientRequest
): Promise<ClientResponse> =>
  httpApi
    .put<ClientRequest>("Clients/" + id, {
      ...incomingSoilPayload,
    })
    .then(({ data }) => data);

export const getClientById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`Clients/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const deleteClient = async (id: number): Promise<ClientResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<ClientResponse>(`Clients/${id}`, {
    data: { hmac_time: time },
  });

  return httpApi.delete<ClientResponse>(`Clients/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};

export const getClientSitesById = (client_id: any): Promise<any> => {
  const profileId = getProfileID()
  let apiUrl = '';

  if (profileId == 1) {
    apiUrl += 'Clients/GetSites?client_id=' + client_id + '&order_by=site_name';
  } else {
    apiUrl += 'ClientsData/Sites';
  }
  return httpApi
    .get<any>(apiUrl) 
    .then(({ data }) => data.data)
    .then((data) => data);
}
export const getClients = (): Promise<any> =>
  httpApi
    .get<any>(`Clients`)
    .then(({ data }) => data.data)
    .then((data) => data);

export const getProfessionals = (): Promise<any> =>
  httpApi
    .get<any>(`Professionals?show_deleted=true`)
    .then(({ data }) => data.data)
    .then((data) => data);

export const addClientSite = (
  garmentsPayload: ClientRequest,
  client_id: any
): Promise<ClientResponse> =>
  httpApi
    .post<ClientResponse>(`Clients/${client_id}/AddSite`, {
      ...garmentsPayload,
    })
    .then(({ data }) => data);

export const updateClientSite = (
  payload: any,
  client_id?: any,
  id?: any
): Promise<any[]> =>
  httpApi
    .put<any[]>(`Clients/${client_id}/UpdateSite/${id}`, {
      ...payload,
      id: +payload.id,
  })
    .then(({ data }) => data);

export const deleteClientSite = async (
  id: number,
  clientId: any
): Promise<ClientResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<ClientResponse>(
    `Clients/${clientId}/DeleteSite/${id}
`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<ClientResponse>(
    `Clients/${clientId}/DeleteSite/${id}`,
    {
      data: {
        hmac_time: time,

        hmac: hmac_token?.data?.hmac,
      },
    }
  );
};

export const getClientDepartmentsById = (client_id: any): Promise<any> => {
  const profileId = getProfileID()
  let apiUrl = '';

  if (profileId == 1) {
    apiUrl += `Clients/GetDepartments?client_id=${client_id}&order_by=department_name`;
  } else {
    apiUrl += 'ClientsData/Departments';
  }
  
  return httpApi
  .get<any>(apiUrl)
  .then(({ data }) => data.data)
  .then((data) => data);
}

export const addClientDepartment = (
  garmentsPayload: ClientRequest,
  client_id: any
): Promise<ClientResponse> =>
  httpApi
    .post<ClientResponse>(`Clients/${client_id}/AddDepartment`, {
      ...garmentsPayload,
    })
    .then(({ data }) => data);

export const updateClientDepartment = (
  payload: any,
  client_id?: any,
  id?: any
): Promise<any[]> =>
  httpApi
    .put<any[]>(`Clients/${client_id}/UpdateDepartment/${id}`, {
      ...payload,
    })
    .then(({ data }) => data);

export const deleteClientDepartment = async (
  department_id: number,
  client_id: any
): Promise<ClientResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<ClientResponse>(
    `Clients/${client_id}/DeleteDepartment/${department_id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<ClientResponse>(
    `Clients/${client_id}/DeleteDepartment/${department_id}`,
    {
      data: {
        hmac_time: time,
        hmac: hmac_token?.data?.hmac,
      },
    }
  );
};
export const getClientAndSites = (): Promise<Client[]> =>
  httpApi.get<Client[]>("Clients/GetClientsAndSites").then(({ data }) => data);
