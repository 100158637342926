import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getOpenWareHouseAllProfessionals,
  getOpenWareHouseProfessionals,
  getWareHouseByCompany,
  getWareHouseByProfessional,
  getWareHouseOverview,
} from "api/wareHouse.api";

export interface WareHouseSlice {
  order_overview_list: any;
  order_by_company_list: any;
  order_by_professional_list: any;
  order_overview_list_loaded: boolean;
  order_by_company_list_loaded: boolean;
  order_by_professional_list_loaded: boolean;
  open_order_professionals_loaded: boolean;
  open_order_professionals_list: any;
  open_order_all_professionals_list?: any;
  open_order_all_professionals_loaded: boolean;
}

const initialState: WareHouseSlice = {
  order_overview_list: [],
  order_by_company_list: [],
  order_by_professional_list: [],
  order_overview_list_loaded: false,
  order_by_company_list_loaded: false,
  order_by_professional_list_loaded: false,
  open_order_professionals_loaded: false,
  open_order_professionals_list: [],
  open_order_all_professionals_list: [],
  open_order_all_professionals_loaded: false
};

export const doGetWareHouseOverview = createAsyncThunk(
  "warehouse/doGetWareHouseOverview",
  async () =>
    getWareHouseOverview().then((res: any) => {
      return res;
    })
);


export const doGetOpenWareHouseProfessionals = createAsyncThunk(
  "agreement/doGetOpenWareHouseProfessionals",
  async (companyId: any, { dispatch }) =>
    getOpenWareHouseProfessionals(companyId)
      .then((res: any) => {
        return res;
      })
);

export const doGetOpenWareHouseAllProfessionals = createAsyncThunk(
  "agreement/doGetOpenWareHouseAllProfessionals",
  async (companyId: any, { dispatch }) =>
    getOpenWareHouseAllProfessionals(companyId)
      .then((res: any) => {
        return res;
      })
);

export const doGetWareHouseByCompany = createAsyncThunk(
  "warehouse/doGetWareHouseByCompany",
  async () =>
    getWareHouseByCompany().then((res: any) => {
      return res;
    })
);


export const doGetWareHouseByProfessional = createAsyncThunk(
  "warehouse/doGetWareHouseByProfessional",
  async (data: any) =>
    getWareHouseByProfessional(data).then((res: any) => { return res; })
);

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {
    clearState: (state) => {
      state.order_overview_list = [];
      state.order_by_company_list = [];
      state.order_by_professional_list = [];
      state.order_overview_list_loaded = false;
      state.order_by_company_list_loaded = false;
      state.order_by_professional_list_loaded = false;
      state.open_order_professionals_loaded = false;
      state.open_order_professionals_list = []
    },
    clearOpenOrderAllProfessionalsList: (state) => {
      state.open_order_all_professionals_list = [];
      state.open_order_all_professionals_loaded = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(doGetWareHouseOverview.fulfilled, (state, action) => {
      state.order_overview_list = action.payload.data;
      state.order_overview_list_loaded = true;
    });
    builder.addCase(doGetWareHouseByCompany.fulfilled, (state, action) => {
      state.order_by_company_list = action.payload.data;
      state.order_by_company_list_loaded = true;
    });
    builder.addCase(doGetWareHouseByProfessional.fulfilled, (state, action) => {
      state.open_order_professionals_list = action.payload.data;
      state.open_order_professionals_loaded = true;
    });
    builder.addCase(doGetOpenWareHouseProfessionals.fulfilled, (state, action) => {
      state.open_order_professionals_list = action.payload;
      state.open_order_professionals_loaded = true;
    });
    builder.addCase(doGetOpenWareHouseAllProfessionals.fulfilled, (state, action) => {
      state.open_order_all_professionals_list = action.payload;
      state.open_order_all_professionals_loaded = true;
    });
  },
});
export const { clearState, clearOpenOrderAllProfessionalsList } = warehouseSlice.actions
export default warehouseSlice.reducer;
