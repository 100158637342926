import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addDrivers,
  deleteDrivers,
  getDriversList,
  updateDrivers,
} from "api/drivers.api";
import { notificationController } from "controllers/notificationController";
import { Drivers, DriversRequest } from "domain/Drivers";

export interface DriversSlice {
  driversList: Drivers[] | null;
}

const initialState: DriversSlice = {
  driversList: null,
};

export const doGetDrivers = createAsyncThunk("drivers/doGetDrivers", async () =>
  getDriversList().then((res: any) => {
    return res;
  })
);

export const doAddDrivers = createAsyncThunk(
  "drivers/doAddGarment",
  async (professionalPayload: DriversRequest, { dispatch }) =>
    addDrivers(professionalPayload).then((res) => {
      dispatch(doGetDrivers());
      return res;
    })
);
export const doUpdateDrivers = createAsyncThunk(
  "drivers/doUpdateGarment",
  async (professionalPayload: any, { dispatch }) =>
    updateDrivers(professionalPayload.id, professionalPayload).then((res) => {
      dispatch(doGetDrivers());
      return res;
    })
);

export const doDeleteDrivers = createAsyncThunk(
  "drivers/doDeleteDrivers",
  async (garmentId: any, { dispatch }) => {
    deleteDrivers(garmentId).then(
      (res) => {
        notificationController.error({
          message: "Driver Deleted successfully.",
        });
        dispatch(doGetDrivers());
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);

const driversSlice = createSlice({
  name: "drivers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetDrivers.fulfilled, (state, action) => {
      state.driversList = action.payload.data;
    });
  },
});

export default driversSlice.reducer;
