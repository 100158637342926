import { httpApi } from "api/http.api";
import { IGarment, GarmentRequest, GarmentResponse } from "domain/Garments";
import moment from "moment";

/**
 * @param id Agreement Id
 * @returns Garments[]
 */
export const getGarmentsList = (): Promise<IGarment[]> =>
  httpApi.get<IGarment[]>("Garments").then(({ data }) => data);

export const getGarmentsDetailsById = (id: number | string): Promise<IGarment[]> =>
  httpApi.get<IGarment[]>(`Garments/GetGarmentDetails/${id}`).then((data:any ) => data);

export const addGarments = (
  garmentsPayload: GarmentRequest
): Promise<GarmentResponse> =>
  httpApi
    .post<GarmentResponse>("Garments", {
      ...garmentsPayload,
    })
    .then(({ data }) => data);

export const updateGarments = (
  id: any,
  garmentsPayload: GarmentRequest
): Promise<GarmentResponse> =>
  httpApi
    .put<GarmentRequest>("Garments/" + id, {
      ...garmentsPayload,
    })
    .then(({ data }) => data);

export const deleteGarments = (id: number): Promise<GarmentResponse> =>
  httpApi.delete<GarmentResponse>(`Garments/${id}`).then(({ data }) => data);

export const searchGarments = (
  garmentsPayload: GarmentRequest
): Promise<GarmentResponse> =>
  httpApi.get<any>("Garments").then(({ data }) => data.data);

export const getGarmentById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`Garments/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const getGarmentType = (): Promise<any> =>
  httpApi
    .get<any>(`GarmentTypes?is_fetch_all=true`)
    .then(({ data }) => data.data)
    .then((data) => data);

export const getGarmentColors = (): Promise<any> =>
  httpApi
    .get<any>(`GarmentColors?is_fetch_all=true`)
    .then(({ data }) => data.data)
    .then((data) => data);

export const getGarmentSizeTypes = (): Promise<any> =>
  httpApi
    .get<any>(`GarmentSizeTypes?is_fetch_all=true`)
    .then(({ data }) => data.data)
    .then((data) => data);

export const getGarmentFabricTypes = (): Promise<any> =>
  httpApi
    .get<any>(`GarmentFabricTypes?is_fetch_all=true`)
    .then(({ data }) => data.data)
    .then((data) => data);

export const getGarments = (): Promise<any> =>
  httpApi
    .get<any>(`Garments`)
    .then(({ data }) => data.data)
    .then((data) => data);

export const searchGarmentsByParam = (
  garmentsPayload: GarmentRequest
): Promise<GarmentResponse> =>
  httpApi
    .post<any>("Garments/SearchGarment", garmentsPayload)
    .then(({ data }) => data.data);

export const getGarmentFiles = (id: any): Promise<any> =>
  httpApi
    .get<any>(`GarmentFiles/GetByGarment/${id}`)
    .then(({ data }) => data.data)
    .then((data) => data);

export const getGarmentFabric = (id: any): Promise<any> =>
  httpApi
    .get<any>(`Garments/${id}/GetGarmentFabrics`)
    .then(({ data }) => data.data)
    .then((data) => data);

export const deleteGarment = async (id: number): Promise<GarmentResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<GarmentResponse>(
    `Garments/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<GarmentResponse>(`Garments/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};

export const deleteGarmentDocFile = async (
  id: number,
  file_name: string
): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(`GarmentFiles/${id}`, {
    data: { hmac_time: time, file_name: file_name },
  });

  return httpApi.delete<GarmentResponse>(`GarmentFiles/${id}`, {
    data: {
      hmac_time: time,
      hmac: hmac_token?.data?.hmac,
      file_name: file_name,
    },
  });
};

export const deleteGarmentImage = async (id: number): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Garments/${id}/DeleteGarmentImage`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<GarmentResponse>(`Garments/${id}/DeleteGarmentImage`, {
    data: {
      hmac_time: time,
      hmac: hmac_token?.data?.hmac,
    },
  });
};

export const getGarmentTags = (id: any): Promise<any> =>
  httpApi
    .get<any>(`GarmentTags`)
    .then(({ data }) => data.data)
    .then((data) => data);
