const WifiIcon = ({ filled = false }) => {
  return (
    <svg
      width={21}
      height={21}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.5" cy="10.5" r="10.5" fill={filled ? "#268959" : '#BBBBBB'} />
      <path
        d="M15.212 7.88269C14.619 7.28582 13.9149 6.81234 13.14 6.4893C12.365 6.16627 11.5345 6 10.6957 6C9.85685 6 9.02627 6.16627 8.25134 6.4893C7.47641 6.81234 6.77231 7.28582 6.17927 7.88269C6.06443 7.99833 5.99994 8.15513 6 8.31861C6.00006 8.48209 6.06465 8.63884 6.17958 8.7544C6.2945 8.86995 6.45034 8.93484 6.61281 8.93478C6.77529 8.93472 6.93108 8.86973 7.04592 8.75409C7.52516 8.27174 8.09414 7.88911 8.72037 7.62805C9.3466 7.367 10.0178 7.23263 10.6957 7.23263C11.3735 7.23263 12.0447 7.367 12.6709 7.62805C13.2972 7.88911 13.8661 8.27174 14.3454 8.75409C14.4602 8.86973 14.616 8.93472 14.7785 8.93478C14.941 8.93484 15.0968 8.86995 15.2117 8.7544C15.3267 8.63884 15.3912 8.48209 15.3913 8.31861C15.3914 8.15513 15.3269 7.99833 15.212 7.88269Z"
        fill={filled ? "white" : '#757575'}
      />
      <path
        d="M12.8023 12.152C12.9273 12.1521 13.0496 12.1149 13.1536 12.045C13.2576 11.9751 13.3387 11.8757 13.3866 11.7594C13.4345 11.6431 13.4471 11.5151 13.4227 11.3916C13.3983 11.2681 13.3382 11.1546 13.2497 11.0656C12.9144 10.7278 12.5162 10.4598 12.078 10.2769C11.6397 10.0941 11.17 10 10.6956 10C10.2213 10 9.75157 10.0941 9.31333 10.2769C8.8751 10.4598 8.47692 10.7278 8.14154 11.0656C8.02303 11.185 7.95648 11.347 7.95654 11.5158C7.95657 11.5994 7.97295 11.6822 8.00475 11.7594C8.03655 11.8366 8.08314 11.9068 8.14186 11.9659C8.20058 12.025 8.27029 12.0718 8.347 12.1038C8.42371 12.1358 8.50592 12.1522 8.58894 12.1522C8.7566 12.1521 8.91738 12.085 9.03589 11.9656C9.48282 11.5353 10.0773 11.2951 10.6956 11.2951C11.314 11.2951 11.9085 11.5353 12.3554 11.9656C12.4739 12.0849 12.6346 12.152 12.8023 12.152Z"
        fill={filled ? "white" : '#757575'}
      />
      <path
        d="M10.6955 15.0005C11.2358 15.0005 11.6738 14.5625 11.6738 14.0222C11.6738 13.4819 11.2358 13.0439 10.6955 13.0439C10.1553 13.0439 9.71729 13.4819 9.71729 14.0222C9.71729 14.5625 10.1553 15.0005 10.6955 15.0005Z"
        fill={filled ? "white" : '#757575'}
      />
    </svg>
  )
}

export default WifiIcon