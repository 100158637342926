import React from "react";
import { Dashboard, Customers, Orders, Reports, IncomingSoil, Shipment, SKUs, PendingReturn, Vehicles, Routes, Staff } from 'icons/svg';
import {

  SettingOutlined,
} from "@ant-design/icons";
import {
  AgreementIcon,
  OrderIcon,
  ProfessionalIcon,
} from "icons/Icons";
export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  svgIcon?: React.ReactNode;
}
type SidebarNavigation = {
  [key: number]: SidebarNavigationItem[];
};
export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: "Dashboard",
    key: "dashboard",
    url: "/dashboard/",
    svgIcon: Dashboard,
  },
  {
    title: "Professionals",
    key: "professional",
    url: "/client/professional/list",
    svgIcon: Customers,
  },
  {
    title: "Reports",
    key: "report",
    url: "/report-v2/list",
    svgIcon: Reports,
  },
];

export const sidebarNavigation3: SidebarNavigation = {
  1: [
    {
      title: "Plans",
      key: "agreement",
      url: "/agreement/list",
      icon: <AgreementIcon />,
    },
    {
      title: "Professionals",
      key: "professional",
      url: "/professional/list",
      icon: <ProfessionalIcon />,
    },
    {
      title: "OBC Items",
      key: "pricing-list-items",
      url: "/pricing-list-items/list",
      icon: <ProfessionalIcon />,
    },
    {
      title: "Bulk Items",
      key: "bulk-items",
      url: "/bulk-items/list",
      icon: <ProfessionalIcon />,
    }
  ],
  2: [
    {
      title: "Plans",
      key: "agreement",
      url: "/agreement/list",
      icon: <AgreementIcon />,
    },
    {
      title: "Professionals",
      key: "professional",
      url: "/professional/list",
      icon: <ProfessionalIcon />,
    }
  ],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
};
export const sidebarNavigation2: SidebarNavigation = {
  1: [
    // {
    //   title: "Dashboard",
    //   key: "dashboard",
    //   url: "/dashboard/",
    //   svgIcon: Dashboard
    // },
    {
      title: "Customers",
      key: "clients",
      url: "/client/list",
      svgIcon: Customers,
    },
    {
      title: "Orders",
      key: "orders",
      url: "/orders/",
      svgIcon: Orders,
    },
    {
      title: "Affiliation",
      key: "Affiliation",
      url: "/affiliation/",
      svgIcon: Reports,
    },
    {
      title: "De-Affiliation",
      key: "De-Affiliation",
      url: "/de-affiliation/",
      svgIcon: Reports,
    },
    {
      title: "Shipments",
      key: "shipment-document",
      url: "/shipment-document-v3/list",
      svgIcon: Vehicles,
    },
    {
      title: "Reports",
      key: "report",
      url: "/report-v2/list",
      svgIcon: Reports,
    },
    {
      title: "Incoming Soil",
      key: "incoming-soil",
      url: "/incoming-soil-v2/list",
      svgIcon: IncomingSoil,
    },
    {
      title: "SKUs",
      key: "garments",
      url: "/garments/list",
      svgIcon: SKUs,
    },
    {
      title: "Pending Return",
      key: "warehouse",
      url: "/warehouse-v2",
      svgIcon: PendingReturn,
    },
    {
      title: "Vehicles",
      key: "vehicles",
      url: "/vehicles/list",
      svgIcon: Vehicles,
    },
    {
      title: "Routes",
      key: "route",
      url: "/route/list",
      svgIcon: Routes,
    },
    {
      title: "Work-Schedule",
      key: "work-schedule",
      url: "/work-schedule",
      svgIcon: Routes,
    },
    {
      title: "ERP Users",
      key: "erp-user",
      url: "/users/list",
      svgIcon: Staff,
    },
  ],
  2: [
    {
      title: "Dashboard",
      key: "dashboard",
      url: "/dashboard/",
      svgIcon: Dashboard,
    },
    {
      title: "Customers",
      key: "clients",
      url: "/client/list",
      svgIcon: Customers,
    },
    {
      title: "Reports",
      key: "report",
      url: "/report/list",
      svgIcon: Reports,
    },
    {
      title: "Routes",
      key: "route",
      url: "/route/list",
      svgIcon: Routes,
    },
    {
      title: "Work-Schedule",
      key: "work-schedule",
      url: "/work-schedule",
      svgIcon: Routes,
    },

    {
      title: "ERP Users",
      key: "erp-user",
      url: "/users/list",
      svgIcon: Staff,
    },
  ],
  3: [
    {
      title: "Dashboard",
      key: "dashboard",
      url: "/dashboard/",
      svgIcon: Dashboard,
    },
    {
      title: "Orders",
      key: "orders",
      url: "/orders/",
      svgIcon: Orders,
    },
    {
      title: "Affiliation",
      key: "Affiliation",
      url: "/affiliation/",
      svgIcon: Reports,
    },
    {
      title: "Shipments",
      key: "shipment-document",
      url: "/shipment-document-v3/list",
      svgIcon: Vehicles,
    },
    {
      title: "Routes",
      key: "route",
      url: "/route/list",
      svgIcon: Routes,
    },
    {
      title: "Work-Schedule",
      key: "work-schedule",
      url: "/work-schedule",
      svgIcon: Routes,
    },
  ],
  4: [
    {
      title: "Dashboard",
      key: "dashboard",
      url: "/dashboard/",
      svgIcon: Dashboard,
    },
    {
      title: "Routes",
      key: "route",
      url: "/routes-and-work-schedule",
      svgIcon: Routes,
    },
    {
      title: "Work-Schedule",
      key: "work-schedule",
      url: "/work-schedule",
      svgIcon: Routes,
    },
  ],
  5: [
    {
      title: "Orders",
      key: "orders",
      url: "/orders/",
      svgIcon: Orders,
    },
    {
      title: "Affiliation",
      key: "Affiliation",
      url: "/affiliation/",
      svgIcon: Reports,
    },
    {
      title: "Shipments",
      key: "shipment-document",
      url: "/shipment-document-v3/list",
      svgIcon: Vehicles,
    },
  ],
  6: [
    {
      title: "Reports",
      key: "report",
      url: "/report/list",
      svgIcon: Reports,
    },
    {
      title: "Pending Return",
      key: "warehouse",
      url: "/warehouse-v2",
      svgIcon: PendingReturn,
    },
  ],
  7: [
    {
      title: "Shipments",
      key: "shipment-document",
      url: "/shipment-document-v3/list",
      svgIcon: Vehicles,
    },
  ],
  8: [
    {
      title: "Incoming Soil",
      key: "incoming-soil",
      url: "/incoming-soil-v2/list",
      svgIcon: IncomingSoil,
    },
  ],
};
