import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Empty, Input, Menu, Select } from 'antd';
import { SearchInputIcon } from 'icons/svg';
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Loading } from 'components/common/Loading';
import style from './ClientList.module.css';
import "./clientList.css"
import { setIsCreateClient } from 'store/slices/clientSlice';
import  {AddClientModal} from '../clientlAddEdit/AddClientModal';

const ClientListMenu = ({ onChangeCompany, clientId, filterData }: any) => {
  const { clientList, isLoading } = useAppSelector((state) => state.client);
  const [searchText, setSearchText] = useState('');
  const [filterType, setFilterType] = useState<string>('active');
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isNewClient = useAppSelector((state) => state.client.isCreateClient)

  const filterClients = clientList?.filter(
    (e: any) => {
      if (filterType === 'all') {
        return e.company_name?.toLowerCase().includes(searchText?.toLowerCase());
      } else if (filterType === 'active') {
        return e.is_client_deleted === false && e.company_name &&
         e.company_name.toLowerCase().includes(searchText.toLowerCase());
      }
      return false;
    });
    
  useEffect(() => {
    filterData(clientList);
  }, [clientList]);

  const renderClientList = () => {
    if (isLoading) {
      return <Loading />;
    }
     if (filterClients && filterClients.length > 0) {
      return (
        <div className="nested-nav-bar">
          <Menu
            theme="dark"
            mode="inline"
            className=""
            style={{ background: '#E0E0E0', color: 'black' }}
            selectedKeys={clientId ? [String(clientId)] : []}
            onSelect={(selectedValue) => {
              const selectedClientId = parseInt(selectedValue.key);
              onChangeCompany(selectedClientId);
            }}
          >
            {filterClients.map((nav: any) => (
              <Menu.Item title={nav.company_name} key={nav.id}>
                {nav.company_name}
              </Menu.Item>
            ))}
          </Menu>
        </div>
      );
    } else {
      return (
        <div className={style.empty}>
          <Empty />
        </div>
      )
    }
  };
  return (
    <>
      {!isLoading && (
        <>
          <div className={`${style.filterActive} search-bar`} style={{width :"100%"}}>
            <Input
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
              className="search-bar-input"
              suffix={<SearchInputIcon />}
            />
          </div>
        </>
      )}
      <div className={`customer-list ${style.customerList}`} onClick={() => navigate('/client/list')}>
        {renderClientList()}
        <div className="nested-bottom">
          <Button type='text'  onClick={(event) => { event.stopPropagation(); dispatch(setIsCreateClient(true)) }}>
            <p> <PlusCircleOutlined /> Add New Client</p>
          </Button>
        </div>
      </div>

      {isNewClient && (
        <AddClientModal />
      )}
    </>
  );
};

export default ClientListMenu;