import { ShipmentDocument } from "domain/Shipment";
import moment from "moment";

export function useDynamicShipmentTitle(shippingDocumentDetails: ShipmentDocument) {
  const shipmentStatus = shippingDocumentDetails?.shipment_status_value || '';
  const shipmentType = capitalize(shippingDocumentDetails?.shipment_type_value) || '';

  const title = shippingDocumentDetails
    ? `${capitalize(shipmentStatus)} / ${shippingDocumentDetails?.created_by} /
     ${moment(shippingDocumentDetails.created_on).format('MM-DD-YY')}
    / ${shippingDocumentDetails?.site_name} / ${shipmentType} Shipment`
    : '';

  return title;
}

// Helper function to capitalize the first letter of a string
function capitalize(str: string) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}
