import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addLaundryProgram,
  deleteLaundryProgram,
  getLaundryProgramList,
  updateLaundryProgram,
} from "api/laundryProgram.api";
import { notificationController } from "controllers/notificationController";
import { LaundryProgram, LaundryProgramRequest } from "domain/LaundryProgram";

export interface LaundryProgramSlice {
  laundryProgramList: LaundryProgram[] | null;
}

const initialState: LaundryProgramSlice = {
  laundryProgramList: null,
};

export const doGetLaundryProgram = createAsyncThunk(
  "laundryProgram/doGetLaundryProgram",
  async () =>
    getLaundryProgramList().then((res: any) => {
      return res;
    })
);

export const doAddLaundryProgram = createAsyncThunk(
  "laundryProgram/doAddLaundryProgram",
  async (professionalPayload: LaundryProgramRequest, { dispatch }) =>
    addLaundryProgram(professionalPayload).then((res) => {
      dispatch(doGetLaundryProgram());
      return res;
    })
);
export const doUpdateLaundryProgram = createAsyncThunk(
  "laundryProgram/doUpdateLaundryProgram",
  async (professionalPayload: any, { dispatch }) =>
    updateLaundryProgram(professionalPayload.id, professionalPayload).then(
      (res) => {
        dispatch(doGetLaundryProgram());
        return res;
      }
    )
);

export const doDeleteLaundryProgram = createAsyncThunk(
  "laundryProgram/doDeleteLaundryProgram",
  async (garmentId: any, { dispatch }) => {
    deleteLaundryProgram(garmentId).then(
      (res) => {
        notificationController.error({
          message: "Laundry Program delete successfully.",
        });
        dispatch(doGetLaundryProgram());
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);

const laundryProgramSlice = createSlice({
  name: "laundryProgram",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetLaundryProgram.fulfilled, (state, action) => {
      state.laundryProgramList = action.payload.data;
    });
  },
});

export default laundryProgramSlice.reducer;
