import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getOpenGarmentOrderByProfessional,
  getOpenOrderProfessionals,
  getOrderByCompany,
  getOrderByProfessional,
  getOrderOverview,
} from "api/order.api";

export interface OrderSlice {
  order_overview_list: any;
  order_by_company_list: any;
  order_by_professional_list: any;
  order_overview_list_loaded: boolean;
  order_by_company_list_loaded: boolean;
  order_by_professional_list_loaded: boolean;
  open_order_professionals_loaded: boolean;
  open_order_professionals_list: any;
  open_garment_order_professional_list: any;
  open_garment_order_professional_loaded: boolean;
}

const initialState: OrderSlice = {
  order_overview_list: [],
  order_by_company_list: [],
  order_by_professional_list: [],
  order_overview_list_loaded: false,
  order_by_company_list_loaded: false,
  order_by_professional_list_loaded: false,
  open_order_professionals_loaded: false,
  open_order_professionals_list: [],
  open_garment_order_professional_list: [],
  open_garment_order_professional_loaded: false
};

export const doGetOrderOverview = createAsyncThunk(
  "orders/doGetOrderOverview",
  async () =>
    getOrderOverview().then((res: any) => {
      return res;
    })
);


export const doGetOpenOrderProfessionals = createAsyncThunk(
  "agreement/doGetOpenOrderProfessionals",
  async (companyId: any, { dispatch }) =>
    getOpenOrderProfessionals(companyId).then((res: any) => {
      return res;
    })
);

export const doGetOpenGarmentOrderByProfessional = createAsyncThunk(
  "agreement/doGetOpenGarmentOrderByProfessional",
  async (companyId: any, { dispatch }) =>
    getOpenGarmentOrderByProfessional(companyId).then((res: any) => {
      return res;
    })
);




export const doGetOrderByCompany = createAsyncThunk(
  "orders/doGetOrderByCompany",
  async () =>
    getOrderByCompany().then((res: any) => {
      return res;
    })
);

export const doGetOrderByProfessional = createAsyncThunk(
  "orders/doGetOrderByProfessional",
  async () =>
    getOrderByProfessional({
      "client_id": 1,
      "professional_id": 1,
    }).then((res: any) => {
      return res;
    })
);

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    clearOrderOverview: (state) => {
      state.order_overview_list = [];
      state.order_overview_list_loaded = false;
    },
    clearGetOpenOrderProfessionals: (state) => {
      state.open_order_professionals_list = [];
      state.open_order_professionals_loaded = false;
    },
    clearGetOpenGarmentOrderProfessionals: (state) => {
      state.open_garment_order_professional_list = [];
      state.open_garment_order_professional_loaded = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doGetOrderOverview.fulfilled, (state, action) => {
      state.order_overview_list = action.payload.data;
      state.order_overview_list_loaded = true;
    });
    builder.addCase(doGetOrderByCompany.fulfilled, (state, action) => {
      state.order_by_company_list = action.payload.data;
      state.order_by_company_list_loaded = true;
    });
    builder.addCase(doGetOrderByProfessional.fulfilled, (state, action) => {
      state.order_by_professional_list = action.payload.data;
      state.order_by_professional_list_loaded = true;
    });
    builder.addCase(doGetOpenOrderProfessionals.fulfilled, (state, action) => {
      state.open_order_professionals_list = action.payload;
      state.open_order_professionals_loaded = true;
    });
    builder.addCase(doGetOpenGarmentOrderByProfessional.fulfilled, (state, action) => {
      state.open_garment_order_professional_list = action.payload;
      state.open_garment_order_professional_loaded = true;
    });
  },
});

export const {
  clearOrderOverview,
  clearGetOpenOrderProfessionals,
  clearGetOpenGarmentOrderProfessionals,
} = orderSlice.actions;

export default orderSlice.reducer;
