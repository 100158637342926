import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  addAgreement,
  addAgreementNote,
  deleteAgreement,
  editAgreement,
  getAgreementList,
  getAgreementNotes,
  getAgreementProfessionalList,
} from "api/agreement.api";
import { notificationController } from "controllers/notificationController";
import {
  AgreementModel,
  AgreementNoteModel,
  AgreementNoteRequest,
  AgreementProfessional,
  AgreementRequest,
} from "domain/Agreement";

export interface AgreementSlice {
  agreement_list: AgreementModel[] | null;
  agreement_professional_list: AgreementProfessional | null;
  agreement_note_list: AgreementNoteModel[] | null;
}

const initialState: AgreementSlice = {
  agreement_list: null,
  agreement_professional_list: null,
  agreement_note_list: null,
};

export const getAgreements = createAsyncThunk(
  "agreement/getAgreement",
  async () =>
    getAgreementList().then((res: any) => {
      //const resData = JSON.parse(res);
      return res;
    })
);

export const doAddAgreement = createAsyncThunk(
  "auth/doAddAgreement",
  async (agreementPayload: AgreementRequest, { dispatch }) =>
    addAgreement(agreementPayload).then((res) => {
      dispatch(getAgreements());

      return res;
    })
);

export const doUpdateAgreement = createAsyncThunk(
  "auth/doUpdateAgreement",
  async (agreementPayload: AgreementRequest, { dispatch }) =>
    editAgreement(agreementPayload.id, agreementPayload).then((res) => {
      dispatch(getAgreements());

      return res;
    })
);

export const doDeleteAgreement = createAsyncThunk(
  "auth/doDeleteAgreement",
  async (agreementData: { id: number, delete_reason: string },
    { dispatch }) => {
    const { id, delete_reason } = agreementData
    deleteAgreement(id, delete_reason).then(
      (res) => {
        dispatch(getAgreements());
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      })
  }
);

export const doGetAgreementNotes = createAsyncThunk(
  "agreement/doGetAgreementNotes",
  async (id: number) =>
    getAgreementNotes(id).then((res: any) => {
      //const resData = JSON.parse(res);
      return res;
    })
);

export const doGetAgreementProfessional = createAsyncThunk(
  "agreementProfessional/doGetAgreementProfessional",
  async (id: any) =>
    getAgreementProfessionalList(id).then((res: any) => {
      return res;
    })
);

export const doAddAgreementNotes = createAsyncThunk(
  "agreement/doAddAgreementNotes",
  async (agreementPayload: AgreementNoteRequest, { dispatch }) =>
    addAgreementNote(agreementPayload).then((res) => {
      dispatch(doGetAgreementNotes(agreementPayload.agreement_id));

      return res;
    })
);

const authSlice = createSlice({
  name: "agreement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAgreements.fulfilled, (state, action) => {
      //action.payload.substring(1, action.payload.length-1);
      state.agreement_list = action.payload;
    });
    builder.addCase(doGetAgreementProfessional.fulfilled, (state, action) => {
      //action.payload.substring(1, action.payload.length-1);
      state.agreement_professional_list = action.payload;
    });
    builder.addCase(doGetAgreementNotes.fulfilled, (state, action) => {
      //action.payload.substring(1, action.payload.length-1);
      alert(33);
      // state.agreement_list = JSON.parse(
      //   action.payload.substring(1, action.payload.length - 1)
      // );
    });
    // builder.addCase(addAgreement.fulfilled, (state, action) => {});
  },
});

export default authSlice.reducer;
