import React from 'react'

const RfidListIcon = ({ filled = false }) => {
  return (
    <svg
      width={43}
      height={23}
      viewBox="0 0 43 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9001 13.3002V17.1002H1.90011V1.90011H9.49999V0H1.90011C0.855293 0 0 0.854438 0 1.90011V17.1002C0 18.145 0.855293 19.0003 1.90011 19.0003H10.4502V20.9004H5.70005V22.8002H17.1002V20.9004H12.35V19.0003H20.9001C21.9449 19.0003 22.8002 18.145 22.8002 17.1002V13.3002H20.9001ZM22.8002 3.80022C20.7017 3.80022 19.0003 2.09847 19.0003 0H20.9001C20.9001 0.503941 21.1003 0.987242 21.4566 1.34358C21.813 1.69992 22.2963 1.90011 22.8002 1.90011V3.80022Z"
        fill={filled ? '#268959' : "#BBBBBB"}
      />
      <path
        d="M22.8001 7.60016C18.6029 7.60016 15.2 4.19723 15.2 0H17.1001C17.1001 3.14785 19.6523 5.70005 22.8001 5.70005V7.60016Z"
        fill={filled ? '#268959' : "#BBBBBB"}
      />
      <path
        d="M22.8 11.4001C16.5043 11.4001 11.3999 6.29571 11.3999 0H13.3C13.3 5.24633 17.5537 9.50027 22.8 9.50027V11.4001Z"
        fill={filled ? '#268959' : "#BBBBBB"}
      />
      <path
        d="M42 9L37.5 13L33 9"
        stroke="black"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default RfidListIcon