import React, { useEffect, useMemo, useState } from 'react'
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import './ShipmentListMenu.css'
import { Button, Menu, Modal } from 'antd';
import { useNavigate, useParams } from "react-router-dom";
import { clearShippingBatchItems, clearShippingDocumentDetails, doGetBatchItems, doGetShippingDocumentDetailsById as doGetShippingDocumentDetailsByIdV1 } from 'store/slices/shippingDocumentSlice';
import { clearShippingBatchItems as clearShippingBatchItemsV3, clearSelectedBatchId, clearShippingRFIDScans, doGetBatchItems as doGetBatchItemsV3, setSelectedBatchId, setIsBatchOrRFIDClicked, setShipmentClientId, doGetShippingDocumentDetailsById as doGetShippingDocumentDetailsByIdV3 } from 'store/slices/shippingDocumentSliceV3';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { DeleteIcon } from 'icons/Icons';
import { deleteScan, getAttachedRFIDReader, getBatchesOfShipment, newScan } from 'api/shipping.api';
import { DownIcon } from 'icons/svg';
import { notificationController } from 'controllers/notificationController';
import { attachRFID, getRFIDReaders } from 'api/shipping.api';
import RfidCorruptedModel from './RfidCorruptedModel';
import DonateClothes from "../../../icons/animations/donate-clothes.json"
import { isDocDeliveredOrAppr, lottieOptions } from 'utils/utils';
import Lottie from 'react-lottie';
import ShipmentProgressBar from './ShipmentProgressBar/ShipmentProgressBar';
import { IShipmentRoutes, TRoutes } from 'domain/Shipment';
import { doGetRFIDScansItems } from 'store/slices/shippingDocumentSliceV3';
import useFetchRFIDReaders from 'hooks/useFetchRFIDReaders';
import RfidAttachModelShared from 'components/common/RfidAttachModelShared';

const { confirm } = Modal;

export default function ShipmentListMenu({isShipmentPageV2, isShipmentPageV3}: {
  isShipmentPageV2?: boolean, isShipmentPageV3?: boolean}) {

  const shipmentList: IShipmentRoutes[] = [
    {
      name: 'All Scans',
      route: 'all-scans',
      qty: '0'
    },
    {
      name: 'Barcodes',
      route: 'barcode',
      qty: '0'
    },
    {
      name: 'RFID Scans',
      route: 'rfid',
      qty: '0',
      children: []
    },
    {
      name: 'Bulk Orders',
      route: 'bulk-orders',
      qty: '0'
    },
    {
      name: 'Missing Items',
      route: 'missing-items',
      qty: 0,
    },
    {
      name: 'COG & Bulk',
      route: 'wash',
      qty: '0'
    },
  ]
  const [loading, setLoading] = useState<boolean>(false);
  const [newScanLoading, setNewScanLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rfIdAttachRequired, setRFIDAttachRequired] = useState<any>(false);
  const [rfidAttachModelVisible, setRfidAttachModelVisible] = useState<any>(false);
  const [rfidCorruptedModel, setRfidCorruptedModel] = useState<boolean>(false);
  const [isException, setIsException] = useState<boolean>(false);
  const [rfidCorruptedData, setRfidCorruptedData] = useState<any>();
  const [shipmentListData, setShipmentListData] = useState<IShipmentRoutes[]>(shipmentList);
  const [selectedKey, setSelectedKey] = useState<string>('');
  const [selectedSubmenu, setSelectedSubmenu] = useState<number | null>(null);
  const [hadRFIDBatches, setHasRFIDBatches] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const fetchRFIDReaders = useFetchRFIDReaders()
  const dispatch = useAppDispatch();
  const shipmentClientID = useAppSelector((state) => state.shippingDocumentV3.shipment_client_id);
  const basePath = isShipmentPageV3 ? "shipment-document-v3" : isShipmentPageV2 ? "shipment-document-v2" : "shipment-document";

  const {
    shipping_document_details,
    rfid_Scanner_connected,
    shipment_list_update_flag
  } = useAppSelector((state) => isShipmentPageV3 ? state.shippingDocumentV3 : state.shippingDocument);

  const doGetShippingDocumentDetailsById = isShipmentPageV3 ? doGetShippingDocumentDetailsByIdV3 : doGetShippingDocumentDetailsByIdV1 
  const { pathname } = window.location;
  const path = pathname.split('/').pop()
  const isRfidPage = path === 'rfid';

  const filteredShipmentList = shipmentListData?.filter((item: any) => {
    return (
      (shipping_document_details?.shipment_type === 1 && item.route !== 'bulk-orders') ||
      (shipping_document_details?.shipment_type === 2 && item.route !== 'wash')
    );
  });

  const getNewScan = () => {
    setNewScanLoading(true);
    newScan(id, {}).then(
      (data: any) => {
        loadBatches(false, true);
        dispatch(doGetShippingDocumentDetailsById(id));
        setNewScanLoading(false);
        if (data?.data || data?.unknown_tags) {
          setRfidCorruptedModel(true);
          if (data?.data || data?.unknown_tags) setRfidCorruptedData(data)
        }
      },
      (err) => {
        dispatch(doGetShippingDocumentDetailsById(id));
        loadBatches(false, true);
        setNewScanLoading(false);
        notificationController.error({ message: err.message });
      }
    );
  };

  const loadBatches = (first: boolean = false, last: boolean = false, isDeletingBatch: boolean = false) => {
    setLoading(true);
    getBatchesOfShipment(id)
      .then((data) => {
        const children: any = []
        data.map((item) => {
          children.push({
            name: 'RFID' + item.batch_number,
            route: item.id + '/',
            id: item.id,
            qty: item.num_of_items,
            exception: +item.number_of_batch_exceptions,
          })
        })
        const exception = children.some((scan: any) => scan.exception > 0)
        setIsException(exception)
        if(children?.length) setHasRFIDBatches(true)
      
        setShipmentListData((prev) => {
          const newShipmentList = [...prev];
          newShipmentList[2].children = children;
          newShipmentList[2].qty = children.length;
          return newShipmentList;
        });

        setLoading(false);
        if (first && isRfidPage && !isDeletingBatch) {
          setSelectedKey(children[0]?.route)
          if(!isShipmentPageV3){
            dispatch(doGetBatchItems({ id: data?.[0]?.id, batchNumber: 'RFID' + data?.[0]?.batch_number }));
          }
        }
        if (last && isRfidPage && !isDeletingBatch) {
          setSelectedKey(children[children.length - 1].route)
          if(!isShipmentPageV3){
            dispatch(doGetBatchItems({
              id: data[data.length - 1].id,
              batchNumber: 'RFID' + data?.[data.length - 1]?.batch_number
            }));
          }
        }
      })
      .catch((err) => {
        console.log(err)
       });
  };

  const checkAttachedRFIDReader = () => {
    getAttachedRFIDReader()
      .then((data) => {
        if (data.length == 0) {
          setRFIDAttachRequired(true);
        } else {
          setRFIDAttachRequired(false);
        }
      })
      .catch((err) => {
        console.log(err)
       });
  };

  const updateShipmentMenuQuantity = ({ payload }: any) => {
    if(!payload) return
    const menuKeys: { [key in TRoutes]: string } = {
      "all-scans": "",
      barcode: "number_of_barcode_items",
      rfid: "",
      "bulk-orders": "number_of_bulk_orders",
      "missing-items": "number_of_missing_items",
      wash: "number_of_wash_items",
    };

    const updatedShipmentList = shipmentList.map((shipment) => {
      // updating the shipmentList according to the keys
      const keyOfApi = menuKeys[shipment.route];
      if (!keyOfApi) return shipment;
      return { ...shipment, qty: payload[keyOfApi] };
    });

    // set All scans value
    updatedShipmentList[0].qty =
      +payload?.number_of_rfid_items + +payload?.number_of_barcode_items;
    setShipmentListData(updatedShipmentList)
  };

  const handleShipmentUpdate = async (id: string, shouldUpdateClientId: boolean) => {
    try {
      const res = await dispatch(doGetShippingDocumentDetailsById(id));
      const response = res?.payload;
      shouldUpdateClientId && await dispatch(setShipmentClientId(response?.client_id));
      await updateShipmentMenuQuantity(res ?? response);

    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  useEffect(() => {
    if(id) handleShipmentUpdate(id, true)
    loadBatches(true);
    checkAttachedRFIDReader()

    return () => {
      dispatch(clearShippingDocumentDetails())
      dispatch(clearSelectedBatchId())
      setShipmentListData(shipmentList)
    };
  }, [id])
  
  useEffect(()=>{
    id && handleShipmentUpdate(id, false)
  },[shipment_list_update_flag])

  useEffect(() => {
    checkAttachedRFIDReader()
  }, [rfid_Scanner_connected])

  const navigateToShipmentRFIDDetails = (id: string) => {
      navigate(`${basePath}/details/${id}/rfid`);
  };
  const handleMenuItemSelect = (selectedValue: { key: string }) => {
    if (!selectedValue.key) return;
  
    const { key } = selectedValue;
    const isRFIDBatchedItems = key.includes('/');
    const batchId = key.split('/')[0];
  
    const handleRFIDBatchItems = () => {
      setSelectedKey(key);
      dispatch(setIsBatchOrRFIDClicked("batch"));
      navigateToShipmentRFIDDetails(id!!);
      
      const selectedBatch: any = shipmentListData[2]?.children?.find((item: any) => item.id == batchId);
      dispatch(setSelectedBatchId(batchId));
  
      if (isShipmentPageV3) {
        dispatch(clearShippingBatchItemsV3());
        dispatch(clearShippingRFIDScans());
        dispatch(doGetBatchItemsV3({
          shipment_batch_id: batchId,
          scan_type: 1,
          pagination: { current: 1, pageSize: 100 },
          sort_order: "descend",
          sort_field: "created_on",
          client_id: shipmentClientID,
          shipment_document_id: id
        }));
      } else {
        dispatch(doGetBatchItems({ id: batchId, batchNumber: selectedBatch.name }));
      }
    };
    
    if (isRFIDBatchedItems) {
      handleRFIDBatchItems();
    } else if (isShipmentPageV3 && key === "rfid") {
      handleRFIDItems(key);
    } else {
      handleDefaultItems(key);
    }
  };
  
  const handleDefaultItems = (key: string | number) => {
    dispatch(clearShippingBatchItems());
    setSelectedKey('');
    navigate(`${basePath}/details/${id}/${key}`);
    dispatch(clearShippingBatchItems());
  };

  const handleRFIDItems = (key: string | number) => {
    dispatch(setIsBatchOrRFIDClicked("rfid"));
    setSelectedKey("rfid");
    dispatch(clearShippingBatchItemsV3());
    navigate(`shipment-document-v3/details/${id}/${key}`);
  };

  const deleteBatch = (batch_id: any) => {
    confirm({
      title: "Are you sure want to delete the Batch?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      onOk() {
        setLoading(true);
        deleteScan(batch_id).then(
          () => {
            setLoading(false);
            dispatch(clearShippingBatchItemsV3())
            dispatch(setIsBatchOrRFIDClicked("rfid"))
            dispatch(doGetRFIDScansItems({
              scan_type: 1,
              pagination: { current: 1, pageSize: 100 },
              sort_order: "ascend",
              sort_field: "professional_name",
              client_id: shipmentClientID,
              shipment_document_id: id
            }));
            setSelectedKey("rfid")
            notificationController.success({
              message: "Batch deleted successfully.",
            })
            fetchUpdatedMenuData()
          },
          (err) => {
            setLoading(false);
            notificationController.error({ message: err.message });
          }
        );
      },
      onCancel() { },
    });
  };
  const fetchUpdatedMenuData = async () => {
    if(id) await handleShipmentUpdate(id, false)
    loadBatches(true, false, true);
  }
  
  return (
    <div className='shipment-list'>
      {newScanLoading && (
        <div className="scan-loading">
          <Lottie options={lottieOptions(DonateClothes)} width={500} />
        </div>
      )}
      {loading && (
        <div className="full-loading">
          <span>Loading…</span>
        </div>
      )}

      <ShipmentProgressBar
        itemScanned={shipping_document_details?.total_scanned_items}
        missingItems={shipping_document_details?.number_of_missing_items}
      />

      <div className="shipment-wrapper">
        <Menu
          theme="light"
          mode="inline"
          style={{ background: '#E0E0E0', color: '#000000' }}
          defaultSelectedKeys={[path || 'barcode']}
          selectedKeys={isRfidPage && hadRFIDBatches ? [selectedKey] : [path || 'barcode']}
          onSelect={handleMenuItemSelect}
        >
          {filteredShipmentList?.map((nav: any, index: number) => (
            nav?.children?.length > 0 ? (
              <div key={index}>
                <Menu.SubMenu
                onTitleClick={() => {
                  if(isShipmentPageV3){
                    navigate(`shipment-document-v3/details/${id}/rfid`);
                    dispatch(setIsBatchOrRFIDClicked("rfid"))
                    dispatch(doGetRFIDScansItems(
                      { scan_type: 1,
                        pagination: { current: 1, pageSize: 100 },
                        client_id: shipmentClientID,
                        sort_order: "descend",
                        sort_field: "created_on",
                        shipment_document_id: id
                      }))
                    setSelectedKey("rfid")
                    setSelectedSubmenu(index)
                  }
                }}
                  theme='light'
                  className={isShipmentPageV3 && selectedSubmenu ? 'selected-submenu-dropdown' : ''}
                  title={
                    <div 
                    className={`justify-between ${isShipmentPageV3 && selectedSubmenu && 'selected-submenu-dropdown'}`}
                    >
                      <span>
                        <DownIcon />
                        <span>
                          {nav.name}
                        </span>
                      </span>
                      <div className={`${isException ? 'rfid-detail' : 'rfid-detail-end'}`}>
                        {isException && <div className='exception-show' />}
                        <p>({nav.qty})</p>
                      </div>
                    </div>
                  }
                  popupClassName="d-none"
                >
                  {nav.children.map((childNav: any) => {
                    return (
                      <Menu.Item className={`submenu-items ${selectedKey?.includes(childNav.route) && 'selectd-scan'}`}
                        key={childNav.route}>
                        <div className='justify-between scan-item'>
                          <span>{childNav.name}</span>
                          <div className={` ${childNav.exception > 0 ? 'scan-details' : 'scan-details-none'}`}>
                            {childNav.exception > 0 && <div className='exception'>{childNav.exception}</div>}
                            <div className='flex'>
                              <span>{`(${childNav.qty})`}</span>
                              {isDocDeliveredOrAppr(shipping_document_details) ? 
                                <div className='rfid-level2-space'></div>
                              :
                                <span className="delete-scan">
                                <Button
                                  type="link"
                                  style={{ padding: '3px' }}
                                  onClick={() => {
                                    deleteBatch(childNav.id);
                                  }}
                                  title={"Delete"}
                                >
                                  <DeleteIcon />
                                </Button>
                              </span>}
                            </div>
                          </div>
                        </div>
                      </Menu.Item>
                    )
                  })}
                </Menu.SubMenu>
                <div className='divider' />
              </div>
            ) : (
              <Menu.Item key={nav.route}>
                <div className='justify-between'>
                  <span>{nav.name}</span>
                  <span>{`(${nav.qty})`}</span>
                </div>
              </Menu.Item>
            )
          ))}
        </Menu>
      </div>
      <div className="nested-btn">
        <button
          title='New Scan'
          disabled={shipping_document_details?.shipment_status_value === 'delivered' ||
            shipping_document_details?.shipment_status_value === 'approved'}
          onClick={() => {
            if (!rfIdAttachRequired) getNewScan();
            else {
              // navigate(`shipment-document/details/${id}/rfid`);
              navigateToShipmentRFIDDetails(id!!)
              setRfidAttachModelVisible(true)
            };
          }}
        >
          <PlusOutlined />
        </button>
      </div>
      {rfidAttachModelVisible &&
        <RfidAttachModelShared
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          rfidAttachModelVisible={rfidAttachModelVisible}
          setRFIDAttachRequired={setRFIDAttachRequired}
          setRfidAttachModelVisible={setRfidAttachModelVisible}
          reloadScanners={fetchRFIDReaders}
          fetchUpdatedMenuData={fetchUpdatedMenuData}
          attachRFID={attachRFID}
          getRFIDReaders={getRFIDReaders}
          btnText={"Scan Now"}
        ></RfidAttachModelShared>}
      <RfidCorruptedModel
        rfidCorruptedModel={rfidCorruptedModel}
        setRfidCorruptedModel={setRfidCorruptedModel}
        rfidCorruptedData={rfidCorruptedData}
      ></RfidCorruptedModel>
    </div>
  )
}