import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
} from "antd";
import { useAppDispatch } from "hooks/reduxHooks";
import { notificationController } from "controllers/notificationController";
import { doLogin, doLogout } from "store/slices/authSlice";
import "./LoginForm.css";
import { getProfileID } from "services/localStorage.service";

interface LoginFormData {
  username: string;
  password: string;
}

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (values: LoginFormData) => {
    setLoading(true);
    //first logout to remove previous user data from localStorage and sessionStorage
    dispatch(doLogout()).then(async () => {
      try {
        const data:any = await dispatch(doLogin(values)).unwrap();
        if (data?.is_account) {
          switch (getProfileID()) {
            case 5:
              navigate("/orders");
              break;
            case 6:
              navigate("/report/list");
              break;
            case 7:
              navigate("/shipment-document-v3/list");
              break;
            case 8:
              navigate("/incoming-soil-v2/list");
              break;
            default:
              // navigate("/dashboard");
              navigate("/client/list");
          }
        } else {
          navigate("/dashboard");
          // navigate("/client/list");
        }
      } catch (err: any) {
        setLoading(false);
        notificationController.error({ message: err.message });
      } finally {
        setLoading(false);
      }
    }).catch((err: any) => {
      setLoading(false);
      notificationController.error({ message: err.message });
    })
  };


  return (
    <>
      <Row className="login-wrapper">
        <Col span={12}>
          <img
            src="/login-logo.png"
            alt="logo"
            className="logo-img"
          />
        </Col>
        <Col span={12} className="login-form-wraper">
          <div className="login-form">
            <h1 className="welcome-text">Welcome back</h1>
            <p className="details">Please enter your contact details to connect.</p>
            <Form
              layout="vertical"
              className="form-data"
              style={{ padding: 0 }}
              initialValues={{ remember: true }}
              onFinish={handleSubmit}
              requiredMark={false}
              autoComplete="off"
            >
              <Form.Item
                label="Email address"
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <div className="justify-between">
                <Form.Item name="remember" valuePropName="checked" >
                  <Checkbox> <span className="remember">Remember me</span></Checkbox>
                </Form.Item>
                <p className="forgot-text">Forgot password</p>
              </div>

              <Form.Item >
                <Button className="login-btn" htmlType="submit" loading={isLoading}>
                  Log in
                </Button>
              </Form.Item>
              <p className="account-text">Don't have an account ?
                <span className="forgot-text"> Sign up here</span></p>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
};

