import { httpApi } from "api/http.api";
import {
  AgreementModel,
  AgreementNoteRequest,
  AgreementRequest,
  AgreementResponse,
  UpdateAgreementNoteRequest,
} from "domain/Agreement";
import moment from "moment";

export const getAgreementList = (): Promise<AgreementModel[]> =>
  httpApi
    .get<AgreementModel[]>("Agreements")
    .then((data: any) => data["data"])
    .then(({ data }) => data);

export const getClientAgreementList = (): Promise<AgreementModel[]> =>
  httpApi
    .get<any[]>("/PlanTemplates/GetClientAgreements")
    .then((data: any) => data["data"])
    .then(({ data }) => data);

export const getClientAgreementsForPlanTemplate = (): Promise<AgreementModel[]> =>
  httpApi
    .get<any[]>("PlanTemplates/GetClientAgreementsForPlanTemplate")
    .then((data: any) => data["data"])
    .then(({ data }) => data);

export const addAgreement = (
  agreementPayload: AgreementRequest
): Promise<AgreementResponse> =>
  httpApi
    .post<AgreementResponse>("Agreements/LeaseAgreement", {
      ...agreementPayload,
    })
    .then(({ data }) => data);

export const editAgreement = (
  id: string,
  agreementPayload: AgreementRequest
): Promise<AgreementResponse> =>
  httpApi
    .put<AgreementResponse>("Agreements/LeaseAgreement/" + id, {
      ...agreementPayload,
    })
    .then(({ data }) => data);


export const deleteAgreement = async (
  agreement_id: number,
  delete_reason: string
): Promise<AgreementResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<AgreementResponse>(
    `Agreements/DeleteAgreement/${agreement_id}`,
    {
      data: {
        hmac_time: time,
        delete_reason: delete_reason
      },
    }
  );
  return httpApi.delete<AgreementResponse>(
    `Agreements/DeleteAgreement/${agreement_id}`,
    {
      data: {
        hmac_time: time,
        hmac: hmac_token?.data?.hmac,
        delete_reason: delete_reason,
      },
    }
  );
};

export const getAgreementById = (id: any): Promise<AgreementModel> =>
  httpApi
    .get<any>(`Agreements/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const getAgreementProfessionalList = (id: any): Promise<any> =>
  httpApi
    .get<any>("Professionals/GetProfessionalsByAgreementID/" + id)
    .then(({ data }) => data);

export const activateAgreement = (id: number): Promise<AgreementModel> =>
  httpApi
    .put<AgreementModel>(`Agreements/${id}/ActivateAgreement`, {})
    .then(({ data }) => data);

export const pauseAgreement = (id: number): Promise<AgreementModel> =>
  httpApi
    .put<AgreementModel>(`Agreements/${id}/PauseAgreement`, {})
    .then(({ data }) => data);

export const addAgreementNote = (
  agreementNotePayload: AgreementNoteRequest
): Promise<AgreementResponse> =>
  httpApi
    .post<AgreementResponse>("AgreementNotes", {
      ...agreementNotePayload,
    })
    .then(({ data }) => data);

export const updateAgreementNote = (
  id: number,
  data: UpdateAgreementNoteRequest
): Promise<AgreementModel> =>
  httpApi
    .put<AgreementModel>(`AgreementNotes/${id}`, data)
    .then(({ data }) => data);

export const deleteAgreementNoteById = async (
  id: number
): Promise<AgreementResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<AgreementResponse>(
    `AgreementNotes/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<AgreementResponse>(`AgreementNotes/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};

export const getAgreementNoteById = (id: number): Promise<AgreementModel> =>
  httpApi
    .get<AgreementModel>(`AgreementNotes/${id}`)
    .then((data: any) => {
      return data.data.data[0];
    })
    .then((data) => data);

export const getAgreementNotes = (id: number): Promise<AgreementModel> =>
  httpApi
    .get<AgreementModel>(`AgreementNotes/GetAgreementNotes/${id}`)
    .then((data: any) => data.data)
    .then(({ data }) => data);

export const getBulkAgreementId = (): Promise<AgreementModel> =>
  httpApi
    .get<AgreementModel>(`Agreements/GetBulkAgreement`)
    .then((data: any) => data.data)
    .then(({ data }) => data);

export const markNoteComplete = (id: number): Promise<AgreementModel> =>
  httpApi
    .put<AgreementModel>(`AgreementNotes/${id}/MarkAsCompleted`, {})
    .then(({ data }) => data);
