import moment from "moment";
import { httpApi } from "api/http.api";
import { Drivers, DriversRequest, DriversResponse } from "domain/Drivers";

/**
 *
 * @returns Drivers[]
 */
export const getDriversList = (): Promise<Drivers[]> =>
  httpApi.get<Drivers[]>("AccountDrivers").then(({ data }) => data);

export const addDrivers = (
  garmentsPayload: DriversRequest
): Promise<DriversResponse> =>
  httpApi
    .post<DriversResponse>("AccountDrivers", {
      ...garmentsPayload,
    })
    .then(({ data }) => data);

export const updateDrivers = (
  id: any,
  driversPayload: DriversRequest
): Promise<DriversResponse> =>
  httpApi
    .put<DriversRequest>("AccountDrivers/" + id, {
      ...driversPayload,
    })
    .then(({ data }) => data);

export const getDriversById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`AccountDrivers/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const deleteDrivers = async (id: number): Promise<DriversResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<DriversResponse>(
    `AccountDrivers/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<DriversResponse>(`AccountDrivers/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};
