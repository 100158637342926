import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addIncomingSoil,
  deleteIncomingSoil,
  getIncomingSoilList,
  updateIncomingSoil,
} from "api/incomingSoil.api";
import { notificationController } from "controllers/notificationController";
import { IncomingSoil, IncomingSoilRequest } from "domain/IncomingSoil";

export interface IncomingSoilSlice {
  incomingSoilList: IncomingSoil[] | null;
}

const initialState: IncomingSoilSlice = {
  incomingSoilList: null,
};

export const doGetIncomingSoil = createAsyncThunk(
  "incomingSoil/doGetIncomingSoil",
  async () =>
    getIncomingSoilList().then((res: any) => {
      return res;
    })
);

export const doAddIncomingSoil = createAsyncThunk(
  "incomingSoil/doAddGarment",
  async (incomingSoilPayload: IncomingSoilRequest, { dispatch }) =>
    addIncomingSoil(incomingSoilPayload).then((res) => {
      dispatch(doGetIncomingSoil());
      return res;
    })
);
export const doUpdateIncomingSoil = createAsyncThunk(
  "incomingSoil/doUpdateGarment",
  async (incomingSoilPayload: any, { dispatch }) =>
    updateIncomingSoil(incomingSoilPayload.id, incomingSoilPayload).then(
      (res) => {
        dispatch(doGetIncomingSoil());
        return res;
      }
    )
);

export const doDeleteIncomingSoil = createAsyncThunk(
  "incomingSoil/doDeleteIncomingSoil",
  async (incomingSoilId: any, { dispatch }) => {
    deleteIncomingSoil(incomingSoilId).then(
      (res) => {
        notificationController.error({
          message: "Incoming soil deleted successfully.",
        });
        dispatch(doGetIncomingSoil());
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);

const incomingSoilSlice = createSlice({
  name: "incomingSoil",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetIncomingSoil.fulfilled, (state, action) => {
      state.incomingSoilList = action.payload.data;
    });
  },
});

export default incomingSoilSlice.reducer;
