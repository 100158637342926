import { DefaultTheme } from "styled-components";
import jwtDecode from 'jwt-decode';
import moment from "moment";
import { IKeyValuePair } from "components/reportV2/reportList/ReportList";
import { NestedObject } from "domain/globalTypes";

export const camelize = (string: string): string => {
  return string
    .split(" ")
    .map((word, index) =>
      index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)
    )
    .join("");
};

export const getDate = (date: string): string => {
  if (!date) return "";
  return date?.split("T")[0];
};

export const getDateTime = (dateTime: string): string => {
  if (!dateTime) return "";

  const [date, time] = dateTime.split("T");
  const [hours, minutes] = time.split(":");
  
  return `${date}  ${hours}:${minutes}`;
};

export const getCurrencyPrice = (price: number, currency: string): string => {
  switch (currency) {
    case "USD": {
      return `$${price}`;
    }

    default: {
      return `$${price}`;
    }
  }
};

export const parseJwt = (token: string) => {
  if (!token) {
    return;
  }

  return jwtDecode(token);
};

type MarkArea = {
  xAxis: number;
};

export const getMarkAreaData = (data: string[] | number[]): MarkArea[][] =>
  data.map((el, index) => [
    {
      xAxis: 2 * index,
    },
    {
      xAxis: 2 * index + 1,
    },
  ]);

export const capitalize = (word: string): string =>
  `${word[0].toUpperCase()}${word.slice(1)}`;

export const hexToRGB = (hex: string, alpha: string | number): string => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`;
};

export const getDifference = (
  value: number,
  prevValue: number
): string | null =>
  prevValue !== 0
    ? `${((Math.abs(value - prevValue) / prevValue) * 100).toFixed(0)}%`
    : "100%";

export const normalizeProp = (
  prop: string | number | [number, number]
): string =>
  typeof prop === "number"
    ? `${prop}px`
    : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString();

// export const defineColorByPriority = (priority: Priority, theme: DefaultTheme): string => {
//   switch (priority) {
//     case Priority.INFO:
//       return theme.colors.main.primary;
//     case Priority.LOW:
//       return theme.colors.main.success;
//     case Priority.MEDIUM:
//       return theme.colors.main.warning;
//     case Priority.HIGH:
//       return theme.colors.main.error;
//     default:
//       return theme.colors.main.success;
//   }
// };

// export const defineColorBySeverity = (severity: NotificationType | undefined, theme: DefaultTheme): string => {
//   switch (severity) {
//     case 'error':
//       return theme.colors.main.error;
//     case 'warning':
//       return theme.colors.main.warning;
//     case 'success':
//       return theme.colors.main.success;
//     case 'info':
//       return theme.colors.main.primary;
//     default:
//       return theme.colors.main.primary;
//   }
// };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeBy = (a: any[], b: any[], key: string): any[] =>
  a
    .filter((elem) => !b.find((subElem) => subElem[key] === elem[key]))
    .concat(b);

export const getSmoothRandom = (factor: number, start: number): number => {
  const halfEnvelope = 1 / factor / 2;
  const max = Math.min(1, start + halfEnvelope);
  const min = Math.max(0, start - halfEnvelope);

  return Math.random() * (max - min) + min;
};

export const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
};

export const trimStr = (str: string, charCount = 15) => {
  if (!str) return "";
  var res = str.substring(0, charCount);
  return res + "...";
};

export const isMorePages = (dataLength: any, perPageRecord = 10): any => {
  dataLength = dataLength ? dataLength : 0;
  return dataLength > perPageRecord ? true : false;
};

export function Ellipsis({ text, length = 30 }: { text: any; length?: number }) {
  if (typeof text == 'string') {
    return text.length > length - 2 ? text.substring(0, length - 2) + '...' : text;
  } else {
    return text;
  }
}
export const StatusColor: any = {
  pending: '#D44848',
  delivered: '#BBBBBB',
  draft: '#FFE600',
  approved: '#268959',
}

export const lottieOptions = (file: {}) => {
  return {
    loop: true,
    autoplay: true,
    animationData: file,
  }
}

export const getQueryStringFromObject = (obj: { [key: string]: any }) => {
  let queryString = "";
  Object.entries(obj).map(([key, val]) => {
    if (!val) return; //removes all falsy values
    if (key === "professional_id") return; // was part of old logic in wareHoseOrderByProfessional.tsx
    queryString += "&";
    queryString += `${key}=${val}`;
  });
  return queryString;
};

export const calculateNearestMonday = () => {
  const today = moment();
  const daysUntilMonday = (1 - today.isoWeekday() + 7) % 7;
  return today.add(daysUntilMonday, 'days').format('YYYY-MM-DD');
};

export const calculateMonthDates = (originalDateString: string, isTimeZone=false): {
  startDate: string;
  endDate: string;
} => {
  const [month, year] = originalDateString.split("-").map(Number);

  const startDate = new Date(year, month - 1, 1);
  const endDate = new Date(year, month, 0);

  const formatDate = (date: Date, isStartDate: boolean): string => {
    const y = date.getFullYear();
    const m = (date.getMonth() + 1).toString().padStart(2, "0");
    const d = date.getDate().toString().padStart(2, "0");
    if (isTimeZone) {
      const time = isStartDate ? "T00:00:00.000Z" : "T23:59:59.999Z";
      return `${y}-${m}-${d}${time}`;
    } else {
      return `${y}-${m}-${d}`;
    }
  };

  return {
    startDate: formatDate(startDate, true),
    endDate: formatDate(endDate, false),
  };
}

export const formatDate = (date: any, isFromDate: any) => {
  const formattedDate = moment(date).format("YYYY-MM-DD");
  const time = isFromDate ? "00:00:00.000Z" : "23:59:59.999Z";
  return `${formattedDate}T${time}`;
};

export const formatDateWithoutTimeZone = (date: Date, isFromDate?: boolean) => {
  const formattedDate = moment(date).format("YYYY-MM-DD");
  return formattedDate;
};

export const formatDateWithMonthName = (date: Date) => {
  const formattedDate = moment(date).format("DD MMM YYYY");
  return formattedDate;
};

export const addFilter = (filters: any, key: string, value: number | string) => {
  if (value !== undefined && value !== null) {
    filters.push(`${key} eq ${value}`);
  }
};

export const addFilterWithEqualOperator = (filters: any, key: string, value: number | string) => {
  if (value !== undefined && value !== null) {
    filters.push(`${key}=${value}`);
  }
};

export const extractName = (fullName: string) => {
  const namePart = fullName.split('-')[0].trim();
  const normalized = namePart.replace(/\s+/g, ' ');
  return normalized?.toLowerCase();
};

export const hasNonEmptyValue = (values: { [key: string]: any }) => {
  return Object.values(values).some(value => value !== '');
};

export const buildFilterQueryStringFormat = (data: any) => {
  const filters = Object.entries(data)
    .filter(([key, value]) => value !== null && value !== undefined)
    .map(([key, value]: any) => {
      if (key == "from_date" || key == "to_date") {
        value = value.toISOString();
      }
      return `${key} eq ${encodeURIComponent(value)}`;
    });

  if (filters.length > 0) {
    return "$filter=" + filters.join(" and ");
  } else {
    return "";
  }
};

export function formatDateToYYYYMMDD(dateString: string): string {
  const date = new Date(dateString);
  return date.toISOString().slice(0, 10);
}

export const flattenGroupedData = (groupedData: any) => {
  const flatData = [];

  for (const key in groupedData) {
    if (groupedData.hasOwnProperty(key)) {
      flatData.push(...groupedData[key].data);
    }
  }

  return flatData;
};

export const mergeStates = (existingState: any, newData: any) => {
  const mergedState = { ...existingState };
  for (let key in newData) {
    if (mergedState.hasOwnProperty(key)) {
      mergedState[key] = mergeStates(mergedState[key], newData[key]);
    } else {
      mergedState[key] = newData[key];
    }
  }
  return mergedState;
};

export const appendChildrenData = (combinedData: any, newData: any) => {
  return combinedData.map((item: any) => {
    const matchingItems = newData.filter(
      (dataItem: any) =>
        dataItem.professional_id === item.professionalId &&
        dataItem.garment_id === item.garment_id &&
        dataItem.client_id === item.client_id
    );

    if (matchingItems.length > 0) {
      return {
        ...item,
        children: matchingItems
      };
    }
    return item;
  });
};

export const filterValueOfColumn = (
  list: Array<{ [key: string]: any }>,
  searchValues: IKeyValuePair
): Array<{ [key: string]: any }> => {
  if (!searchValues) return list;

  return list?.filter((item) => {
    return Object.entries(searchValues).some(([key, value]) => {
      const itemValue =
        typeof item[key] === 'string'
          ? item[key].toLowerCase().trim().replace(/\s+/g, ' ')
          : item[key];
      const searchValue =
        typeof value === 'string'
          ? value.toLowerCase().trim().replace(/\s+/g, ' ')
          : value;
      return itemValue === searchValue;
    });
  });
};

export function containsOnlyNumbers(str: string) {
  return /^\d+$/.test(str);
}

export function isValidDate(dateString:string) {
  const parsedDate = new Date(dateString);
    return !isNaN(parsedDate.getTime());
}

interface IgenerateFilteredColumnConditions {
  rest: Record<string, any>,
  restrictedKeys: string[],
  dateRangeValues?: Record<string, any>,
  integerValues?: Record<string, any>
}


export const generateFilteredColumnConditions = (
  data: IgenerateFilteredColumnConditions
) => {
  const {rest, restrictedKeys, dateRangeValues, integerValues} = data
  return Object.entries(rest)
    .filter(([key, value]) => {
      if (key.includes("isComplete") || restrictedKeys?.includes(key)) {
        return false;
      }
      return value !== null && value !== undefined;
    })
    .map(([key, value]: [string, any]) => {
      if (rest[`${key} isComplete`] === true) {
        if (value === "true" || value === "false" || integerValues?.[key] || typeof value === "boolean") {
          return `${key} eq ${value}`;
          // console.log("trig")
          // return `tolower(${key}) eq tolower(${value})`
        } else {
          return `${key} eq '${value}'`;
          // console.log("trig2")
          // return `tolower(${key}) eq tolower('${value}')`
        }
      } else if (dateRangeValues?.[key]) {
        const startDate = formatDate(value[0], true);
        const endDate = formatDate(value[1], false);
        if (startDate !== "Invalid date" && endDate !== "Invalid date") {
          return `${key} ge ${startDate} and ${key} le ${endDate}`;
        }
      } else if (key === "active" && rest[key] === true) {
        return `${key} eq ${value}`;
      } else {
        if (value === "true" || value === "false" || integerValues?.[key] || typeof value === "boolean") {
          return `contains(${key}, ${value})`;
        } else {
          // return `contains(${key}, '${value}')`;
          if(containsOnlyNumbers(value) || isValidDate(value)){
            return `contains(${key}, '${value}')`;
          } else {
            return `contains(tolower(${key}), tolower('${value}'))`
          }
        }
      }
    });
};



// export const generateFilteredColumnConditions = (
//   data: IgenerateFilteredColumnConditions
// ) => {
//   const {rest, restrictedKeys, dateRangeValues, integerValues} = data
//   return Object.entries(rest)
//     .filter(([key, value]) => {
//       if (key.includes("isComplete") || restrictedKeys?.includes(key)) {
//         return false;
//       }
//       return value !== null && value !== undefined;
//     })
//     .map(([key, value]: [string, any]) => {
//       if (rest[`${key} isComplete`] === true) {
//         if (value === "true" || value === "false" || integerValues?.[key] || typeof value === "boolean") {
//           return `${key} eq ${value}`;
//         } else {
//           return `${key} eq '${value}'`;
//         }
//       } else if (dateRangeValues?.[key]) {
//         const startDate = formatDate(value[0], true);
//         const endDate = formatDate(value[1], false);
//         if (startDate !== "Invalid date" && endDate !== "Invalid date") {
//           return `${key} ge ${startDate} and ${key} le ${endDate}`;
//         }
//       } else if (key === "active" && rest[key] === true) {
//         return `${key} eq ${value}`;
//       } else {
//         if (value === "true" || value === "false" || integerValues?.[key] || typeof value === "boolean") {
//           return `contains(${key}, ${value})`;
//         } else {
//           return `contains(${key}, '${value}')`;
//         }
//       }
//     });
// };

export const buildFilterString = (
  filters: string[],
  filteredColumnConditions: any,
  isAccountingWithAndOperatorQuery: boolean,
  hasRestrictedKeys: boolean
) => {
  let filterString = '';

  if (isAccountingWithAndOperatorQuery) {
    if (filters.length > 0 || filteredColumnConditions.length > 0) {
      if (filters.length > 0) {
        filterString += filters.join('&');
      }
      if (filters.length > 0 && filteredColumnConditions.length > 0) {
        filterString += '&';
      }
      if (filteredColumnConditions.length > 0 && !hasRestrictedKeys) {
        filterString += '$filter=';
        filterString += filteredColumnConditions.join(' and ');
      }
    }
  } else {
    if (filters.length > 0 || filteredColumnConditions.length > 0) {
      filterString += '$filter=';
      if (filters.length > 0) {
        filterString += filters.join(' and ');
      }
      if (filters.length > 0 && filteredColumnConditions.length > 0) {
        filterString += ' and ';
      }
      if (filteredColumnConditions.length > 0 && !hasRestrictedKeys) {
        filterString += filteredColumnConditions.join(' and ');
      }
    }
  }

  return filterString;
};

export const getRowSeverityClassName = (record: any) => {
  if (record.severity_level === 1) {
    return 'row-high-severity';
  } else if (record.severity_level === 2) {
    return 'row-medium-severity';
  } else if (record.severity_level === 3) {
    return 'row-low-severity';
  }
  return '';
};


export const containsKeyAtLevel = (obj: NestedObject, keyToFind: string, level: number = 0): boolean =>
  level === 3
    ? Object.keys(obj).some(key => key.includes(keyToFind))
    : Object.values(obj).some(value => typeof value === 'object' && containsKeyAtLevel(value as NestedObject, keyToFind, level + 1));


export const downloadBlob = (blob: Blob, fileName: string): void => {
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(blobUrl);
};

export const isDocDeliveredOrAppr = (docData: any): boolean =>
  ['delivered', 'approved'].includes(docData?.shipment_status_value);

export const addUniqueKeys = (data: any[]) => 
  data?.map((item, index) => ({ ...item, key: `${item.id}_${index}` })) || data;
