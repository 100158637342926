import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addPlanTemplate,
  editPlanTemplate,
  getPlanTemplatesList,
} from "api/planTemplates.api";
import { PlanTemplatesModel, PlanTemplatesRequest } from "domain/PlanTemplates";

export interface PlanTemplatesSlice {
  plan_templates_list: PlanTemplatesModel[] | null;
  plan_templates_list_loaded: boolean;
}

const initialState: PlanTemplatesSlice = {
  plan_templates_list: null,
  plan_templates_list_loaded: false,
};

export const doGetPlanTemplates = createAsyncThunk(
  "planTemplates/doGetPlanTemplates",
  async () =>
    getPlanTemplatesList().then((res: any) => {
      //const resData = JSON.parse(res);
      return res;
    })
);

export const doAddPlanTemplates = createAsyncThunk(
  "planTemplates/doAddPlanTemplates",
  async (payload: PlanTemplatesRequest, { dispatch }) =>
    addPlanTemplate(payload).then((res) => {
      dispatch(doGetPlanTemplates());
      return res;
    })
);

export const doUpdatePlanTemplate = createAsyncThunk(
  "auth/doUpdateAgreement",
  async (payload: PlanTemplatesRequest, { dispatch }) =>
    editPlanTemplate(payload.id, payload).then((res: any) => {
      dispatch(doGetPlanTemplates());
      return res;
    })
);

const planTemplatesSlice = createSlice({
  name: "planTemplates",
  initialState,
  reducers: {
    clearPlanTemplates: (state) => {
      state.plan_templates_list = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doGetPlanTemplates.fulfilled, (state, action) => {
      state.plan_templates_list_loaded = true;
      state.plan_templates_list = action.payload;
    });
  },
});

export const { clearPlanTemplates } = planTemplatesSlice.actions;

export default planTemplatesSlice.reducer;
