import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addGarmentFabric,
  deleteGarmentFabric,
  getGarmentFabricList,
  updateGarmentFabric,
} from "api/garmentFabric.api";
import { notificationController } from "controllers/notificationController";
import {
  GarmentFabric,
  GarmentFabricRequest,
} from "domain/garmentFabricInterface";

export interface GarmentFabricSlice {
  garmentFabricList: GarmentFabric[] | null;
}

const initialState: GarmentFabricSlice = {
  garmentFabricList: null,
};

export const doGetGarmentFabric = createAsyncThunk(
  "garmentFabric/doGetGarmentFabric",
  async () =>
    getGarmentFabricList().then((res: any) => {
      return res;
    })
);

export const doAddGarmentFabric = createAsyncThunk(
  "garmentFabric/doAddGarment",
  async (professionalPayload: GarmentFabricRequest, { dispatch }) =>
    addGarmentFabric(professionalPayload).then((res) => {
      dispatch(doGetGarmentFabric());
      return res;
    })
);
export const doUpdateGarmentFabric = createAsyncThunk(
  "garmentFabric/doUpdateGarment",
  async (professionalPayload: any, { dispatch }) =>
    updateGarmentFabric(professionalPayload.id, professionalPayload).then(
      (res) => {
        dispatch(doGetGarmentFabric());
        return res;
      }
    )
);

export const doDeleteGarmentFabric = createAsyncThunk(
  "garmentFabric/doDeleteGarmentFabric",
  async (garmentId: any, { dispatch }) => {
    deleteGarmentFabric(garmentId).then(
      (res) => {
        notificationController.error({
          message: "Fabric Deleted successfully.",
        });
        dispatch(doGetGarmentFabric());
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);

const garmentFabricSlice = createSlice({
  name: "garmentFabric",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetGarmentFabric.fulfilled, (state, action) => {
      state.garmentFabricList = action.payload.data;
    });
  },
});

export default garmentFabricSlice.reducer;
