import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addGarments,
  deleteGarment,
  getGarmentsDetailsById,
  getGarmentsList,
  updateGarments,
} from "api/garments.api";
import { notificationController } from "controllers/notificationController";
import { IGarment, GarmentRequest, GarmentSKUDetail } from "domain/Garments";

export interface GarmentSlice {
  garment_list: IGarment[] | null;
  single_garment_detail: GarmentSKUDetail | null
}

const initialState: GarmentSlice = {
  garment_list: null,
  single_garment_detail: null
};

export const doGetGarments = createAsyncThunk(
  "garment/doGetGarments",
  async () =>
    getGarmentsList().then((res: any) => {
      return res;
    })
);

export const doGetGarmentsDetailsById = createAsyncThunk(
  "garment/doGetGarmentsDetailsById",
  async (id: number | string) =>
  getGarmentsDetailsById(id).then((res: any) => {
      return res;
    })
);

export const doAddGarment = createAsyncThunk(
  "garment/doAddGarment",
  async (professionalPayload: GarmentRequest, { dispatch }) =>
    addGarments(professionalPayload).then((res) => {
      dispatch(doGetGarments());
      return res;
    })
);
export const doUpdateGarment = createAsyncThunk(
  "garment/doUpdateGarment",
  async (professionalPayload: any, { dispatch }) =>
    updateGarments(professionalPayload.id, professionalPayload).then((res) => {
      dispatch(doGetGarments());
      return res;
    })
);

export const doDeleteGarment = createAsyncThunk(
  "agreementItems/doDeleteAgreementItem",
  async (garmentId: any, { dispatch }) => {
    deleteGarment(garmentId).then(
      (res) => {
        notificationController.error({
          message: "Garment Deleted successfully.",
        });
        dispatch(doGetGarments());
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);

const garmentSlice = createSlice({
  name: "garment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetGarments.fulfilled, (state, action) => {
      state.garment_list = action.payload.data;
    });
    builder.addCase(doGetGarmentsDetailsById.fulfilled, (state, action) => {
      state.single_garment_detail = action.payload.data;
    });
  },
});

export default garmentSlice.reducer;
