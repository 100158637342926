import { httpApi } from "api/http.api";
import { notificationController } from "controllers/notificationController";
import { AgreementItem } from "domain/AgreementItem";
import {
  ProfessionalModel,
  ProfessionalRequest,
  ProfessionalResponse,
} from "domain/Professional";
import moment from "moment";

export const getProfessionalList = (): Promise<ProfessionalModel[]> =>
  httpApi.get<ProfessionalModel[]>("Professionals?show_deleted=true").then(({ data }) => data);

export const addProfessional = (
  professionalPayload: ProfessionalRequest
): Promise<ProfessionalResponse> =>
  httpApi
    .post<ProfessionalResponse>("Professionals", {
      ...professionalPayload,
    })
    .then(({ data }) => data);

export const getProfessionalById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`Professionals/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);


export const getPlanTemplateSkusList = (): Promise<any> =>
  httpApi
    .get<any>(`Professionals/GetPlanTemplateSKUs`)
    .then(({ data }) => data.data)
    .then((data) => data);

export const getPlanTemplateSkusId = (id: any): Promise<any> =>
  httpApi
    .get<any>(`Professionals/GetPlanTemplateSKUsByID/${id}`)
    .then(({ data }) => data.data)
    .then((data) => data);

export const addAssignPlanTemplateToProfessional = (
  payload: ProfessionalRequest
): Promise<ProfessionalResponse> =>
  httpApi
    .post<ProfessionalResponse>("Professionals/AssignAgreement_V3", {
      ...payload,
    })
    .then(({ data }) => data);

export const updateAssignPlanTemplateToProfessional = (
  payload: ProfessionalRequest
): Promise<ProfessionalResponse> =>
  httpApi
    .post<ProfessionalResponse>("Professionals/UpdateAssignAgreement_V2 ", {
      ...payload,
    })
    .then(({ data }) => data);

export const getProfessionalGarmentsById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`Professionals/GetProfessionalGarments_V2/${id}`)
    .then(({ data }) => data)

export const sendMobileInstruction = (id: any): Promise<any> =>
  httpApi
    .get<any>(`Professionals/${id}/ResendSMSInstructions/`)
    .then(({ data }) => data)
    .then((data) => data);

export const updateProfessional = (id: number, data: any): Promise<any> =>
  httpApi.put<any>(`Professionals/${id}`, data).then(({ data }) => data);

export const getLocations = (id: any): Promise<any> =>
  httpApi
    .get<any>(`Professionals/ClientSites`)
    .then(({ data }) => data.data)
    .then((data) => data);

export const getClientLocations = (id: any): Promise<any> =>
  httpApi
    .get<any>(`ClientsData/Sites`)
    .then(({ data }) => data.data)
    .then((data) => data);

export const getClientPlans = (): Promise<any[]> =>
  httpApi
    // .get<any>(`/Plans/GetClientPlans`)
    .get<any>(`/PlanTemplates/GetClientAgreements`)
    .then((data: any) => data)
    .then(({ data }) => data);
        

export const getClientAgreements = (): Promise<any[]> =>
  httpApi
    .get<any>(`/PlanTemplates/GetClientAgreements`)
    .then((data: any) => data)
    .then(({ data }) => data);
        

export const deactivateProfessional = async (id: number): Promise<any> => {
  try {
    let time = moment().toISOString();
    let hmacTokenResponse = await httpApi.delete<any>(
      `Professionals/${id}/DeactivateProfessional/`,
      {
        data: { hmac_time: time },
      }
    );

    let response = await httpApi.delete<any>(
      `Professionals/${id}/DeactivateProfessional/`,
      {
        data: { hmac_time: time, hmac: hmacTokenResponse?.data?.hmac },
      }
    );
    return response;
  } catch (error:any) {
    notificationController.error({
      message: error.message,
    });
  }
};

export const getProfessionLinkedAgreement = (
  id: any
): Promise<AgreementItem[]> =>
  httpApi
    .get<any>(`Professionals/GetProfessionalAgreements/${id}`)
    .then(({ data }) => data);

export const linkAgreement = (payload: any): Promise<AgreementItem[]> =>
  httpApi
    .post<any>(`Professionals/AssignAgreement`, payload)
    .then(({ data }) => data);

export const updateLinkAgreement = (payload: {
  agreement2profession_id: any;
  garments: any;
}): Promise<AgreementItem[]> =>
  httpApi
    .post<any>(`Professionals/UpdateAssignAgreement`, payload)
    .then(({ data }) => data);
export const unassignAgreement = async (
  agreementId: number,
  professionalId: any,
  agreement2professional_id: any
): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Professionals/DeactivateProfessionalFromAgreement/${agreement2professional_id}`,
    {
      data: {
        professional_id: parseInt(professionalId),
        hmac_time: time,
      },
    }
  );

  return httpApi.delete<any>(
    `Professionals/DeactivateProfessionalFromAgreement/${agreement2professional_id}`,
    {
      data: {
        professional_id: parseInt(professionalId),
        hmac_time: time,
        hmac: hmac_token?.data?.hmac,
      },
    }
  );
};

export const deactivateProfessionalFromAgreement = async (
  agreement2professional_id: any,
  professional_id: any
): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Professionals/DeactivateProfessionalFromAgreement/${agreement2professional_id}`,
    {
      data: {
        professional_id: parseInt(professional_id),
        hmac_time: time,
      },
    }
  );

  return httpApi.delete<any>(
    `Professionals/DeactivateProfessionalFromAgreement/${agreement2professional_id}`,
    {
      data: {
        professional_id: parseInt(professional_id),
        hmac_time: time,
        hmac: hmac_token?.data?.hmac,
      },
    }
  );
};
export const deactivateProfessionalPlanByGarment = async (
  garment_id: any,
  professional_id: any
): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Professionals/DeactivateProfessionalPlanByGarment/${garment_id}`,
    {
      data: {
        professional_id: parseInt(professional_id),
        hmac_time: time,
      },
    }
  );

  return httpApi.delete<any>(
    `Professionals/DeactivateProfessionalPlanByGarment/${garment_id}`,
    {
      data: {
        professional_id: parseInt(professional_id),
        hmac_time: time,
        hmac: hmac_token?.data?.hmac,
      },
    }
  );
};
