import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addGarmentWeightTypes,
  deleteGarmentWeightTypes,
  getGarmentWeightTypesList,
  updateGarmentWeightTypes,
} from "api/garmentWeightTypes.api";
import { notificationController } from "controllers/notificationController";
import {
  GarmentWeightTypes,
  GarmentWeightTypesRequest,
} from "domain/GarmentWeightTypes";

export interface GarmentWeightTypesSlice {
  garmentWeightTypesList: GarmentWeightTypes[] | null;
}

const initialState: GarmentWeightTypesSlice = {
  garmentWeightTypesList: null,
};

export const doGetGarmentWeightTypes = createAsyncThunk(
  "garmentWeightTypes/doGetGarmentWeightTypes",
  async (args: any) =>
    getGarmentWeightTypesList(args).then((res: any) => {
      return res;
    })
);

export const doAddGarmentWeightTypes = createAsyncThunk(
  "garmentWeightTypes/doAddGarment",
  async (professionalPayload: GarmentWeightTypesRequest, { dispatch }) =>
    addGarmentWeightTypes(professionalPayload).then((res) => {
      dispatch(doGetGarmentWeightTypes(false));
      return res;
    })
);
export const doUpdateGarmentWeightTypes = createAsyncThunk(
  "garmentWeightTypes/doUpdateGarment",
  async (professionalPayload: any, { dispatch }) =>
    updateGarmentWeightTypes(professionalPayload.id, professionalPayload).then(
      (res) => {
        dispatch(doGetGarmentWeightTypes(false));
        return res;
      }
    )
);

export const doDeleteGarmentWeightTypes = createAsyncThunk(
  "garmentWeightTypes/doDeleteGarmentWeightTypes",
  async (garmentId: any, { dispatch }) => {
    deleteGarmentWeightTypes(garmentId).then(
      (res) => {
        notificationController.error({
          message: "Garment Deleted successfully.",
        });
        dispatch(doGetGarmentWeightTypes(false));
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);

const garmentWeightTypesSlice = createSlice({
  name: "garmentWeightTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetGarmentWeightTypes.fulfilled, (state, action) => {
      state.garmentWeightTypesList = action.payload.data;
    });
  },
});

export default garmentWeightTypesSlice.reducer;
