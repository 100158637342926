import moment from "moment";
import { httpApi } from "api/http.api";
import jwtDecode from 'jwt-decode';
import {
  IncomingSoil,
  IncomingSoilListResponse,
  IncomingSoilRequest,
  IncomingSoilResponse,
} from "domain/IncomingSoil";
import { buildFilterString, generateFilteredColumnConditions } from "utils/utils";
import { IAPIResponsePayload } from "domain/globalTypes";

/**
 *
 * @returns IncomingSoil[]
 */

export const getAllIncomingSoilList = (): Promise<IncomingSoil[]> =>
  httpApi.get<IncomingSoil[]>("IncomingSoil").then(({ data }) => data);


export const getIncomingSoilList = async (data: any): Promise<IncomingSoilListResponse> => {
  try {
    delete data.total;
    const {
      page: current,
      pageSize,
      sort_order,
      sort_field,
      erp_user_id,
      ...rest
    } = data;

    let apiUrl = `/IncomingSoilV2/GetIncomingSoils?$count=true`;
    if (current === 1) {
      apiUrl += `&$top=${pageSize}`;
    } else {
      apiUrl += `&$top=${pageSize}&$skip=${(current - 1) * pageSize}`;
    }

    if (sort_order && sort_field) {
      const sortOrderParam = sort_order === "ascend" ? "ASC" : "DESC";
      apiUrl += `&$orderby=${sort_field} ${sortOrderParam}`;
    }

    const filterQueryString = buildFilterQueryString(data);
    if (filterQueryString) {
      apiUrl += `&${filterQueryString} and erp_user_id eq ${erp_user_id}`;
    } else {
      apiUrl += `&$filter=erp_user_id eq ${erp_user_id}`;
    }

    const response = await httpApi.get<{ value: IncomingSoil[], '@odata.count': number }>(apiUrl);
    return {
      data: response.data.value,
      total: response.data['@odata.count']
    };
  } catch (error) {
    console.error("Error fetching getIncomingSoilList:", error);
    throw error;
  }
};


// export const getIncomingSoilList = async (data: any): Promise<{ data: IncomingSoil[], total: number }> => {

//   try {
//     delete data.total
//     const { 
//       page: current,
//       pageSize,
//       sort_order,
//       sort_field,
//       erp_user_id,
//       ...rest
//       } = data
//     let apiUrl = `/IncomingSoilV2/GetIncomingSoils?$count=true`
//     if (current === 1) {
//       apiUrl += `&$top=${pageSize}` 
//     } else {
//       apiUrl += `&$top=${pageSize}&$skip=${(current - 1) * pageSize}`
//     }

//   if (sort_order && sort_field) {
//     const sortOrderParam = sort_order === "ascend" ? "ASC" : "DESC";
//     apiUrl += `&$orderby=${sort_field} ${sortOrderParam}`;
//   } 
  
//   const filterQueryString = buildFilterQueryString(data);

//   if (filterQueryString) {
//     apiUrl += `&${filterQueryString} and erp_user_id eq ${erp_user_id}`;
//   } else {
//     apiUrl+= `&$filter=erp_user_id eq ${erp_user_id}`
//   }

//     let response;
//     response = await httpApi.get<{ value: IncomingSoil[], '@odata.count': number }>(apiUrl);
//     return { data: response.data.value, total: response.data['@odata.count'] };
//   } catch (error) {
//     console.error("Error fetching getIncomingSoilList:", error);
//     throw error;
//   }
// };

const buildFilterQueryString = (data: any,) => {
  const { 
    page: current,
    pageSize,
    sort_order,
    sort_field,
    total,
    erp_user_id,
    pagination,
    ...rest
    } = data
  const filters: any[] = [];
  const filterKeys:string[] = []
  const hasRestrictedKeys = filterKeys.some(key => filters.includes(key));
  const dateRangeValues: {[key: string]: boolean} = {"created_on": true};
  const intergerValues: {[key: string]: boolean} = {"erp_user_id": true};

  const filteredColumnConditions = generateFilteredColumnConditions({
    rest,
    restrictedKeys: filterKeys,
    dateRangeValues,
    integerValues: intergerValues
  });

  const buildFilter = buildFilterString(filters, filteredColumnConditions, false, hasRestrictedKeys)
  return buildFilter;
}


export const generatePickupDocument = (): Promise<IncomingSoil[]> =>
  httpApi
    .post<IncomingSoil[]>("IncomingSoil/GeneratePickupDocuments", {})
    .then(({ data }) => data);

export const addIncomingSoil = (
  incomingSoilPayload: IncomingSoilRequest
): Promise<IncomingSoilResponse> =>
  httpApi
    .post<IncomingSoilResponse>("IncomingSoil", {
      ...incomingSoilPayload,
    })
    .then(({ data }) => data);

export const updateIncomingSoil = (
  id: any,
  incomingSoilPayload: IncomingSoilRequest
): Promise<IncomingSoilResponse> =>
  httpApi
    .put<IncomingSoilRequest>("IncomingSoil/" + id, {
      ...incomingSoilPayload,
    })
    .then(({ data }) => data);

export const getIncomingSoilById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`IncomingSoil/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const deleteIncomingSoil = async (
  id: number
): Promise<IncomingSoilResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<IncomingSoilResponse>(
    `IncomingSoil/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<IncomingSoilResponse>(`IncomingSoil/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};
