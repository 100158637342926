import React from 'react'
import { Modal, Space, Table, TableColumnsType, Typography } from 'antd';
import { isMorePages } from 'utils/utils';
import NoEntranceIcon from 'icons/svg/no-entrance-png.webp';
const { Text } = Typography
interface Props {
  rfidCorruptedData: any;
  rfidCorruptedModel: boolean;
  setRfidCorruptedModel: React.Dispatch<React.SetStateAction<boolean>>;
}
const columns: TableColumnsType<any> = [
  {
    title: "Company Name",
    dataIndex: "company_name",
    key: "company_name",
  },

  {
    title: "Site Name",
    dataIndex: "site_name",
    key: "site_name",
  },
  {
    title: "Professional Name",
    dataIndex: "professional_name",
    key: "professional_name",
  },
  {
    title: "Barcode",
    dataIndex: "barcode",
    key: "barcode",
  },
  {
    title: "Rfid",
    dataIndex: "rfid",
    key: "rfid",
  },
  {
    title: "Garment Size",
    dataIndex: "garment_size",
    key: "garment_size",
  },
  {
    title: "Pending Warehouse Return",
    dataIndex: "pending_warehouse_return",
    key: "pending_warehouse_return",
  },
  {
    title: "Pending Warehouse Return Reason",
    dataIndex: "pending_warehouse_return_reason",
    key: "pending_warehouse_return_reason",
  },
  {
    title: "Deactivation Date",
    dataIndex: "deactivation_date",
    key: "deactivation_date",
  },
  {
    title: "Deactivation Reason",
    dataIndex: "deactivation_reason",
    key: "deactivation_reason",
  },
  {
    title: "Error",
    dataIndex: "error",
    key: "error",
  },
];

const RfidCorruptedModel = ({
  rfidCorruptedModel,
  setRfidCorruptedModel,
  rfidCorruptedData,
}: Props) => {
  return (
    <Modal
      title="Corrupted RFID Tags"
      visible={rfidCorruptedModel}
      onOk={() => {
        setRfidCorruptedModel(false);
      }}
      width={1600}
      onCancel={() => {
        setRfidCorruptedModel(false);
      }}
    >
      <Typography.Title level={4} className='text-center'>
        <Space>
          <Text type="danger">No Entrance</Text>
          <img width={30} src={NoEntranceIcon} alt="img" />
        </Space>
      </Typography.Title>
      <Table
        className="redesign-table mb-10"
        columns={columns}
        dataSource={rfidCorruptedData?.data}
        pagination={isMorePages(rfidCorruptedData?.length)}
      />
      {rfidCorruptedData?.unknown_tags && (
        <div >
          <Typography.Title level={4}>
            Unrecognized RFID Tags: <Text type="danger">{rfidCorruptedData?.unknown_tags.split(',').length} items</Text>

            <ul style={{ listStyle: 'none', margin: '10px 0' }}>
              {rfidCorruptedData?.unknown_tags.split(',').map((tag: string, index: number) => (
                <li key={index}><Text type="danger">{tag}</Text></li>
              ))}
            </ul>
          </Typography.Title>
        </div>
      )}
      <Typography.Title level={4}>
        Message: <Text type="danger">{rfidCorruptedData?.message}</Text>
      </Typography.Title>
    </Modal>
  )
}

export default RfidCorruptedModel