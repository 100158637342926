import moment from "moment";
import { httpApi } from "./http.api";

export const getRoutes = (schedule_date: string): Promise<any[]> =>
  httpApi
    .get<any[]>("Routes?start_date=" + schedule_date)
    .then(({ data }) => data);

export const getWorkSchedules = (schedule_date: string): Promise<any[]> =>
  httpApi
    .get<any[]>("WorkSchedules?schedule_date=" + schedule_date)
    .then(({ data }) => data);

export const getWorkSchedulesByDate = (selected_date: string): Promise<any[]> => {
  let api = selected_date ?
    `WorkSchedules/GetWorkSchedulesByDate?selected_date=${selected_date}` :
    'WorkSchedules/GetWorkSchedulesByDate';
  return httpApi
    .get<any[]>(api)
    .then(({ data }) => data);
};

export const getWorkScheduleById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`WorkSchedules/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const addRoute = (payload: any): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Routes`, payload)
    .then((data: any) => data.data.data);
};

export const addWorkScheduleSite = (payload: any): Promise<any[]> => {
  return httpApi
    .post<any[]>(`WorkScheduleSites`, payload)
    .then((data: any) => data.data.data);
};
export const updateWorkScheduleSite = (
  id: any,
  payload: any
): Promise<any[]> => {
  delete payload?.routeSiteId;
  return httpApi
    .put<any[]>(`WorkScheduleSites/${id}`, payload)
    .then((data: any) => data.data.data);
};
export const getWorkScheduleSite = (id: any): Promise<any[]> => {
  return httpApi
    .get<any[]>(`WorkScheduleSites/${id}`)
    .then((data: any) => data.data.data);
};

export const deleteWorkScheduleSite = async (id: number): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(`WorkScheduleSites/${id}`, {
    data: { hmac_time: time },
  });

  return httpApi.delete<any>(`WorkScheduleSites/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};

export const reOrderWorkScheduleSites = (id: any, payload: any): Promise<any[]> => {
  return httpApi
    .put<any[]>(`/WorkScheduleSites/ReOrder/${id}`, payload)
    .then((data: any) => data.data.data);
};

export const deleteDriver = async (
  routeId: any,
  driverId: any
): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `WorkSchedules/${routeId}/DeleteDriverToWorkSchedule/${driverId}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<any>(
    `WorkSchedules/${routeId}/DeleteDriverToWorkSchedule/${driverId}`,
    {
      data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
    }
  );
};
export const deleteVehicleFromRoute = async (id: number): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `WorkSchedules/DeleteVehicleToWorkSchedule/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<any>(
    `WorkSchedules/DeleteVehicleToWorkSchedule/${id}`,
    {
      data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
    }
  );
};
export const deleteVehicle = async (id: number): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(`RouteSites/${id}`, {
    data: { hmac_time: time },
  });

  return httpApi.delete<any>(`RouteSites/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};
export const updateWorkSchedule = (
  routeId: any,
  payload: any
): Promise<any[]> => {
  return httpApi
    .put<any[]>(`WorkSchedules/${routeId}`, payload)
    .then((data: any) => data.data.data);
};

export const assignVehicleToWorkSchedule = (
  work_schedule_id: any,
  payload: { vehicle_id: any }
): Promise<any[]> => {
  return httpApi
    .put<any[]>(
      `WorkSchedules/AssignVehicleToWorkSchedule/${work_schedule_id}`,
      payload
    )
    .then((data: any) => data.data.data);
};

export const assignDriverToWorkSchedule = (
  work_schedule_id: any,
  payload: { driver_id: any }
): Promise<any[]> => {
  return httpApi
    .put<any[]>(
      `WorkSchedules/AssignDriverToWorkSchedule/${work_schedule_id}`,
      payload
    )
    .then((data: any) => data.data.data);
};

export const deleteWorkSchedule = async (id: number): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(`WorkSchedules/${id}`, {
    data: { hmac_time: time },
  });

  return httpApi.delete<any>(`WorkSchedules/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};

export const deleteVehicleToRoute = async (id: number): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Routes/DeleteVehicleToRoute/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<any>(`Routes/DeleteVehicleToRoute/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.data },
  });
};

export const getShipments = (
  schedule_date: string,
  week_day: number
): Promise<any[]> =>
  httpApi
    .get<any[]>(
      "ShipmentWorkScheduleSite/GetShipments?schedule_date=" +
      schedule_date?.split("T")[0] +
      "&week_day=" +
      week_day
    )
    .then(({ data }) => data);

export const publishShipment = (
  route_name: string,
  status: number
): Promise<any[]> =>
  httpApi
    .get<any[]>(
      "ShipmentWorkScheduleSite/PublishWorkScheduleByToggle?route_name=" +
      route_name +
      "&is_published=" +
      status
    )
    .then(({ data }) => data);

export const saveMapping = (data:any): Promise<any[]> =>
  httpApi
    .post<any[]>("ShipmentWorkScheduleSite/SaveMapping", data)
    .then(({ data }) => data);
