import "./ShipmentProgressBar.css";

interface IProps {
  itemScanned: number | null;
  missingItems: number | null;
}

export default function ShipmentProgressBar({
  itemScanned,
  missingItems,
}: IProps) {
  itemScanned = Number(itemScanned) || 0;
  missingItems = Number(missingItems) || 0;

  const total = itemScanned + missingItems;

  const percentage = (itemScanned / total) * 100;
  const missingItemsPercentage = (total / total) * 100 || 0; //this always return 100 and is being used to how nothing on loading state

  const showWhiteBorder =
    percentage > 98 || percentage < 2 ? "" : "2px solid white";

  return (
    <div className={`shipment-navbar`}>
      <div className="shipment-bar-title">
        <p>Item scanned</p>
        <p>
          {itemScanned}/ {total}
        </p>
      </div>
      <div className="shipment-progress-bar">
        <div
          style={{ width: `${percentage}%`, borderRight: showWhiteBorder }}
          className="scanned-item-bar"
        ></div>
        <div
          style={{ width: `${missingItemsPercentage}%` }}
          className="missing-item-bar"
        ></div>
      </div>
    </div>
  );
}
