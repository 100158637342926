import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addAssignPlanTemplateToProfessional,
  addProfessional,
  deactivateProfessionalFromAgreement,
  deactivateProfessionalPlanByGarment,
  getClientAgreements,
  getClientPlans,
  getProfessionalGarmentsById,
  getProfessionalList,
  getProfessionLinkedAgreement,
  linkAgreement,
  unassignAgreement,
  updateAssignPlanTemplateToProfessional,
  updateLinkAgreement,
  updateProfessional,
} from "api/professional.api";
import { notificationController } from "controllers/notificationController";
import { AgreementModel } from "domain/Agreement";
import { GetClientsPlanModel, ProfessionalModel, ProfessionalRequest } from "domain/Professional";

export interface ProfessionalSlice {
  professional_list: ProfessionalModel[] | null;
  professional_agreement_list: AgreementModel[];
  professional_list_loaded: boolean;
  client_agreement_list?: GetClientsPlanModel[] | null
  client_agreement_list_loaded?: boolean;
  show_settings_form: boolean
}

const initialState: ProfessionalSlice = {
  professional_list: null,
  professional_agreement_list: [],
  professional_list_loaded: false,
  client_agreement_list: [],
  client_agreement_list_loaded: false,
  show_settings_form: false
};

export const doGetProfessionals = createAsyncThunk(
  "professional/doGetProfessionals",
  async () =>
    getProfessionalList().then((res: any) => {
      //const resData = JSON.parse(res);
      return res;
    })
);
export const doGetClientAgreements = createAsyncThunk(
  "professional/doGetClientAgreements",
  async () =>
  getClientAgreements().then((res: any) => {
      return res;
    })
);

export const doGetProfessionalGarmentsById = createAsyncThunk(
  "professional/doGetProfessionalGarmentsById",
  async (id:number) =>
    getProfessionalGarmentsById(id).then((res: any) => {
      return res;
    })
);

export const doAddProfessional = createAsyncThunk(
  "professional/doAddProfessional",
  async (professionalPayload: ProfessionalRequest, { dispatch }) =>
    addProfessional(professionalPayload).then((res) => {
      dispatch(doGetProfessionals());

      return res;
    })
);

export const doAddAssignPlanTemplateToProfessional = createAsyncThunk(
  "professional/doAddAssignPlanTemplateToProfessional",
  async (professionalPayload: ProfessionalRequest, { dispatch }) =>
  addAssignPlanTemplateToProfessional(professionalPayload).then((res) => {
      return res;
    })
);

export const doUpdateAssignPlanTemplateToProfessional = createAsyncThunk(
  "professional/doUpdateAssignPlanTemplateToProfessional",
  async (professionalPayload: ProfessionalRequest, { dispatch }) =>
  updateAssignPlanTemplateToProfessional(professionalPayload).then((res) => {
      return res;
    })
);

export const doUpdateProfessional = createAsyncThunk(
  "professional/doUpdateProfessional",
  async (professionalPayload: any, { dispatch }) =>
    updateProfessional(professionalPayload.id, professionalPayload).then(
      (res) => {
        dispatch(doGetProfessionals());

        return res;
      }
    )
);

export const doGetProfessionalLinkedAgreement = createAsyncThunk(
  "professional/doGetProfessionalLinkedAgreement",
  async (id: any) =>
    getProfessionLinkedAgreement(id).then((res: any) => {
      //const resData = JSON.parse(res);
      return res;
    })
);
export const doAssignAgreement = createAsyncThunk(
  "professional/doAssignAgreement",
  async (payload: any, { dispatch }) =>
    linkAgreement(payload).then((res: any) => {
      dispatch(doGetProfessionalLinkedAgreement(payload.professional_id));
      return res;
    })
);
export const doUpdateAssignAgreement = createAsyncThunk(
  "professional/doUpdateAssignAgreement",
  async (payload: any, { dispatch }) =>
    updateLinkAgreement(payload).then((res: any) => {
      dispatch(doGetProfessionalLinkedAgreement(payload.professional_id));
      return res;
    })
);
export const doUnAssignAgreement = createAsyncThunk(
  "professional/doUnAssignAgreement",
  async (payload: any, { dispatch }) =>
    unassignAgreement(
      payload.agreement_id,
      payload.professional_id,
      payload.agreement2professional_id
    ).then((res: any) => {
      dispatch(doGetProfessionalLinkedAgreement(payload.professional_id));
      return res;
    })
);

export const doDeleteProfessionalPlanItem = createAsyncThunk(
  "professionalItems/doDeleteProfessionalPlanItem",
  async (agreementData: any, { dispatch }) => {
    const { professionalId, id } = agreementData;
  
    deactivateProfessionalFromAgreement(id, professionalId).then(
      (res) => {
        notificationController.error({
          message: "Professional Plan Item Deleted successfully.",
        });
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
      );
    
  }
);
export const doDeleteProfessionalPlanByGarment = createAsyncThunk(
  "professionalItems/doDeleteProfessionalPlanByGarment",
  async (agreementData: any, { dispatch }) => {
    const { professionalId, id } = agreementData;
    deactivateProfessionalPlanByGarment(id, professionalId).then(
      (res) => {
        notificationController.error({
          message: "Professional Plan Item Deleted successfully.",
        });
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
      );
    
  }
);

const professionalSlice = createSlice({
  name: "professional",
  initialState,
  reducers: {
    clearProfessionalAgreement: (state) => {
      state.professional_agreement_list = [];
    },
    showProfessionalSettingsForm: (state, action) => {
      state.show_settings_form = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doGetProfessionals.fulfilled, (state, action) => {
      state.professional_list_loaded = true;
      state.professional_list = action.payload.data;
    });
    builder.addCase(doGetClientAgreements.fulfilled, (state, action) => {
      state.client_agreement_list_loaded = true;
      state.client_agreement_list = action.payload.data;
    });
    builder.addCase(
      doGetProfessionalLinkedAgreement.fulfilled,
      (state, action) => {
        state.professional_agreement_list = action.payload.data;
      }
    );
  },
});

export const { clearProfessionalAgreement, showProfessionalSettingsForm } = professionalSlice.actions;

export default professionalSlice.reducer;
