import moment from "moment";
import { httpApi } from "api/http.api";
import {
  GarmentTypes,
  GarmentTypesRequest,
  GarmentTypesResponse,
} from "domain/GarmentTypes";

/**
 *
 * @returns GarmentTypes[]
 */
export const getGarmentTypesList = (
  is_fetch_all = true
): Promise<GarmentTypes[]> =>
  httpApi
    .get<GarmentTypes[]>("GarmentTypes?is_fetch_all=" + is_fetch_all)
    .then(({ data }) => data);

export const getGarmentTypes = (): Promise<GarmentTypes[]> =>
  httpApi
    .get<GarmentTypes[]>("GarmentTypes/")
    .then((data: any) => data["data"])
    .then(({ data }) => data);

export const addGarmentTypes = (
  garmentsPayload: GarmentTypesRequest
): Promise<GarmentTypesResponse> =>
  httpApi
    .post<GarmentTypesResponse>("GarmentTypes", {
      ...garmentsPayload,
    })
    .then(({ data }) => data);

export const updateGarmentTypes = (
  id: any,
  garmentTypesPayload: GarmentTypesRequest
): Promise<GarmentTypesResponse> =>
  httpApi
    .put<GarmentTypesRequest>("GarmentTypes/" + id, {
      ...garmentTypesPayload,
    })
    .then(({ data }) => data);

export const getGarmentTypesById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`GarmentTypes/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const deleteGarmentTypes = async (
  id: number
): Promise<GarmentTypesResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<GarmentTypesResponse>(
    `GarmentTypes/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<GarmentTypesResponse>(`GarmentTypes/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};
