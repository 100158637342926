import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addGarmentColor,
  deleteGarmentColor,
  getGarmentColorList,
  updateGarmentColor,
} from "api/garmentColor.api";
import { notificationController } from "controllers/notificationController";
import { GarmentColor, GarmentColorRequest } from "domain/GarmentColor";

export interface GarmentColorSlice {
  garmentColorList: GarmentColor[] | null;
}

const initialState: GarmentColorSlice = {
  garmentColorList: null,
};

export const doGetGarmentColor = createAsyncThunk(
  "garmentColor/doGetGarmentColor",
  async (args: any) =>
    getGarmentColorList(args).then((res: any) => {
      return res;
    })
);

export const doAddGarmentColor = createAsyncThunk(
  "garmentColor/doAddGarment",
  async (professionalPayload: GarmentColorRequest, { dispatch }) =>
    addGarmentColor(professionalPayload).then((res) => {
      dispatch(doGetGarmentColor(false));
      return res;
    })
);
export const doUpdateGarmentColor = createAsyncThunk(
  "garmentColor/doUpdateGarment",
  async (professionalPayload: any, { dispatch }) =>
    updateGarmentColor(professionalPayload.id, professionalPayload).then(
      (res) => {
        dispatch(doGetGarmentColor(false));
        return res;
      }
    )
);

export const doDeleteGarmentColor = createAsyncThunk(
  "garmentColor/doDeleteGarmentColor",
  async (garmentId: any, { dispatch }) => {
    deleteGarmentColor(garmentId).then(
      (res) => {
        notificationController.error({
          message: "Garment Deleted successfully.",
        });
        dispatch(doGetGarmentColor(false));
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);

const garmentColorSlice = createSlice({
  name: "garmentColor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetGarmentColor.fulfilled, (state, action) => {
      state.garmentColorList = action.payload.data;
    });
  },
});

export default garmentColorSlice.reducer;
