import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addPriceListItems,
  deletePriceListItems,
  getPriceListItemsList,
  updatePriceListItems,
} from "api/priceListItems.api";
import { notificationController } from "controllers/notificationController";
import { PriceListItems, PriceListItemsRequest } from "domain/PriceListItems";

export interface PriceListItemsSlice {
  priceListItemsList: PriceListItems[] | null;
}

const initialState: PriceListItemsSlice = {
  priceListItemsList: null,
};

export const doGetPriceListItems = createAsyncThunk(
  "priceListItems/doGetPriceListItems",
  async () =>
    getPriceListItemsList().then((res: any) => {
      return res;
    })
);

export const doAddPriceListItems = createAsyncThunk(
  "priceListItems/doAddGarment",
  async (professionalPayload: PriceListItemsRequest, { dispatch }) =>
    addPriceListItems(professionalPayload).then((res) => {
      dispatch(doGetPriceListItems());
      return res;
    })
);
export const doUpdatePriceListItems = createAsyncThunk(
  "priceListItems/doUpdateGarment",
  async (professionalPayload: any, { dispatch }) =>
    updatePriceListItems(professionalPayload.id, professionalPayload).then(
      (res) => {
        dispatch(doGetPriceListItems());
        return res;
      }
    )
);

export const doDeletePriceListItems = createAsyncThunk(
  "priceListItems/doDeletePriceListItems",
  async (garmentId: any, { dispatch }) => {
    deletePriceListItems(garmentId).then(
      (res) => {
        notificationController.error({
          message: "Price list deleted successfully.",
        });
        dispatch(doGetPriceListItems());
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);

const priceListItemsSlice = createSlice({
  name: "priceListItems",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetPriceListItems.fulfilled, (state, action) => {
      state.priceListItemsList = action.payload.data;
    });
  },
});

export default priceListItemsSlice.reducer;
