import userReducer from "store/slices/userSlice";
import authReducer from "store/slices/authSlice";
import agreementReducer from "store/slices/agreementSlice";
import professionalReducer from "store/slices/professionalSlice";
import agreementItemReducer from "store/slices/agreementItemSlice";
import onsiteLaundryReducer from "store/slices/onsiteLaundrySlice";
import garmentReducer from "store/slices/garmentSlice";
import garmentColorReducer from "store/slices/garmentColorSlice";
import orderSliceReducer from "store/slices/orderSlice";
import garmentSizeTypeReducer from "store/slices/garmentSizeTypeSlice";
import garmentWeightTypesReducer from "store/slices/garmentWeightTypesSlice";
import garmentTypeSliceReducer from "store/slices/garmentTypesSlice";
import priceListItemsReducer from "store/slices/priceListItemsSlice";
import shippingDocumentReducer from "store/slices/shippingDocumentSlice";
import shippingDocumentReducerV2 from "store/slices/shippingDocumentSliceV2";
import shippingDocumentReducerV3 from "store/slices/shippingDocumentSliceV3";
import reportReducer from "store/slices/reportSlice";
import incomingSoilReducer from "store/slices/incomingSoilSlice";
import incomingSoilReducerV2 from "store/slices/incomingSoilSliceV2";
import clientReducer from "store/slices/clientSlice";
import garmentFabricReducer from "store/slices/garmentFabricSlice";
import priceListItemTypesReducer from "store/slices/priceListItemTypesSlice";
import laundryProgramReducer from "store/slices/laundryProgramSlice";
import workScheduleReducer from "store/slices/workScheduleSlice";
import routeReducer from "store/slices/routeSlice";
import wareHouseScheduler from "store/slices/wareHouseSlice";
import wareHouseSchedulerV2 from "store/slices/wareHouseSliceV2";
import vehiclesReducer from "store/slices/vehiclesSlice";

import driversReducer from "store/slices/driversSlice";
import dashboardReducer from "store/slices/dashboardSlice";
import erpUserReducer from "store/slices/erpUserSlice";
import planTemplatesReducer from "store/slices/planTemplatesSlice"
import tabSliceReducer from "store/slices/refreshTabsSlice";
export default {
  user: userReducer,
  auth: authReducer,
  agreement: agreementReducer,
  professional: professionalReducer,
  onsiteLaundry: onsiteLaundryReducer,
  agreementItems: agreementItemReducer,
  garment: garmentReducer,
  garmentColor: garmentColorReducer,
  orders: orderSliceReducer,
  garmentSizeType: garmentSizeTypeReducer,
  garmentWeightTypes: garmentWeightTypesReducer,
  garmentTypes: garmentTypeSliceReducer,
  priceListItems: priceListItemsReducer,
  shippingDocument: shippingDocumentReducer,
  shippingDocumentV2: shippingDocumentReducerV2,
  shippingDocumentV3: shippingDocumentReducerV3,
  report: reportReducer,
  incomingSoil: incomingSoilReducer,
  incomingSoilV2: incomingSoilReducerV2,
  client: clientReducer,
  garmentFabric: garmentFabricReducer,
  priceListType: priceListItemTypesReducer,
  laundryProgram: laundryProgramReducer,
  workSchedule: workScheduleReducer,
  routes: routeReducer,
  vehicles: vehiclesReducer,
  drivers: driversReducer,
  wareHouse: wareHouseScheduler,
  wareHouseV2: wareHouseSchedulerV2,
  dashboard: dashboardReducer,
  erpUser: erpUserReducer,
  planTemplates: planTemplatesReducer,
  refreshTab: tabSliceReducer
};
