import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addOnsiteLaundry,
  deleteOnsiteLaundry,
  getOnsiteLaundryList,
  updateOnsiteLaundry,
} from "api/onsiteLaundry.api";
import { notificationController } from "controllers/notificationController";
import { AgreementModel } from "domain/Agreement";
import { OnsiteLaundry, OnsiteLaundryRequest } from "domain/OnsiteLaundry";

export interface OnsiteLaundrySlice {
  onsite_laundry_list: OnsiteLaundry[] | null;
}

const initialState: OnsiteLaundrySlice = {
  onsite_laundry_list: null,
};

export const doUpdateSelectedOnsiteLaundry = createAction(
  "onsiteLaundry/doUpdateSelectedOnsiteLaundry",
  function prepare(agreement: AgreementModel) {
    return {
      payload: {
        agreement,
      },
    };
  }
);

export const doGetOnsiteLaundry = createAsyncThunk(
  "onsiteLaundry/doGetOnsiteLaundry",
  async () =>
    getOnsiteLaundryList().then((res: any) => {
      return res;
    })
);

export const doAddOnsiteLaundry = createAsyncThunk(
  "onsiteLaundry/doAddOnsiteLaundry",
  async (professionalPayload: OnsiteLaundryRequest, { dispatch }) =>
    addOnsiteLaundry(professionalPayload).then((res) => {
      dispatch(doGetOnsiteLaundry());
      return res;
    })
);
export const doUpdateOnsiteLaundry = createAsyncThunk(
  "onsiteLaundry/doUpdateOnsiteLaundry",
  async (professionalPayload: any, { dispatch }) =>
    updateOnsiteLaundry(professionalPayload.id, professionalPayload).then(
      (res) => {
        dispatch(doGetOnsiteLaundry());
        return res;
      }
    )
);

export const doDeleteOnsiteLaundry = createAsyncThunk(
  "onsiteLaundry/doDeleteOnsiteLaundry",
  async (garmentId: any, { dispatch }) => {
    deleteOnsiteLaundry(garmentId).then(
      (res) => {
        notificationController.error({
          message: "Onsite Laundry Deleted successfully.",
        });
        dispatch(doGetOnsiteLaundry());
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);

const onsiteLaundrySlice = createSlice({
  name: "onsiteLaundry",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetOnsiteLaundry.fulfilled, (state, action) => {
      state.onsite_laundry_list = action.payload.data;
    });
  },
});

export default onsiteLaundrySlice.reducer;
