import { DatePicker } from "antd";
import { ArrowDownIcon } from "icons/Icons";
import React, { useState } from "react";
import styles from "./WorkSchedulesHeader.module.css";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { setSelectedDate } from "store/slices/workScheduleSlice";
import { WorkScheduleMonthPickerInterface } from "domain/WorkScheduele";


const WorkScheduleMonthPicker: React.FC<WorkScheduleMonthPickerInterface> = ({
  selectedMonth,
  setSelectedMonth,
  setSelectedDatePicker,
}: WorkScheduleMonthPickerInterface) => {
  const dispatch = useAppDispatch();

  const selectedDate = useAppSelector(
    (state) => state.workSchedule.selectedDate
  );

  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const handleButtonClick = () => {
    setDatePickerOpen(!datePickerOpen);
  };

  const handleDatePickerChange = (date: moment.Moment | null) => {
    const newDate = moment(date).clone().weekday(1).format("YYYY-MM-DD");
    const newSecondDatePickerDate = moment(newDate)
      .startOf("month")
      .format("YYYY-MM-DD");

    setSelectedMonth(moment(date).format("MMMM YYYY"));
    setSelectedDatePicker(newSecondDatePickerDate);
    dispatch(setSelectedDate(newDate));
    setDatePickerOpen(false);
  };

  return (
    <div className={styles.workScheduleMonth}>
      <div className={styles.workSchduleMonthHeading}>
        <span className="header-text">// {selectedMonth}</span>
        <button onClick={handleButtonClick} className={styles.downIcon}>
          <ArrowDownIcon />
        </button>
      </div>
      <DatePicker
        value={moment(selectedDate)}
        onChange={handleDatePickerChange}
        picker="month"
        bordered={false}
        suffixIcon={null}
        open={datePickerOpen}
        className={styles.customPicker}
        allowClear={false}
      />
    </div>
  );
};

export default WorkScheduleMonthPicker;
