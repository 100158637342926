import { httpApi } from "api/http.api";
import { IReportsNames } from "domain/Reports";

export const getReports = async (data: any): Promise<any[]> => {
  const payload = {...data};
  delete payload.api_route;
  try {
    const response = await httpApi.post<any>(data.api_route, payload);
    return response.data.data ?? response.data
  } catch (error) {
    console.error("Error fetching professional report:", error);
    throw error;
  }
};
export const getProfessionalReport = async (data: any): Promise<any[]> => {
  try {
    const response = await httpApi.post<any>("Reports/ProfessionalReport", data);
    return response.data.data ?? response.data
  } catch (error) {
    console.error("Error fetching professional report:", error);
    throw error;
  }
};
export const getProfessionalsByFilter = (
  client_id: any,
  site_id: any = "",
  department_id: any = "",
  status: any = "all"
): Promise<any[]> => {
  let str = `/Reports/GetProfessionals?client_id=${client_id}&is_active=${status}`;
  str = site_id ? str + `&site_id=${site_id}` : str;
  str = department_id ? str + `&department_id=${department_id}` : str;

  return httpApi.get<any[]>(str).then((data: any) => data.data.data);
};
export const getAffiliationReport = async (data: any): Promise<any[]> => {
  try {
    const response = await httpApi.post<any>("Reports/AffiliationReport", data);
    return response.data.data ?? response.data;
  } catch (error) {
    console.error("Error fetching affiliation report:", error);
    throw error;
  }
};

export const getDocumentReport = async (data: any): Promise<any[]> => {
  try {
    const response = await httpApi.post<any>("Reports/DocumentReport", data);
    return response.data.data ?? response.data;
  } catch (error) {
    console.error("Error fetching document report:", error);
    throw error;
  }
};

export const getDocumentCSVReport = (data: any): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Reports/DocumentReport`, data)
    .then((data: any) => data.data);
};
export const getEventReport = async (data: any): Promise<any[]> => {
  try {
    const response = await httpApi.post<any>("Reports/EventReport", data);
    return response.data.data ?? response.data
  } catch (error) {
    console.error("Error fetching event report:", error);
    throw error;
  }
};


export const getReportName = (): Promise<any[]> =>
  httpApi
    .get<IReportsNames[]>("Reports/GetReportNames")
    .then((data: any) => data.data.data);
