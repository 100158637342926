import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import './DropdownWithServerData.css'
import { ArrowDownIcon, SearchIcon } from "icons/Icons";

const { Option } = Select;

const DropdownWithServerData: React.FC<any> = ({
  isDisabled = false,
  apiFunction,
  defaultValue,
  filterFunction,
  existingData,
  onChange,
  placeholder,
  ...props
}) => {
  const [apiData, setAPIData] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);

  const [selectedValue, setSelectedValue] = useState<any>(null);
  useEffect(() => {
    apiFunction().then((data: any) => {
      const filterData = filterFunction(data);
      setAPIData(filterData);
      setLoading(false);
    });
  }, []);
  useEffect(() => {
    setAPIData(existingData);
  }, [existingData]);

  const customSelectStyles = {
    backgroundColor: '#fff',
    border: '1px solid #BBBBBB',
    borderRadius: '8px',
    padding: '10px 0',
  }

  const customOptionStyle = {
    color: '#fff',
    fontWeight: "bold",
    backgroundColor: '#262889',
    margin: '2px 10px',
    borderRadius: '8px',
    fontFamily: "Poppins, sans-serif",
    position:'relative'
  };

  const customOptionNonStyle = {
    color: '#333333',
    fontFamily: "Poppins, sans-serif",
    fontStyle: 'normal',
    fontWeight: 400,
    backgroundColor: '#fff',
    borderRadius: '10px',
  };
  
  const handleSelectChange = (value: any) => {
    setSelectedValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className="dropdown-with-name-wrapper">
      {(!props.mode || !selectedValue) &&
        <div className="heading">
          <SearchIcon />
        </div>
      }
      <Select loading={loading}
        disabled={isDisabled}
        allowClear
        showSearch
        placeholder={placeholder}
        defaultValue={defaultValue ? defaultValue : null}
        bordered={false}
        suffixIcon={<ArrowDownIcon/>}
        dropdownStyle={customSelectStyles}
        onChange={handleSelectChange}
        {...props}
      >
        {apiData &&
          apiData.map((record: any, index: number) => (
            <Option
              style={
                record.value === selectedValue
                  ? customOptionStyle
                  : customOptionNonStyle 
              }
              value={record.value}
              key={record.label + index}
            >
              {/* <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}> */}

                {record.label}
                {record.value === selectedValue && (
                  <CheckOutlined style={{ color: '#ffff' , position:'absolute' , right:10 , top:'28%' , background:'#262889'}} />
                )}
              {/* </div> */}
            </Option>
          ))}
      </Select>
    </div>
  );
};

export default DropdownWithServerData;
