import moment from "moment";
import { httpApi } from "api/http.api";
import { User, UserRequest, UserResponse } from "domain/User";

/**
 *
 * @returns User[]
 */
export const getUserList = (): Promise<User[]> =>
  httpApi
    .get<User[]>("Users")
    .then(({ data }: any) => data.data)
    .then((data) => data);

export const getClientUserList = (): Promise<User[]> =>
  httpApi
    .get<User[]>("Users/GetClientUsers")
    .then(({ data }: any) => data.data)
    .then((data) => data);

export const addUser = (garmentsPayload: UserRequest): Promise<UserResponse> =>
  httpApi
    .post<UserResponse>("Users/AddAccountUser", {
      ...garmentsPayload,
    })
    .then(({ data }) => data);

export const updateUser = (
  id: any,
  userPayload: UserRequest
): Promise<UserResponse> =>
  httpApi
    .put<UserRequest>(`Users/${id}/UpdateAccountUser`, {
      ...userPayload,
    })
    .then(({ data }) => data);

export const addClientUser = (garmentsPayload: UserRequest): Promise<UserResponse> =>
  httpApi
    .post<UserResponse>("Users/AddClientUser", {
      ...garmentsPayload,
    })
    .then(({ data }) => data);

export const updateClientUser = (
  id: any,
  userPayload: UserRequest
): Promise<UserResponse> =>
  httpApi
    .put<UserRequest>(`Users/${id}/UpdateClientUser`, {
      ...userPayload,
    })
    .then(({ data }) => data);

export const updatePassword = (
  id: any,
  userPayload: UserRequest
): Promise<UserResponse> =>
  httpApi
    .put<UserRequest>(`Users/${id}/ChangePassword`, {
      ...userPayload,
    })
    .then(({ data }) => data);

export const getUserById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`Users/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const activateUser = (id: any): Promise<UserResponse> =>
  httpApi
    .put<UserRequest>(`Users/${id}/ActivateUser`, {})
    .then(({ data }) => data);

export const deActivate = async (id: number): Promise<UserResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<UserResponse>(
    `Users/${id}/DeactivateUser`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<UserResponse>(`Users/${id}/DeactivateUser`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};

export const getUserProfile = (): Promise<User[]> =>
  httpApi.get<User[]>("Users/GetProfiles").then(({ data }) => data);

export const deleteUser = async (id: number): Promise<UserResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<UserResponse>(
    `Users/${id}/DeleteUser`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<UserResponse>(`Users/${id}/DeleteUser`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};
