import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addIncomingSoil,
  deleteIncomingSoil,
  getAllIncomingSoilList,
  getIncomingSoilList,
  updateIncomingSoil,
} from "api/incomingSoilV2.api";
import { notificationController } from "controllers/notificationController";
import { IAPIResponsePayload } from "domain/globalTypes";
import { IncomingSoil, IncomingSoilListResponse, IncomingSoilRequest } from "domain/IncomingSoil";

export interface IncomingSoilSlice {
  // incomingSoilList: IAPIResponsePayload<IncomingSoil[]> | null;
  incomingSoilList: IncomingSoilListResponse | null;
  barcode_scanner_enabled: boolean
}

const initialState: IncomingSoilSlice = {
  incomingSoilList: null,
  barcode_scanner_enabled: true
};

export const doGetAllIncomingSoil = createAsyncThunk(
  "incomingSoil/doGetAllIncomingSoil",
  async () =>
    getAllIncomingSoilList().then((res: any) => {
      return res;
    })
);

export const doGetIncomingSoil = createAsyncThunk(
  "incomingSoil/doGetIncomingSoil",
  async (data: any, { rejectWithValue }) => {
    try {
      delete data?.incomingSoilId;
      const res = await getIncomingSoilList(data);
      return  res;
    } catch (error:any) {
      return rejectWithValue(error.toString());
    }
  }
);

export const doAddIncomingSoil = createAsyncThunk(
  "incomingSoil/doAddGarment",
  async (incomingSoilPayload: IncomingSoilRequest, { dispatch }) =>
    addIncomingSoil(incomingSoilPayload).then((res) => {
      dispatch(doGetAllIncomingSoil());
      return res;
    })
);
export const doUpdateIncomingSoil = createAsyncThunk(
  "incomingSoil/doUpdateGarment",
  async (incomingSoilPayload: any, { dispatch }) =>
    updateIncomingSoil(incomingSoilPayload.id, incomingSoilPayload).then(
      (res) => {
        dispatch(doGetAllIncomingSoil());
        return res;
      }
    )
);

export const doDeleteIncomingSoil = createAsyncThunk(
  "incomingSoil/doDeleteIncomingSoil",
  async (data: any, { dispatch }) => {
    const { incomingSoilId } = data
    deleteIncomingSoil(incomingSoilId).then(
      (res) => {
        notificationController.error({
          message: "Incoming soil deleted successfully.",
        });
        // dispatch(doGetAllIncomingSoil());
        dispatch(doGetIncomingSoil(data));
        return res;
      },
      (error:any) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);

const incomingSoilSlice = createSlice({
  name: "incomingSoil",
  initialState,
  reducers: {
    setBarcodeScannerEnabled: (state, action: PayloadAction<boolean>) => {
      state.barcode_scanner_enabled = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doGetIncomingSoil.fulfilled, (state, action) => {
      state.incomingSoilList = action.payload;
    })
  },
});

export const { setBarcodeScannerEnabled } = incomingSoilSlice.actions;


export default incomingSoilSlice.reducer;



