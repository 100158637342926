import moment from "moment";
import { httpApi } from "api/http.api";
import {
  LaundryProgram,
  LaundryProgramRequest,
  LaundryProgramResponse,
} from "domain/LaundryProgram";

/**
 *
 * @returns LaundryProgram[]
 */
export const getLaundryProgramList = (): Promise<LaundryProgram[]> =>
  httpApi.get<LaundryProgram[]>("LaundryPrograms").then(({ data }) => data);

export const addLaundryProgram = (
  garmentsPayload: LaundryProgramRequest
): Promise<LaundryProgramResponse> =>
  httpApi
    .post<LaundryProgramResponse>("LaundryPrograms", {
      ...garmentsPayload,
    })
    .then(({ data }) => data);

export const updateLaundryProgram = (
  id: any,
  laundryProgramPayload: LaundryProgramRequest
): Promise<LaundryProgramResponse> =>
  httpApi
    .put<LaundryProgramRequest>("LaundryPrograms/" + id, {
      ...laundryProgramPayload,
    })
    .then(({ data }) => data);

export const getLaundryProgramById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`LaundryPrograms/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const deleteLaundryProgram = async (
  id: number
): Promise<LaundryProgramResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<LaundryProgramResponse>(
    `LaundryPrograms/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<LaundryProgramResponse>(`LaundryPrograms/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};
