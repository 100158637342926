import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  activateUser,
  addClientUser,
  addUser,
  deActivate,
  deleteUser,
  getClientUserList,
  getUserList,
  updateClientUser,
  updateUser,
} from "api/user.api";
import { notificationController } from "controllers/notificationController";
import { User, UserRequest } from "domain/User";

export interface UserSlice {
  erpUserList: User[] | null;
  erpClientUserList: User[] | null;
}

const initialState: UserSlice = {
  erpUserList: null,
  erpClientUserList: null,
};

export const doGetUser = createAsyncThunk("erpUser/doGetUser", async () =>
  getUserList().then((res: any) => {
    return res;
  })
);

export const doGetClientUser = createAsyncThunk("erpUser/doGetClientUser", async () =>
  getClientUserList().then((res: any) => {
    return res;
  })
);

export const doAddUser = createAsyncThunk(
  "erpUser/doAddGarment",
  async (professionalPayload: UserRequest, { dispatch }) =>
    addUser(professionalPayload).then((res) => {
      dispatch(doGetUser());
      return res;
    })
);
export const doUpdateUser = createAsyncThunk(
  "erpUser/doUpdateGarment",
  async (professionalPayload: any, { dispatch }) =>
    updateUser(professionalPayload.id, professionalPayload).then((res) => {
      dispatch(doGetUser());
      return res;
    })
);
export const doAddClientUser = createAsyncThunk(
  "erpUser/doAddClient",
  async (professionalPayload: UserRequest, { dispatch }) =>
    addClientUser(professionalPayload).then((res) => {
      dispatch(doGetClientUser());
      return res;
    })
);
export const doUpdateClientUser = createAsyncThunk(
  "erpUser/doUpdateClient",
  async (professionalPayload: any, { dispatch }) =>
    updateClientUser(professionalPayload.id, professionalPayload).then((res) => {
      dispatch(doGetClientUser());
      return res;
    })
);
export const doDeleteUser = createAsyncThunk(
  "erpUser/doDeleteUser",
  async (garmentId: any, { dispatch }) => {
    deleteUser(garmentId).then(
      (res:any) => {
        notificationController.error({
          message:  res?.data?.message_description,
        });
        dispatch(doGetUser());
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);
export const doDeleteClientUser = createAsyncThunk(
  "erpUser/doDeleteUser",
  async (garmentId: any, { dispatch }) => {
    deleteUser(garmentId).then(
      (res) => {
        notificationController.error({
          message: "User deleted successfully.",
        });
        dispatch(doGetClientUser());
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);
export const doDeActivateUser = createAsyncThunk(
  "erpUser/doDeActivateUser",
  async (garmentId: any, { dispatch }) => {
    deActivate(garmentId).then(
      (res) => {
        notificationController.error({
          message: "User deactivated successfully.",
        });
        dispatch(doGetUser());
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);
export const doDeActivateClientUser = createAsyncThunk(
  "erpUser/doDeActivateClientUser",
  async (garmentId: any, { dispatch }) => {
    deActivate(garmentId).then(
      (res) => {
        notificationController.error({
          message: "User deactivated successfully.",
        });
        dispatch(doGetClientUser());
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);
export const doActivateUser = createAsyncThunk(
  "erpUser/doActivateUser",
  async (garmentId: any, { dispatch }) => {
    activateUser(garmentId).then(
      (res) => {
        notificationController.error({
          message: "User activated successfully.",
        });
        dispatch(doGetUser());
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);
export const doActivateClientUser = createAsyncThunk(
  "erpUser/doActivateClientUser",
  async (garmentId: any, { dispatch }) => {
    activateUser(garmentId).then(
      (res) => {
        notificationController.error({
          message: "Client User activated successfully.",
        });
        dispatch(doGetClientUser());
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);

const erpUserSlice = createSlice({
  name: "erpUser",
  initialState,
  reducers: {
    clearUserList: (state) => {
      state.erpUserList = null;
    },
    clearClientUserList: (state) => {
      state.erpClientUserList = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doGetUser.fulfilled, (state, action) => {
      state.erpUserList = action.payload;
    });
    builder.addCase(doGetClientUser.fulfilled, (state, action) => {
      state.erpClientUserList = action.payload;
    });
  },
});

export const {
  clearUserList,
  clearClientUserList,
} = erpUserSlice.actions;

export default erpUserSlice.reducer;
