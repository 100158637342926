import { httpApi } from "api/http.api";
import { buildFilterQueryTwoStringFormat } from "./order.api";
import { IOrderData, IOrderData2 } from "domain/order";

export const getWareHouseOverview = (): Promise<any[]> =>
  httpApi.get<any[]>("WareHouseView/Clients").then(({ data }) => data);

export const getPendingWarehouseReturnClients = (): Promise<any[]> =>
  httpApi.get<any[]>("PendingWarehouseReturn/Clients")
  .then((data: any) => data["data"])
  .then(({ data }) => data);

export const getWareHouseByCompany = (): Promise<any[]> =>
  httpApi.get<any[]>("WareHouseView/Garments").then(({ data }) => data);

export const getOpenPendingWareHouseGarmentOrder = (data: IOrderData2): Promise<any[]> => {
  const {
    current, pageSize,
    ...rest
  } = data;
  let apiUrl = "PendingWarehouseReturnV2/GetGarments"

  if (data) {
    const filterQueryString = buildFilterQueryTwoStringFormat(rest);
    let queryParameters = [`$count=true`];

    if (typeof pageSize !== 'undefined') {
      if (current === 1) {
        queryParameters.push(`$top=${pageSize}`);
      } else {
        const skipValue = (current - 1) * pageSize;
        if (!isNaN(skipValue)) {
          queryParameters.push(`$top=${pageSize}&$skip=${skipValue}`);
        }
      }
    }

    if (filterQueryString) {
      queryParameters.push(filterQueryString);
    }

    apiUrl += `?${queryParameters.join('&')}`;

    return httpApi.get<any[]>(apiUrl)
      .then(({ data }) => data);
  }
  return Promise.resolve([]);
}
export const getPendingWareHouseAffiliationsOrder = (data: IOrderData): Promise<any[]> => {
  const {
    top,
    skip,
    pagination,
    current, pageSize,
    ...rest
  } = data;

  let apiUrl = "/PendingWarehouseReturnItemsV2/GetItems";


  if (data) {
    const filterQueryString = buildFilterQueryTwoStringFormat(rest);
    let queryParameters = [`$count=true`];

    if (filterQueryString) {
      queryParameters.push(filterQueryString);
    }

    apiUrl += `?${queryParameters.join('&')}`;

    return httpApi.get<any[]>(apiUrl)
      .then(({ data }) => data);
  }

  return Promise.resolve([]);
};

export const getInvoiceByAffiliationId = (affiliationId: any): Promise<any[]> =>
  httpApi
    .get<any[]>(`Accounting/GetInvoice/${affiliationId}`)
    .then((data: any) => data.data.data[0]);

export const getRefundByAffiliationId = (affiliationId: any): Promise<any[]> =>
  httpApi
    .get<any[]>(`Accounting/GetRefund/${affiliationId}`)
    .then((data: any) => data.data.data[0]);

export const getReceiptByAffiliationId = (affiliationId: any): Promise<any[]> =>
  httpApi
    .get<any[]>(`Accounting/GetReceipt/${affiliationId}`)
    .then((data: any) => data.data.data[0]);

export const getOpenWareHouseProfessionals = (
  companyId: any = null
): Promise<any[]> => {
  return httpApi
    .get<any[]>(`PendingWarehouseReturn/Professionals?client_id=${companyId}`)
    .then((data: any) => data);
};
export const getOpenWareHouseAllProfessionals = (
  companyId: any = 0
): Promise<any[]> => {
  return httpApi
    .get<any[]>(`PendingWarehouseReturn/Professionals?client_id=${0}`)
    .then((data: any) => data.data.data);
};
export const setInvoice = (data: any = null): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Accounting/SetInvoiceV2`, data)
    .then((data: any) => data.data.data);
};
export const setReceipt = (data: any = null): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Accounting/SetReceiptV2`, data)
    .then((data: any) => data.data.data);
};

export const setRefund = (data: any = null): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Accounting/SetRefundV2`, data)
    .then((data: any) => data.data.data);
};

export const getWareHouseByProfessional = (data: any): Promise<any[]> => {
  return httpApi
    .get<any[]>(
      `/PendingWarehouseReturn/Professionals?client_id=${data.affiliationClientId}${data.filterText}`
    )
    .then(({ data }) => data);
};

export const getAllWareHouseByProfessional = (data: any): Promise<any[]> => {
  console.log("filterText", data.filterText);
  
  return httpApi.get<any[]>(
    `PendingWarehouseReturn/GarmentsOfProfessional2?client_id=${data.client_id}&professional_id=${data.professional_id}${data.filterText}&active=${data.active}`)
    .then(({ data }) => {
      return data
    });
}

export const getAffiliationsOfGarment = (data: any): Promise<any[]> => {
  const clientId = Number(data.client_id);
  return httpApi
    .get<any[]>(
      `PendingWarehouseReturn/AffiliationsOfGarment2?client_id=${clientId}&professional_id=${data.professional_id}&garment_id=${data.garment_id}&order_id=${data.order_id}${data.filterText}`
    )
    .then(({ data }) => data);
};

export const getCompanyWareHouseData = (clientIds: any): Promise<any[]> => {
  return httpApi
    .get<any[]>(`WareHouseView/Garments/${clientIds}`)
    .then((data: any) => data?.data?.data);
};

export const postReadyForAffiliation = (data: any): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Affiliations/ChangeAffiliationWareHouseStatusToReady`, data)
    .then(({ data }) => data);
};

export const printAfflication = (data: any): Promise<any[]> => {
  return httpApi
    .post<any[]>(`WareHouseView/ExportBarcodes/`, data)
    .then(({ data }) => data);
};
