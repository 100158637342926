import { httpApi } from "api/http.api";
import { IDownloadData, RFIDReaderData, ScanItem } from "domain/Shipment";
import moment from "moment";
import { addPagination, buildApiUrl, fetchOData } from "utils/buildApiUrl";
import { downloadBlob } from "utils/utils";

export const getShippingDocument = async (data: any): Promise<any[]> => {
  const { pagination } = data;
  const { pageSize, current } = pagination;
  try {
    const apiUrl = addPagination(`ShipmentsV2/GetShipmentDocuments`, current, pageSize);
    const response = await fetchOData(apiUrl);
    return response;
  } catch (error) {
    console.error("Error fetching shipment document:", error);
    throw error;
  }
};

export const getShippingDocumentDetailsById = (id: any): Promise<any[]> =>
  httpApi
    .get<any[]>(`Shipments/${id}/GetShipmentDocumentByID`)
    .then((data: any) => data.data.data[0]);

export const shipmentDocumentDownload = async (data: IDownloadData): Promise<void> => {
  const { document_name, fileType, isClient = false } = data;

  try {
    const docType = isClient ? "ClientDocument" : "Document"
    const response = await httpApi.get<Blob>(`${docType}/Download?FileType=${fileType}&FileName=${document_name}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf',
      },
    });

    if (response.status !== 200) throw new Error('Failed to download file');
    downloadBlob(response?.data, document_name)

  } catch (error) {
    console.error('Error downloading PDF:', error);
  }
};

export const getShippingDocumentDetails = (id: any): Promise<any[]> =>
  httpApi
    .get<any[]>(`Shipments/${id}/GetShipmentDocument`)
    .then((data: any) => data.data.data[0]);

export const getWashingItems = (id: any): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/${id}/GetShipmentWashAndBulkItems`)
    .then((data: any) => data.data.data);
};
export const addWashingItem = (id: any, data: any): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Shipments/${id}/AddShipmentWashAndBulkItem/`, data)
    .then((data: any) => data.data);
};
export const newScan = (shipmentId: any, data: any): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Shipments/${shipmentId}/NewBatchScan`, { rfid_reader_id: 2 })
    .then((data: any) => data.data);
};

export const getShipmentItems = async (data: any): Promise<any[]> => {
  const apiUrl = buildApiUrl('ShipmentItemsV2/GetShipmentItems', data);
  return await fetchOData(apiUrl);
};

export const createNewItem = (
  shipmentDocumentId: any,
  data: any
): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Shipments/${shipmentDocumentId}/NewItem/`, data)
    .then((data: any) => data.data.data);
};

export const deleteShipmentItem = async (shipment_item_id: number): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Shipments/DeleteShipmentItem/${shipment_item_id}`,
    {
      data: { hmac_time: time },
    }
  );
  return httpApi.delete<any>(`Shipments/DeleteShipmentItem/${shipment_item_id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};

export const deleteShipmentItemV2 = async (data: {shipment_document_id: number, item_code: string, scan_type: number}): Promise<any> => {
  const {shipment_document_id, item_code, scan_type} = data;
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Shipments/DeleteShipmentItemV2?shipment_document_id=${shipment_document_id}&item_code=${item_code}&scan_type=${scan_type}`,
    {
      data: { hmac_time: time },
    }
  );
  return httpApi.delete<any>(`Shipments/DeleteShipmentItemV2?shipment_document_id=${shipment_document_id}&item_code=${item_code}&scan_type=${scan_type}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};

// export const deleteScan = (batchId: any): Promise<any[]> => {
//   return httpApi.delete<any[]>(`Shipments/DeleteBatch/${batchId}`).then((data: any) => data.data);

// }

export const getBatchesOfShipment = (
  shipmentDocumentId: any,
  scanType = 2
): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/${shipmentDocumentId}/GetBatchesByShipmentID`)
    .then((data: any) => data.data.data);
};
export const getBatchesOfShipmentException = (
  shipmentDocumentId: any,
  scanType = 2
): Promise<any[]> => {
  return httpApi
    .get<any[]>(
      `Shipments/${shipmentDocumentId}/GetBatchesByShipmentID?showall=true`
    )
    .then((data: any) => data.data.data);
};
export const getAllScans = async (data: any): Promise<ScanItem[]> => {
  const apiUrl = buildApiUrl('ShipmentItemsV2/GetShipmentItems', data);
  return await fetchOData(apiUrl);
};

export const deleteScan = async (batchId: number): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Shipments/DeleteBatchScan/${batchId}`,
    {
      data: { hmac_time: time },
    }
  );
  return httpApi.delete<any>(`Shipments/DeleteBatchScan/${batchId}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};

export const deleteWashItemData = async (
  itemId: number,
  shipment_id: any
): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Shipments/DeleteShipmentPriceListItem/${itemId}`,
    {
      data: { hmac_time: time, shipment_id: shipment_id },
    }
  );
  return httpApi.delete<any>(
    `Shipments/DeleteShipmentPriceListItem/${itemId}`,
    {
      data: {
        hmac_time: time,
        shipment_id: shipment_id,
        hmac: hmac_token?.data?.hmac,
      },
    }
  );
};

export const createShippingDocument = (shipmentPayload: any): Promise<any> =>
  httpApi
    .post<any>("Shipments", { ...shipmentPayload })
    .then(({ data }) => data);

export const getBatchException = (batchId: any): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/GetExceptionsByBatchID/${batchId}`)
    .then((data: any) => data.data.data);
};
export const getBatchItems = async (data: any): Promise<any[]> => {
  const apiUrl = buildApiUrl('ShipmentBatchItemsV2/GetBatchItems', data);
  return await fetchOData(apiUrl);
}


export const getRFIDScansItems = async (data: any): Promise<any[]> => {
  const { pagination } = data
  const { pageSize = 10 } = pagination
  const apiUrl = buildApiUrl('ShipmentItemsV2/GetShipmentItems',
    { ...data, pagination: { ...data.pagination, pageSize: pageSize } });
  return await fetchOData(apiUrl);
};

export const getWashAndBulkItems = (shipmentId: any): Promise<any[]> => {
  return httpApi.get<any[]>(`Shipments/${shipmentId}/WashAndBulkItems`).then(({ data }) => data);
};

export const getAttachedRFIDReader = (): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/GetAttachedRFIDReaders`)
    .then((data: any) => data.data.data);
};

export const getRFIDReader = (): Promise<any[]> => {
  return httpApi
    .get<RFIDReaderData[]>(`Shipments/GetRFIDReaders`)
    .then((data: any) => data.data.data);
};

export const detachRFID = (rfIdData: any): Promise<any[]> => {
  return httpApi
    .post<any>("Shipments/DetachRFID/", { ...rfIdData })
    .then(({ data }) => data);
};

export const getRFIDReaders = (): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/GetRFIDReaders`)
    .then((data: any) => data.data.data);
};
export const attachRFID = (rfIdData: any): Promise<any[]> => {
  return httpApi
    .post<any>("Shipments/AttachRFID/", { ...rfIdData })
    .then(({ data }) => data);
};

export const deleteShipmentDoc = async (documentId: number): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Shipments/DeleteShipment/${documentId}`,
    {
      data: { hmac_time: time },
    }
  );
  return httpApi.delete<any>(`Shipments/DeleteShipment/${documentId}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};

export const getShipmentBulkItems = (shipment_id: any): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/${shipment_id}/GetShipmentBulkItems/`)
    .then((data: any) => data.data.data);
};

export const getShipmentBulkOrders = (shipment_id: any): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/${shipment_id}/GetShipmentBulkOrders/`)
    .then((data: any) => data.data.data);
};

export const getShipmentBulkItemDropdown = (): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/GetBulkItems/`)
    .then((data: any) => data.data.data);
};
export const getOrderOfBulkAgreement = (): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/GetOrdersOfBulkAgreement/`)
    .then((data: any) => data.data.data);
};
export const addShipmentBulkItem = (
  shipment_id: any,
  payload: any
): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Shipments/${shipment_id}/AddShipmentBulkItem/`, payload)
    .then((data: any) => data.data.data);
};

export const addShipmentBulkOrder = (
  shipment_id: any,
  payload: any
): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Shipments/${shipment_id}/AddShipmentBulkOrder/`, payload)
    .then((data: any) => data.data.data);
};

export const deleteShipmentBulkItem = async (
  shipment_bulk_item_id: number
): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Shipments/DeleteShipmentBulkItem/${shipment_bulk_item_id}`,
    {
      data: { hmac_time: time },
    }
  );
  return httpApi.delete<any>(
    `Shipments/DeleteShipmentBulkItem/${shipment_bulk_item_id}`,
    {
      data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
    }
  );
};

export const deleteWashOrBulkItemsItem = async (
  shipment_bulk_item_id: any
): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Shipments/DeleteShipmentWashAndBulkItem/${shipment_bulk_item_id}`,
    {
      data: { hmac_time: time },
    }
  );
  return httpApi.delete<any>(
    `Shipments/DeleteShipmentWashAndBulkItem/${shipment_bulk_item_id}`,
    {
      data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
    }
  );
};

export const deleteShipmentBulkOrder = async (
  shipment_bulk_order_id: number
): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Shipments/DeleteShipmentBulkOrder/${shipment_bulk_order_id}`,
    {
      data: { hmac_time: time },
    }
  );
  return httpApi.delete<any>(
    `Shipments/DeleteShipmentBulkOrder/${shipment_bulk_order_id}`,
    {
      data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
    }
  );
};

export const getShipmentApprovalStatus = (shipment_id: any): Promise<any[]> => {
  return httpApi
    .put<any[]>(`Shipments/${shipment_id}/CheckShipmentApproval/`)
    .then((data: any) => data.data);
};

export const updateManagerNote = (
  shipment_id: any,
  values: any
): Promise<any[]> => {
  return httpApi
    .put<any[]>(`Shipments/${shipment_id}/Approval/`, values)
    .then((data: any) => data.data.data);
};

export const getMissingItems = async (data: any): Promise<any[]> => {
  try {
    const { shipment_id, pagination, sort_order, sort_field, ...rest } = data;
    const additionalParams = { shipment_id: shipment_id };
    delete data.shipment_id
    const { pageSize = 10 } = pagination
    const apiUrl = buildApiUrl('ShipmentMissingItemsV2/GetMissingItems',
      { ...data, pagination: { ...pagination, pageSize: pageSize } },
      additionalParams);

    const response = await fetchOData(apiUrl);
    return response;
  } catch (error) {
    console.error("Error fetching getMissingItems:", error);
    throw error;
  }
};

export const getUncompletedItems = (shipmentId: any): Promise<any[]> => {
  return httpApi
    .get<any[]>(`Shipments/${shipmentId}/UncompletedOrders`)
    .then((data: any) => data.data.data);
};
// export const sendApproval = (
//   shipment_id: any,
//   payload: {
//     manager_approved_note: string;
//   }
// ): Promise<any[]> => {
//   return httpApi
//     .post<any[]>(`Shipments/${shipment_id}/Approval/`, payload)
//     .then((data: any) => data.data.data);
// };

export const getShipmentsStatus = (): Promise<any[]> =>
  httpApi.get<any[]>("/Shipments/ShipmentStatus").then(({ data }) => data);


export const searchShipmentsDocuments = async ({
  client_id,
  status,
  creation_date,
  pagination
}: {
  client_id?: number;
  status?: number;
  creation_date?: string;
  pagination: {
    pageSize: number,
    current: number
  }
}): Promise<any[]> => {
  const { pageSize, current } = pagination;
  let url = addPagination("ShipmentsV2/GetShipmentDocuments", current, pageSize);

  const filters: string[] = [];

  if (client_id) {
    filters.push(`client_id eq ${client_id}`);
  }

  if (status) {
    filters.push(`shipment_status eq ${status}`);
  }

  if (creation_date) {
    filters.push(`created_on eq ${creation_date}`);
  }

  if (filters.length > 0) {
    url += "&$filter=" + filters.join(" and ");
  }

  const response = await fetchOData(url);
  return response;
};
