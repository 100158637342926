const SearchInputIcon = ({ color = "#343434" }) => {
  return (
    <svg
      width={23}
      height={23}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9999 21.9999L18.3816 18.3816M18.3816 18.3816C19.3271 17.4362 20.0771 16.3137 20.5888 15.0784C21.1005 13.8431 21.3639 12.519 21.3639 11.1819C21.3639 9.84482 21.1005 8.5208 20.5888 7.28547C20.0771 6.05015 19.3271 4.9277 18.3816 3.98222C17.4362 3.03674 16.3137 2.28674 15.0784 1.77505C13.8431 1.26336 12.519 1 11.1819 1C9.84482 1 8.5208 1.26336 7.28547 1.77505C6.05015 2.28674 4.9277 3.03674 3.98222 3.98222C2.07274 5.8917 1 8.48151 1 11.1819C1 13.8823 2.07274 16.4722 3.98222 18.3816C5.8917 20.2911 8.48151 21.3639 11.1819 21.3639C13.8823 21.3639 16.4722 20.2911 18.3816 18.3816Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SearchInputIcon