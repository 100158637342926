import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addClient,
  deleteClient,
  getClientDepartmentsById,
  getClientList,
  updateClient,
  updateClientDepartment,
  updateClientSite,
} from "api/client.api";
import { notificationController } from "controllers/notificationController";
import { Client, ClientRequest } from "domain/ClientInterfaces";

export interface ClientSlice {
  clientList: Client[] | null;
  isLoading: boolean;
  clientReloadState: number;
  isCreateClient?: boolean
}

const initialState: ClientSlice = {
  clientList: null,
  isLoading: true,
  clientReloadState: 0,
  isCreateClient: false
};

export const doGetClient = createAsyncThunk("client/doGetClient", async () =>
  getClientList().then((res: any) => {
    return res;
  })
);
export const doGetClientDepartmentList = createAsyncThunk("client/doGetClient", async (client_id: number) =>
  getClientDepartmentsById(client_id).then((res: any) => {
    return res;
  })
);

export const doAddClient = createAsyncThunk(
  "client/doAddGarment",
  async (professionalPayload: ClientRequest, { dispatch }) =>
    addClient(professionalPayload).then((res) => {
      dispatch(doGetClient());
      return res;
    })
);
export const doUpdateClient = createAsyncThunk(
  "client/doUpdateGarment",
  async (professionalPayload: any, { dispatch }) =>
    updateClient(professionalPayload.id, professionalPayload).then((res) => {
      dispatch(doGetClient());
      return res;
    })
);


export const doUpdateClientSite = createAsyncThunk(
  "client/doUpdateClientSite",
  async (payload: any, { dispatch }) =>
    updateClientSite(payload, payload.client_id, payload.id).then((res) => {
      return res;
    })
);

export const doUpdateClientDepartment = createAsyncThunk(
  "client/doUpdateClientDepartment",
  async (payload: any, { dispatch }) =>
    updateClientDepartment(payload, payload.client_id, payload.id).then((res) => {
     
      return res;
    })
);

export const doDeleteClient = createAsyncThunk(
  "client/doDeleteClient",
  async (garmentId: any, { dispatch }) => {
    deleteClient(garmentId).then(
      (res) => {
        notificationController.error({
          message: "Client deleted successfully.",
        });
        dispatch(doGetClient());
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);

export const updateClientReloadState = createAsyncThunk(
  "client/updateClientReloadState",
  async (reloadState: number, { dispatch }) => {
    dispatch(setClientReloadState(reloadState));
  }
);

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setClientReloadState: (state, action) => {
      state.clientReloadState = action.payload;
    },
    setIsCreateClient: (state, action) => {
      state.isCreateClient = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doGetClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(doGetClient.fulfilled, (state, action) => {
        state.clientList = action.payload.data;
        state.isLoading = false;
      })
      .addCase(doGetClient.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
export const { setClientReloadState, setIsCreateClient } = clientSlice.actions;
export default clientSlice.reducer;
