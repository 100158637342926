import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addPriceListItemTypes,
  deletePriceListItemTypes,
  getPriceListItemTypesList,
  updatePriceListItemTypes,
} from "api/priceListItemTypes.api";
import { notificationController } from "controllers/notificationController";
import {
  PriceListItemTypes,
  PriceListItemTypesRequest,
} from "domain/priceListItemTypesInterface";

export interface PriceListItemTypesSlice {
  priceListItemTypes: PriceListItemTypes[] | null;
}

const initialState: PriceListItemTypesSlice = {
  priceListItemTypes: null,
};

export const doGetPriceListItemTypes = createAsyncThunk(
  "priceListItemType/doGetPriceListItemTypes",
  async () =>
  getPriceListItemTypesList().then((res: any) => {
      return res;
    })
);

export const doAddPriceType = createAsyncThunk(
  "priceListItemType/doAddPriceType",
  async (professionalPayload: PriceListItemTypesRequest, { dispatch }) =>
  addPriceListItemTypes(professionalPayload).then((res) => {
      dispatch(doGetPriceListItemTypes());
      return res;
    })
);
export const doUpdatePriceType = createAsyncThunk(
  "priceListItemType/doUpdatePriceType",
  async (professionalPayload: any, { dispatch }) =>
   updatePriceListItemTypes(professionalPayload.id, professionalPayload).then(
      (res) => {
        dispatch(doGetPriceListItemTypes());
        return res;
      }
    )
);

export const doDeletePriceType = createAsyncThunk(
  "priceListItemType/doDeletePriceType",
  async (priceTypeId: any, { dispatch }) => {
    deletePriceListItemTypes(priceTypeId).then(
      (res) => {
        notificationController.error({
          message: "Price Type Deleted successfully.",
        });
        dispatch(doGetPriceListItemTypes());
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);

const priceListItemTypesSlice = createSlice({
  name: "priceListItemTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetPriceListItemTypes.fulfilled, (state, action) => {
      state.priceListItemTypes = action.payload.data;
    });
  },
});

export default priceListItemTypesSlice.reducer;
