import { Content, Footer } from "antd/lib/layout/layout";
import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import LayoutHeader from "../LayoutHeader";
import SiderMenu from "../Sidebar";

const MainLayout: React.FC = () => {
  const { pathname } = useLocation()

  return (
    <div className="flex-row">
      <SiderMenu setCollapsed={() => { }}></SiderMenu>
      <div className="site-content">
        <LayoutHeader />
        <div className="content-wrapper">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
