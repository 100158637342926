import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function AutoCompleteOff() {
  let location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps

  useEffect(() => {
    setTimeout(() => {
      let form = document.getElementsByTagName("form") as any;
      let input = document.getElementsByTagName("input") as any;
      for (var i = 0; i < form.length; i++) {
        form[i].setAttribute("autocomplete", "off");
      }
      for (var i = 0; i < input.length; i++) {
        input[i].setAttribute("autocomplete", "off");
      }
    }, 3000);
  }, [location]);

  return null;
}
