import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addVehicles,
  deleteVehicles,
  getVehiclesList,
  updateVehicles,
} from "api/vehicles.api";
import { notificationController } from "controllers/notificationController";
import { Vehicles, VehiclesRequest } from "domain/Vehicles";

export interface VehiclesSlice {
  vehiclesList: Vehicles[] | null;
}

const initialState: VehiclesSlice = {
  vehiclesList: null,
};

export const doGetVehicles = createAsyncThunk(
  "vehicles/doGetVehicles",
  async () =>
    getVehiclesList().then((res: any) => {
      return res;
    })
);

export const doAddVehicles = createAsyncThunk(
  "vehicles/doAddGarment",
  async (professionalPayload: VehiclesRequest, { dispatch }) =>
    addVehicles(professionalPayload).then((res) => {
      dispatch(doGetVehicles());
      return res;
    })
);
export const doUpdateVehicles = createAsyncThunk(
  "vehicles/doUpdateGarment",
  async (professionalPayload: any, { dispatch }) =>
    updateVehicles(professionalPayload.id, professionalPayload).then((res) => {
      dispatch(doGetVehicles());
      return res;
    })
);

export const doDeleteVehicles = createAsyncThunk(
  "vehicles/doDeleteVehicles",
  async (garmentId: any, { dispatch }) => {
    deleteVehicles(garmentId).then(
      (res) => {
        notificationController.error({
          message: "Vehicle Deleted successfully.",
        });
        dispatch(doGetVehicles());
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);

const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetVehicles.fulfilled, (state, action) => {
      state.vehiclesList = action.payload.data;
    });
  },
});

export default vehiclesSlice.reducer;
