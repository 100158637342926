import { httpApi } from "api/http.api";
import {
  PlanTemplatesModel,
  PlanTemplatesRequest,
  PlanTemplatesResponse,
} from "domain/PlanTemplates";
import moment from "moment";

export const getPlanTemplatesList = (): Promise<PlanTemplatesModel[]> =>
  httpApi
    .get<any[]>("PlanTemplates")
    .then((data: any) => data["data"])
    .then(({ data }) => data);

export const addPlanTemplate = (
  payload: PlanTemplatesRequest
): Promise<PlanTemplatesResponse> =>
  httpApi
    .post<PlanTemplatesResponse>("PlanTemplates/", {
      ...payload,
    })
    .then(({ data }) => data);

export const editPlanTemplate = (
  id: string,
  payload: PlanTemplatesRequest
): Promise<PlanTemplatesResponse> =>
  httpApi
    .put<PlanTemplatesResponse>("PlanTemplates/" + id, {
      ...payload,
    })
    .then(({ data }) => data);

export const getPlanTemplateById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`PlanTemplates/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const deletePlanTemplate = async (
  plan_template_id: number,
): Promise<PlanTemplatesResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<PlanTemplatesResponse>(
    `PlanTemplates/${plan_template_id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<PlanTemplatesResponse>(
    `PlanTemplates/${plan_template_id}`,
    {
      data: {
        hmac_time: time,
        hmac: hmac_token?.data?.hmac,
      },
    }
  );
};