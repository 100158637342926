import axios from "axios";
import { readToken } from "../services/localStorage.service";
import { ApiError } from "./ApiError";
import { ApiErrorData, baseURL } from "./http.api";

export const httpLoginApi = axios.create({
  baseURL: baseURL,
});

httpLoginApi.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    // Authorization: `Bearer ${readToken()}`,
  };
  return config;
});

httpLoginApi.interceptors.response.use(undefined, (error: any) => {
  const response = error.response;
  const messageDescription = response?.data?.message_description || response?.data?.message || error.message;

  throw new ApiError<ApiErrorData>(messageDescription);
});
