import { httpApi } from "api/http.api";
import { IOrderData, IOrderData2 } from "domain/order";

export const getOrderOverview = (): Promise<any[]> =>
  httpApi.get<any[]>("OrderView/Clients").then(({ data }) => data);

export const getOrderByCompany = (): Promise<any[]> =>
  httpApi.get<any[]>("OrderView/Garments").then(({ data }) => data);

export const getOpenOrderProfessionals = (data: any = null): Promise<any[]> => {
  return httpApi.get<any[]>(`OrderView/OpenOrderProfessionals/${data}`).then((data: any) => data.data.data);
}

export const getOpenGarmentOrderByProfessional = (data: IOrderData2): Promise<any[]> => {
  const {
    current, pageSize,
    ...rest
  } = data;
  let apiUrl = "OrderViewV2/OpenOrderGarments"

  if (data) {
    const filterQueryString = buildFilterQueryTwoStringFormat(rest);
    let queryParameters = [`$count=true`];

    if (typeof pageSize !== 'undefined') {
      if (current === 1) {
        queryParameters.push(`$top=${pageSize}`);
      } else {
        const skipValue = (current - 1) * pageSize;
        if (!isNaN(skipValue)) {
          queryParameters.push(`$top=${pageSize}&$skip=${skipValue}`);
        }
      }
    }

    if (filterQueryString) {
      queryParameters.push(filterQueryString);
    }

    apiUrl += `?${queryParameters.join('&')}`;

    return httpApi.get<any[]>(apiUrl)
      .then(({ data }) => data);
  }
  return Promise.resolve([]);
}


export const getOrderByProfessional = (data: any): Promise<any[]> => {
  return httpApi.get<any[]>(`OrderView/GarmentsOfProfessional2/${data.client_id}/${data.professional_id}`).then(({ data }) => data);
}

export const getAffiliationsOfGarment = (data: any): Promise<any[]> => {
  return httpApi.get<any[]>(`/OrderView/AffiliationsOfGarment2/${data.client_id}/${data.professional_id}/${data.garment_id}/${data.order_id}`).then(({ data }) => data);
}

export const getAffiliationsOrderOfGarmentItems = (data: IOrderData): Promise<any[]> => {
  const {
    top,
    skip,
    pagination,
    current, pageSize,
    ...rest
  } = data;

  let apiUrl = "/OpenOrderItemsViewV2/OpenOrderItemsByGarment";


  if (data) {
    const filterQueryString = buildFilterQueryTwoStringFormat(rest);
    let queryParameters = [`$count=true`];

    if (filterQueryString) {
      queryParameters.push(filterQueryString);
    }

    apiUrl += `?${queryParameters.join('&')}`;

    return httpApi.get<any[]>(apiUrl)
      .then(({ data }) => data);
  }

  return Promise.resolve([]);
};

export const getCompanyOrderData = (clientIds: any): Promise<any[]> => {
  return httpApi.get<any[]>(`OrderView/Garments/${clientIds}`).then((data: any) => data?.data?.data);
}

export const postReadyForAffiliation = (data: any): Promise<any[]> => {
  return httpApi.post<any[]>(`Affiliations/ChangeAffiliationOrderStatusToReady`, data).then(({ data }) => data);
}

export const postReadyForAffiliationV2 = (data: any): Promise<any[]> => {
  return httpApi.post<any[]>(`Affiliations/ChangeAffiliationOrderStatusToReadyV2`, data).then(({ data }) => data);
}


export const printAfflication = (data: any): Promise<any[]> => {
  return httpApi.post<any[]>(`OrderView/ExportBarcodes2/`, data).then(({ data }) => data);
}

export const buildFilterQueryTwoStringFormat = (data: any) => {
  const filters = Object.entries(data)
    .filter(([key, value]) => value !== null && value !== undefined && !key.endsWith("_isComplete"))
    .map(([key, value]: any) => {
      if (key === "from_date" || key === "to_date") {
        value = value.toISOString();
      }
      if (key === "professional_name") {
        const isComplete = data["professional_name_isComplete"] === true;
        return isComplete ? `${key} eq '${encodeURIComponent(value)}'` : `contains(${key}, '${value}')`;
      }
      return `${key} eq ${encodeURIComponent(value)}`;
    });

  if (filters.length > 0) {
    return "$filter=" + filters.join(" and ");
  } else {
    return "";
  }
};