import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  addAgreementItem,
  deleteAgreementItem,
  getAgreementItemList,
  updateAgreementItem,
} from "api/agreementItem.api";
import { notificationController } from "controllers/notificationController";
import { AgreementModel } from "domain/Agreement";
import { AgreementItem, AgreementItemRequest } from "domain/AgreementItem";

export interface AgreementItemSlice {
  selected_agreement: AgreementModel | null;
  agreement_item_list: AgreementItem[] | null;
}

const initialState: AgreementItemSlice = {
  selected_agreement: null,
  agreement_item_list: null,
};

export const doUpdateSelectedAgreementItem = createAction(
  "agreementItems/doUpdateSelectedAgreement",
  function prepare(agreement: AgreementModel) {
    return {
      payload: {
        agreement,
      },
    };
  }
);

export const doGetAgreementItem = createAsyncThunk(
  "agreementItems/doGetAgreementItems",
  async (id: any) =>
    getAgreementItemList(id).then((res: any) => {
      //const resData = JSON.parse(res);
      return res;
    })
);

export const doAddAgreementItem = createAsyncThunk(
  "agreementItems/doAddAgreement",
  async (agreementPayload: AgreementItemRequest, { dispatch }) =>
    addAgreementItem(agreementPayload).then((res) => {
      dispatch(doGetAgreementItem(agreementPayload.agreement_id));

      return res;
    })
);

export const doUpdateAgreementItem = createAsyncThunk(
  "agreementItems/doUpdateAgreementItem",
  async (agreementPayload: AgreementItemRequest, { dispatch }) =>
    updateAgreementItem(agreementPayload.id, agreementPayload).then((res) => {
      dispatch(doGetAgreementItem(agreementPayload.agreement_id));
      return res;
    })
);

export const doDeleteAgreementItem = createAsyncThunk(
  "agreementItems/doDeleteAgreementItem",
  async (agreementData: any, { dispatch }) => {
    const { agreementId, id } = agreementData;
  
      deleteAgreementItem(id).then(
        (res) => {
          dispatch(doGetAgreementItem(agreementId));
          return res;
        },
        (error) => {
          notificationController.error({
            message: error.toString(),
          });
        }
      );
    
  }
);

const agreementItemSlice = createSlice({
  name: "agreementItems",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetAgreementItem.fulfilled, (state, action) => {
      //action.payload.substring(1, action.payload.length-1);
      state.agreement_item_list = action.payload.data;
    });
    builder.addCase(doUpdateSelectedAgreementItem, (state, action) => {
      state.selected_agreement = action.payload.agreement;
      //action.payload.substring(1, action.payload.length-1);
      alert(33);
      // state.agreement_list = JSON.parse(
      //   action.payload.substring(1, action.payload.length - 1)
      // );
    });
    // builder.addCase(addAgreement.fulfilled, (state, action) => {});
  },
});

export default agreementItemSlice.reducer;
