import moment from "moment";
import { httpApi } from "api/http.api";
import {
  GarmentWeightTypes,
  GarmentWeightTypesRequest,
  GarmentWeightTypesResponse,
} from "domain/GarmentWeightTypes";

/**
 *
 * @returns GarmentWeightTypes[]
 */
export const getGarmentWeightTypesList = (
  is_fetch_all = true
): Promise<GarmentWeightTypes[]> =>
  httpApi
    .get<GarmentWeightTypes[]>(
      "GarmentWeightTypes?is_fetch_all=" + is_fetch_all
    )
    .then(({ data }) => data);

export const addGarmentWeightTypes = (
  garmentsPayload: GarmentWeightTypesRequest
): Promise<GarmentWeightTypesResponse> =>
  httpApi
    .post<GarmentWeightTypesResponse>("GarmentWeightTypes", {
      ...garmentsPayload,
    })
    .then(({ data }) => data);

export const updateGarmentWeightTypes = (
  id: any,
  garmentWeightTypesPayload: GarmentWeightTypesRequest
): Promise<GarmentWeightTypesResponse> =>
  httpApi
    .put<GarmentWeightTypesRequest>("GarmentWeightTypes/" + id, {
      ...garmentWeightTypesPayload,
    })
    .then(({ data }) => data);

export const getGarmentWeightTypesById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`GarmentWeightTypes/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const deleteGarmentWeightTypes = async (
  id: number
): Promise<GarmentWeightTypesResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<GarmentWeightTypesResponse>(
    `GarmentWeightTypes/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<GarmentWeightTypesResponse>(
    `GarmentWeightTypes/${id}`,
    {
      data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
    }
  );
};
