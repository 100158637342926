import { Col, Form, Input, Modal, Row } from "antd";
import React, { useState } from "react";
import { useAppDispatch } from "hooks/reduxHooks";
import { notificationController } from "controllers/notificationController";
import { doAddClient, setIsCreateClient, updateClientReloadState } from "store/slices/clientSlice";
import { useNavigate } from "react-router-dom";
import { Loading } from "components/common/Loading";
import TextArea from "antd/lib/input/TextArea";
import { doSetClient } from "store/slices/authSlice";

export const AddClientModal: React.FC = () => {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const validateMessages = {
        required: "${label} is required!",
    };

  const resetFields = () => {
      form.resetFields();
  };

  const handleSubmit = (values: any) => {
    setConfirmLoading(true);
    addGarment(values);
  };

  const addGarment = (values: any) => {
    dispatch(doAddClient(values))
      .unwrap()
      .then((res: any) => {
        localStorage.setItem("client_Id", res?.data[0].id)
        dispatch(doSetClient({ client_id: res?.data[0].id }))
        setConfirmLoading(false);
        notificationController.success({
          message: "Client Added successfully.",
        });
        dispatch(setIsCreateClient(false))
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setConfirmLoading(false);
        dispatch(setIsCreateClient(false))
      });
  };

    const handleCancel = () => {
        resetFields();
        dispatch(setIsCreateClient(false))
        dispatch(updateClientReloadState(Math.random()));
    };

    return (
        <>
            <Modal
                title="Add New Client"
                visible={true}
                onOk={() => form.submit()}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                {dataLoading ? (<Loading />) : (
                    <Form
                        style={{ padding: "0" }}
                        form={form}
                        layout="vertical"
                        className="form-data"
                        onFinish={handleSubmit}
                        validateMessages={validateMessages}
                    >
                       <Row>
                        <Col span={24}>
                          <Form.Item
                            label="Name"
                            name="company_name"
                            rules={[{ required: true }]}
                          >
                            <Input placeholder="Enter Name" name="company_name" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item
                            label="Remarks"
                            name="remarks"
                            rules={[{ required: false }]}
                          >
                            <TextArea placeholder="Remarks" name="remarks" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                )}
            </Modal>

        </>
    );
};



// const showModal = () => {
//   Modal.info({
//     title: isAddClient ? "Add New Client" : "Edit Client",
//     visible: isNewClient,
//     content: (
//       <Form
//         form={form}
//         style={{ padding: '38px 0 20px' }}
//         layout="vertical"
//         className="form-data"
//         onFinish={handleSubmit}
//         requiredMark={false}
//         validateMessages={validateMessages}
//       >
//         {isDataLoading ? <Skeleton active /> :
//           <>
//             <Row>
//               <Form.Item
//                 label="Name"
//                 name="company_name"
//                 rules={[{ required: true }]}
//               >
//                 <Input placeholder="Enter Name" name="company_name" />
//               </Form.Item>
//             </Row>
//             <Row>
//               <Form.Item
//                 label="Remarks"
//                 name="remarks"
//                 rules={[{ required: false }]}
//               >
//                 <TextArea placeholder="Remarks" name="remarks" />
//               </Form.Item>
//             </Row>
//           </>}
//       </Form>
//     ),
//     onOk() {
//       form.submit();
//     },
//     onCancel() {
//       form.resetFields();
//       setIsCreateClient(false)
//     },
//     okText: "Save Client",
//     cancelText: "Cancel",
//     closable: true,
//     closeIcon: <Button type="text" style={{color: "black"}}><CloseIcon fill="black" /></Button>
//   });
// };