import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addRoute,
  addRouteSite,
  assignDriverToRoute,
  assignVehicleToRoute,
  deleteNewRoute,
  deleteRoute,
  getNewRoutes,
  getRouteByClientId,
  getRouteByClientAndSiteId,
  getRoutes,
  updateRoute,
  updateRouteSite,
} from "api/routes.api";
import { notificationController } from "controllers/notificationController";
import { AgreementModel } from "domain/Agreement";
import { TRouteData } from "domain/Route";

export interface WorkScheduleSlice {
  routes: any[] | null;
  newRouteList: TRouteData[] | null
  newRouteById: any[] | null;
  newRouteByIdAndSite:any[] | null
}

const initialState: WorkScheduleSlice = {
  routes: null,
  newRouteList: null,
  newRouteById: null,
  newRouteByIdAndSite: null
};

// export const doUpdateSelectedWorkSchedule = createAction(
//   "routes/doUpdateSelectedWorkSchedule",
//   function prepare(agreement: AgreementModel) {
//     return {
//       payload: {
//         agreement,
//       },
//     };
//   }
// );

export const doGetRoute = createAsyncThunk("routes/doGetRoute", async () =>
  getRoutes().then((res: any) => {
    return res;
  })
);
export const doGetNewRoute = createAsyncThunk("routes/doGetNewRoute", async () =>
  getNewRoutes().then((res: any) => {
    return res;
  })
);

export const doGetRouteById = createAsyncThunk("routes/doGetRouteById", async (client_id:any, { dispatch }) =>
getRouteByClientId(client_id).then((res: any) => {
     dispatch(setRouteById(res)) 
  })
);

export const doGetRouteByIdandSite = createAsyncThunk("routes/doGetRouteByIdandSite", async ({ client_id, site_id }:any, { dispatch }) =>
  getRouteByClientAndSiteId(client_id, site_id).then((res: any) => {
    dispatch(setRouteByIdAndSite(res));
  })
);



export const doDeleteNewRoute = createAsyncThunk(
  "routes/doDeleteNewRoute",
  async (route_id: string, { dispatch }) => {
    deleteNewRoute(route_id).then(
      (res) => {
        notificationController.error({
          message: "Route deleted successfully.",
        });
        dispatch(doGetNewRoute())
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);

export const doAssignVehicleToRoute = createAsyncThunk(
  "routes/doAssignVehicleToRoute",
  async (professionalPayload: any, { dispatch }) =>
    assignVehicleToRoute(professionalPayload.routeId, {
      vehicle_id: professionalPayload.vehicle_id,
    }).then((res) => {
      return res;
    })
);
export const doAssignDriverToRoute = createAsyncThunk(
  "routes/doAssignDriverToRoute",
  async (professionalPayload: any, { dispatch }) =>
    assignDriverToRoute(professionalPayload.routeId, {
      driver_id: professionalPayload.driver_id,
    }).then((res) => {
      return res;
    })
);
export const doAddSiteToRoute = createAsyncThunk(
  "routes/doAddSiteToRoute",
  async (professionalPayload: any, { dispatch }) =>
    addRouteSite(professionalPayload).then((res) => {
      return res;
    })
);
export const doUpdateRouteSite = createAsyncThunk(
  "routes/doUpdateRouteSite",
  async (sitePayload: any, { dispatch }) =>
    updateRouteSite(sitePayload.routeSiteId, sitePayload).then((res) => {
      return res;
    })
);

export const doAddRoute = createAsyncThunk(
  "routes/doAddRoute",
  async (professionalPayload: any, { dispatch }) =>
    addRoute(professionalPayload).then((res) => {
      return res;
    })
);
export const doUpdateRoute = createAsyncThunk(
  "routes/doUpdateRoute",
  async (professionalPayload: any, { dispatch }) =>
    updateRoute(professionalPayload.id, professionalPayload).then((res) => {
      return res;
    })
);

export const doDeleteRoute = createAsyncThunk(
  "routes/doDeleteRoute",
  async (routeId: any, { dispatch }) => {
    deleteRoute(routeId).then(
      (res) => {
        notificationController.error({
          message: "Route deleted successfully.",
        });

        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);

const routesSlice = createSlice({
  name: "routeSlice",
  initialState,
  reducers: {
    setRouteById:(state,action)=>{
      state.newRouteById=action.payload
    },
    setRouteByIdAndSite:(state,action)=>{
      state.newRouteByIdAndSite=action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(doGetRoute.fulfilled, (state, action) => {
      let parsedRouteData = [];
      if (action.payload.data) {
        for (let index = 0; index < 7; index++) {
          const element = action.payload.data[index];
          if (element) {
            const clientSitesDat = element.routes
              ? JSON.parse(element.routes)
              : [];
            parsedRouteData.push({ ...element, routes: clientSitesDat });
          } else {
            parsedRouteData.push({ route_week_day: index + 1, routes: [] });
          }
        }
      }
      state.routes = parsedRouteData;
    });
    builder.addCase(doGetNewRoute.fulfilled, (state, action) => {
      
      state.newRouteList = action.payload.data;
    });
    // builder.addCase(doGetRouteById.fulfilled, (state, action) => {
    //   state.newRouteById = action.payload?.data;
    // });
  },
});
export const {setRouteById,setRouteByIdAndSite}=routesSlice.actions
export default routesSlice.reducer;
