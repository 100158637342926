import moment from "moment";
import { httpApi } from "api/http.api";
import {
  IncomingSoil,
  IncomingSoilRequest,
  IncomingSoilResponse,
} from "domain/IncomingSoil";

/**
 *
 * @returns IncomingSoil[]
 */
export const getIncomingSoilList = (): Promise<IncomingSoil[]> =>
  httpApi.get<IncomingSoil[]>("IncomingSoil").then(({ data }) => data);

export const generatePickupDocument = (): Promise<IncomingSoil[]> =>
  httpApi
    .post<IncomingSoil[]>("IncomingSoil/GeneratePickupDocuments", {})
    .then(({ data }) => data);

export const addIncomingSoil = (
  incomingSoilPayload: IncomingSoilRequest
): Promise<IncomingSoilResponse> =>
  httpApi
    .post<IncomingSoilResponse>("IncomingSoil", {
      ...incomingSoilPayload,
    })
    .then(({ data }) => data);

export const updateIncomingSoil = (
  id: any,
  incomingSoilPayload: IncomingSoilRequest
): Promise<IncomingSoilResponse> =>
  httpApi
    .put<IncomingSoilRequest>("IncomingSoil/" + id, {
      ...incomingSoilPayload,
    })
    .then(({ data }) => data);

export const getIncomingSoilById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`IncomingSoil/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const deleteIncomingSoil = async (
  id: number
): Promise<IncomingSoilResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<IncomingSoilResponse>(
    `IncomingSoil/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<IncomingSoilResponse>(`IncomingSoil/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};
