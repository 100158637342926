import React from 'react'

const BackIcon = () => {
  return (
    <svg
      width={5}
      height={8}
      viewBox="0 0 5 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.84204 7.40764L1.15783 4.3879L3.84204 1.36816"
        stroke="black"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BackIcon