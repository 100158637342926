import { DatePicker } from "antd";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { ArrowLeftIcon, ArrowRightIcon } from "icons/Icons";
import moment from "moment";
import React from "react";
import { setSelectedDate, setSelectedMomentDate, setWorkScheduleSiteOpen } from "store/slices/workScheduleSlice";
import styles from "./WorkSchedulesHeader.module.css";
import { WorkScheduleDatePickerInterface } from "domain/WorkScheduele";
import { notificationController } from "controllers/notificationController";

const MAX_WEEKS_FROM_TODAY = 4;
const maxDate = moment().add(MAX_WEEKS_FROM_TODAY, "weeks");
// const maxDisableDate = moment().add(MAX_WEEKS_FROM_TODAY + 1, "weeks");
const minDate = moment().subtract(8, "weeks");

const WorkScheduleDatePicker: React.FC<WorkScheduleDatePickerInterface> = ({
  setSelectedMonth,
  selectedDatePicker,
  setSelectedDatePicker,
}: WorkScheduleDatePickerInterface) => {
  const dispatch = useAppDispatch();

  const selectedDate = useAppSelector(
    (state) => state.workSchedule.selectedDate
  );
  const selectedMomentDate = useAppSelector(
    (state) => state.workSchedule.selectedMomentDate
  );

  const updateDate = (offset: number) => {
    const newDate = moment(selectedDate)
      .add(offset, "weeks")
      .format("YYYY-MM-DD");
    dispatch(setSelectedDate(newDate));
    dispatch(setSelectedMomentDate(moment(selectedMomentDate).add(offset, "weeks")));
    setSelectedMonth(moment(newDate).format("MMMM YYYY"));
    setSelectedDatePicker(newDate);
  };

  const navigateToPrevWeek = () => {
    const prevWeek = moment(selectedDate).subtract(1, "weeks");
    if (prevWeek.isSameOrAfter(minDate)) {
      updateDate(-1);
    }
    dispatch(setWorkScheduleSiteOpen(true))
  };

  const navigateToNextWeek = () => {
    const nextWeek = moment(selectedDate).add(1, "weeks");
    if (nextWeek.isSameOrBefore(maxDate)) updateDate(1);
    if (nextWeek.isAfter(maxDate)) {
      notificationController.error({ message: "Work Schedule is limited to 4 weeks ahead" });
    }
    dispatch(setWorkScheduleSiteOpen(true))
  };

  const handleChangeDatePicker = (date: moment.Moment | null) => {
    const newSelectedDate = moment(date).clone().weekday(1)
    const monday = moment(date).clone().weekday(1);
    // const newSelectedDate = moment(date)
    //   .clone()
    //   .weekday(1)
    //   .format("YYYY-MM-DD");
    setSelectedMonth(moment(date).format("MMMM YYYY"));
    setSelectedDatePicker(newSelectedDate);
    dispatch(setSelectedDate(newSelectedDate));
    dispatch(setSelectedMomentDate(monday));
  }

  return (
    <div className={styles.dateScroller}>
      <button onClick={navigateToPrevWeek} className={styles.leftIcon}>
        <ArrowLeftIcon />
      </button>
      <DatePicker
        format="YYYY-MM-DD"
        allowClear={false}
        // value={moment(selectedDatePicker)}
        value={moment(selectedMomentDate)}
        onChange={handleChangeDatePicker}
        picker="week"
        bordered={false}
        suffixIcon={null}
        disabledDate={(current: any) =>
          current.isBefore(moment().subtract(8, "weeks")) ||
          current.isAfter(maxDate)
        }
      />
      <button
        onClick={navigateToNextWeek}
        // disabled={moment(selectedDatePicker)
        //   .add(1, "weeks")
        //   .isSame(maxDisableDate)}
        className={styles.rightIcon}
      >
        <ArrowRightIcon />
      </button>
    </div>
  );
};

export default WorkScheduleDatePicker;
