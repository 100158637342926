import moment from "moment";
import { httpApi } from "./http.api";

export const getRoutes = (): Promise<any[]> =>
  httpApi
    .get<any[]>("Routes")
    .then(({ data }) => data);
    
export const getCustomers = (): Promise<any[]> =>
  httpApi
    .get<any>("Clients")
    .then(({ data }) => data.data);

export const addNewRoutes = (payload: any): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Routes/AddRoute`, payload)
    .then((data: any) => data.data.data);
};
export const updateNewRoute = (id: any, payload: any): Promise<any[]> => {
  return httpApi
    .put<any[]>(`Routes/UpdateRoute/${id}`, payload)
    .then((data: any) => data.data.data);
};
export const getNewRoutes = (): Promise<any[]> =>
  httpApi
    .get<any[]>("Routes/GetRoutes")
    .then(({ data }) => data);

export const getSites = (id: string): Promise<any[]> =>
  httpApi
    .get<any>(`Clients/GetSites?client_id=${id}&order_by=site_name`)
    .then(({ data }) => data.data);

export const getNewRouteById = (id: string): Promise<any[]> =>
  httpApi
    .get<any>(`Routes/GetRoutesByID/${id}`)
    .then(({ data }) => data.data);

export const getRouteByClientId = (id: string): Promise<any[]> =>
  httpApi
    .get<any>(`Routes/GetRoutes?client_id=${id}`)
    .then(({ data }) => data.data);

export const getRouteByClientAndSiteId = (id: string,site_id: string): Promise<any[]> =>
  httpApi
    .get<any>(`Routes/GetRoutes?client_id=${id}&site_id=${site_id}`)
    .then(({ data }) => data.data);

export const deleteNewRoute = async (id: string): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(`Routes/DeleteRoute/${id}`, {
    data: { hmac_time: time },
  });

  return httpApi.delete<any>(`Routes/DeleteRoute/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};

export const getWorkSchedules = (schedule_date: string): Promise<any[]> =>
  httpApi
    .get<any[]>("WorkSchedules?schedule_date=" + schedule_date)
    .then(({ data }) => data);

export const getRouteById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`Routes/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const addRoute = (payload: any): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Routes`, payload)
    .then((data: any) => data.data.data);
};

export const addRouteSite = (payload: any): Promise<any[]> => {
  return httpApi
    .post<any[]>(`RouteSites`, payload)
    .then((data: any) => data.data.data);
};
export const updateRouteSite = (id: any, payload: any): Promise<any[]> => {
  return httpApi
    .put<any[]>(`RouteSites/${id}`, payload)
    .then((data: any) => data.data.data);
};
export const getRouteSite = (id: any): Promise<any[]> => {
  return httpApi
    .get<any[]>(`RouteSites/${id}`)
    .then((data: any) => data.data.data);
};

export const reOrderSites = (id: any, payload: any): Promise<any[]> => {
  return httpApi
    .put<any[]>(`/RouteSites/ReOrder/${id}`, payload)
    .then((data: any) => data.data.data);
};

export const deleteRouteSite = async (id: number): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(`RouteSites/${id}`, {
    data: { hmac_time: time },
  });

  return httpApi.delete<any>(`RouteSites/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};

export const deleteDriver = async (
  routeId: any,
  driverId: any
): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Routes/${routeId}/DeleteDriverToRoute/${driverId}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<any>(
    `Routes/${routeId}/DeleteDriverToRoute/${driverId}`,
    {
      data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
    }
  );
};
export const deleteVehicleFromRoute = async (id: number): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Routes/DeleteVehicleToRoute/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<any>(`Routes/DeleteVehicleToRoute/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};
export const deleteVehicle = async (id: number): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(`RouteSites/${id}`, {
    data: { hmac_time: time },
  });

  return httpApi.delete<any>(`RouteSites/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};
export const updateRoute = (routeId: any, payload: any): Promise<any[]> => {
  delete payload?.id;
  return httpApi
    .put<any[]>(`Routes/${routeId}`, payload)
    .then((data: any) => data.data.data);
};

export const assignVehicleToRoute = (
  routeId: any,
  payload: { vehicle_id: any }
): Promise<any[]> => {
  return httpApi
    .put<any[]>(`Routes/AssignVehicleToRoute/${routeId}`, payload)
    .then((data: any) => data.data.data);
};

export const assignDriverToRoute = (
  routeId: any,
  payload: { driver_id: any }
): Promise<any[]> => {
  return httpApi
    .put<any[]>(`Routes/AssignDriverToRoute/${routeId}`, payload)
    .then((data: any) => data.data.data);
};

export const deleteRoute = async (id: number): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(`Routes/${id}`, {
    data: { hmac_time: time },
  });

  return httpApi.delete<any>(`Routes/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};

export const deleteVehicleToRoute = async (id: number): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<any>(
    `Routes/DeleteVehicleToRoute/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<any>(`Routes/DeleteVehicleToRoute/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.data },
  });
};
