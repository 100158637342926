import { useEffect, useState } from 'react'
import { Button } from 'antd'
import { RfScannerIcon } from 'icons/Icons'
import { attachRFID, detachRFID, getRFIDReader, getRFIDReaders } from 'api/shipping.api'
import { updateAllowBarcodeScanState, updateRfidScannerConnected } from 'store/slices/shippingDocumentSlice'
import { notificationController } from 'controllers/notificationController'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { RFIDReaderData } from 'domain/Shipment'
import { useNavigate, useParams } from 'react-router-dom'
import WifiIcon from 'icons/svg/WifiIcon'
import RfidListIcon from 'icons/svg/RfidListIcon'
import './ScannerList.css'
import { capitalize } from 'lodash'
import RfidAttachModelShared from 'components/common/RfidAttachModelShared'
interface RfidItem {
  attached: string;
  label: string;
  key: number
}
const ScannersList = () => {
  const [rfidReaders, setRfidReaders] = useState<ItemType[]>([])
  const [rfidDropDown, setRfidDropDown] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedReader, setSelectedReader] = useState<RfidItem>();
  const [rfidAttachModelVisible, setRfidAttachModelVisible] = useState<any>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { allow_barcode_Scanning, rfid_Scanner_connected } = useAppSelector((state) => state.shippingDocument);

  const fetchRFIDReaders = () => {
    getRFIDReader()
      .then((data: RFIDReaderData[]) => {
        const items = data.map((item: RFIDReaderData) => ({
          label: item.description,
          attached: item.attached,
          key: item.id,
        }))
        const isScannerConnected = items.some((item) => item.attached)
        dispatch(updateRfidScannerConnected(isScannerConnected))
        setRfidReaders(items);
      })
      .catch((err) => {
        console.error("Error fetching RFID reader:", err);
      });
  }

  const handleBarcodeScanner = () => {
    navigate(`shipment-document/details/${id}/barcode`);
    dispatch(updateAllowBarcodeScanState(!allow_barcode_Scanning))
    notificationController.success({
      message: `Barcode Scanner ${allow_barcode_Scanning ? 'Activated' : 'DeActivated'} Successfully`
    })
  }

  const handleRfidScanner = (rfid: RfidItem) => {
    if (rfid.attached) {
      detachRFID({ rfid_reader_id: rfid.key }).then(
        () => {
          fetchRFIDReaders()
          setRfidDropDown(false)
          notificationController.success({
            message: 'RFID Scanner Disconnected Successfully',
          });
        },
        (err: any) => {
          setRfidDropDown(false)
          notificationController.error({ message: err.message });
        })
    } else {
      setRfidDropDown(false)
      setSelectedReader(rfid)
      setRfidAttachModelVisible(true)
    }
  }

  useEffect(() => {
    fetchRFIDReaders()
  }, []);

  return (
    <>
      {rfidDropDown && (
        <div
          onClick={() => setRfidDropDown(false)}
          className='overlay'
        />
      )}
      <div className="justify-center">
        <div className="icon-container">
          <Button onClick={handleBarcodeScanner} type="link">
            <RfScannerIcon color={!allow_barcode_Scanning ? '#268959' : '#BBBBBB'} />
          </Button>
        </div>
        <div className="px-4">
          <Button type="link"
            onClick={() => setRfidDropDown(!rfidDropDown)}
          >
            <RfidListIcon filled={rfid_Scanner_connected} />
          </Button>
          {rfidDropDown && (
            <div className="dropdown-overlay">
              {rfidReaders?.map((item: any) => {
                return (
                  <ul className="dropdown-list">
                    <li
                      onClick={() => handleRfidScanner(item)}
                      className={`${item.attached && 'selected-item'}  dropdown-iem`}>
                      <WifiIcon filled={item.attached ? true : false} />
                      RFID Scanner {capitalize(item.label)}
                    </li>
                  </ul>
                )
              })}
            </div>
          )}
        </div>
      </div>
      {rfidAttachModelVisible &&
        <RfidAttachModelShared
          isLoading={isLoading}
          defaultReader={selectedReader}
          setIsLoading={setIsLoading}
          rfidAttachModelVisible={rfidAttachModelVisible}
          setRFIDAttachRequired={setIsLoading}
          reloadScanners={fetchRFIDReaders}
          setRfidAttachModelVisible={setRfidAttachModelVisible}
          attachRFID={attachRFID}
          getRFIDReaders={getRFIDReaders}
          btnText={"Attach"}
        ></RfidAttachModelShared>
      }
    </>
  )
}

export default ScannersList