import moment from "moment";
import { useState } from "react";
import styles from "./WorkSchedulesHeader.module.css";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import WorkScheduleDatePicker from "./WorkScheduleDatePicker";
import WorkScheduleMonthPicker from "./WorkScheduleMonthPicker";
import { Button } from "antd";
import { setExpandWorkSchedule } from "store/slices/workScheduleSlice";

export default function WorkSchedulesHeader() {
  const dispatch = useAppDispatch()
  const selectedDate = useAppSelector(
    (state) => state.workSchedule.selectedDate
  );

  const [selectedMonth, setSelectedMonth] = useState(
    moment(selectedDate).format("MMMM YYYY")
  );

  const [selectedDatePicker, setSelectedDatePicker] =
    useState(moment(selectedDate).clone().weekday(1).format("YYYY-MM-DD"));

  return (
    <>
   <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "80%"}}>
   <div className={styles.workSchedulesHeaderContainer}>
      <WorkScheduleMonthPicker
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        setSelectedDatePicker={setSelectedDatePicker}
      />
      <WorkScheduleDatePicker
        setSelectedMonth={setSelectedMonth}
        selectedDatePicker={selectedDatePicker}
        setSelectedDatePicker={setSelectedDatePicker}
      />
    </div>
    <div>
      <Button type="primary" onClick={() => {
        dispatch(setExpandWorkSchedule())
      }}>
        Toggle
      </Button>
    </div>
   </div>
    </>
  );
}
