import { httpApi } from "api/http.api";

export const getWareHouseOverview = (): Promise<any[]> =>
  httpApi.get<any[]>("WareHouseView/Clients").then(({ data }) => data);

export const getPendingWarehouseReturnClients = (): Promise<any[]> =>
  httpApi.get<any[]>("PendingWarehouseReturn/Clients")
  .then((data: any) => data["data"])
  .then(({ data }) => data);

export const getWareHouseByCompany = (): Promise<any[]> =>
  httpApi.get<any[]>("WareHouseView/Garments").then(({ data }) => data);

export const getOpenWareHouseProfessionals = (
  companyId: any = null
): Promise<any[]> => {
  return httpApi
    .get<any[]>(`PendingWarehouseReturn/Professionals?client_id=${companyId}`)
    .then((data: any) => data);
};
export const getOpenWareHouseAllProfessionals = (
  companyId: any = 0
): Promise<any[]> => {
  return httpApi
    .get<any[]>(`PendingWarehouseReturn/Professionals?client_id=${0}`)
    .then((data: any) => data.data.data);
};
export const setInvoice = (data: any = null): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Accounting/SetInvoice`, data)
    .then((data: any) => data.data.data);
};
export const setReceipt = (data: any = null): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Accounting/SetReceipt`, data)
    .then((data: any) => data.data.data);
};

export const setRefund = (data: any = null): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Accounting/SetRefund`, data)
    .then((data: any) => data.data.data);
};

export const getWareHouseByProfessional = (data: any): Promise<any[]> => {
  return httpApi
    .get<any[]>(
      `/PendingWarehouseReturn/Professionals?client_id=${data.affiliationClientId}${data.filterText}`
    )
    .then(({ data }) => data);
};

export const getAllWareHouseByProfessional = (data: any): Promise<any[]> => {
  console.log("filterText", data.filterText);
  
  return httpApi.get<any[]>(
    `PendingWarehouseReturn/GarmentsOfProfessional2?client_id=${data.client_id}&professional_id=${data.professional_id}${data.filterText}&active=${data.active}`)
    .then(({ data }) => {
      return data
    });
}

export const getAffiliationsOfGarment = (data: any): Promise<any[]> => {
  const clientId = Number(data.client_id);
  return httpApi
    .get<any[]>(
      `PendingWarehouseReturn/AffiliationsOfGarment2?client_id=${clientId}&professional_id=${data.professional_id}&garment_id=${data.garment_id}&order_id=${data.order_id}${data.filterText}`
    )
    .then(({ data }) => data);
};

export const getCompanyWareHouseData = (clientIds: any): Promise<any[]> => {
  return httpApi
    .get<any[]>(`WareHouseView/Garments/${clientIds}`)
    .then((data: any) => data?.data?.data);
};

export const postReadyForAffiliation = (data: any): Promise<any[]> => {
  return httpApi
    .post<any[]>(`Affiliations/ChangeAffiliationWareHouseStatusToReady`, data)
    .then(({ data }) => data);
};

export const printAfflication = (data: any): Promise<any[]> => {
  return httpApi
    .post<any[]>(`WareHouseView/ExportBarcodes/`, data)
    .then(({ data }) => data);
};
