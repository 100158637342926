import moment from "moment";
import { httpApi } from "api/http.api";
import { Vehicles, VehiclesRequest, VehiclesResponse } from "domain/Vehicles";

/**
 *
 * @returns Vehicles[]
 */
export const getVehiclesList = (): Promise<Vehicles[]> =>
  httpApi.get<Vehicles[]>("AccountVehicles").then(({ data }) => data);

export const addVehicles = (
  garmentsPayload: VehiclesRequest
): Promise<VehiclesResponse> =>
  httpApi
    .post<VehiclesResponse>("AccountVehicles", {
      ...garmentsPayload,
    })
    .then(({ data }) => data);

export const updateVehicles = (
  id: any,
  vehiclesPayload: VehiclesRequest
): Promise<VehiclesResponse> =>
  httpApi
    .put<VehiclesRequest>("AccountVehicles/" + id, {
      ...vehiclesPayload,
    })
    .then(({ data }) => data);

export const getVehiclesById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`AccountVehicles/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const deleteVehicles = async (id: number): Promise<VehiclesResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<VehiclesResponse>(
    `AccountVehicles/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<VehiclesResponse>(`AccountVehicles/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};
