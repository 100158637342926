import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TabState {
  selectedTab: string;
}

const initialState: TabState = {
  selectedTab: "",
};


const tabSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    setSelectedTab: (state, action: PayloadAction<string>) => {
      state.selectedTab = action.payload;
    },
    clearSelectedTab: (state) => {
      state.selectedTab = "";
    },
  },
});

export const { setSelectedTab, clearSelectedTab } = tabSlice.actions;
export const getSelectedTab = (state: any) => state.refreshTab.selectedTab;

export default tabSlice.reducer;
