import moment from "moment";
import { httpApi } from "api/http.api";
import {
  GarmentColor,
  GarmentColorRequest,
  GarmentColorResponse,
} from "domain/GarmentColor";

/**
 *
 * @returns GarmentColor[]
 */
export const getGarmentColorList = (
  is_fetch_all = true
): Promise<GarmentColor[]> =>
  httpApi
    .get<GarmentColor[]>("GarmentColors?is_fetch_all=" + is_fetch_all)
    .then(({ data }) => data);

export const addGarmentColor = (
  garmentsPayload: GarmentColorRequest
): Promise<GarmentColorResponse> =>
  httpApi
    .post<GarmentColorResponse>("GarmentColors", {
      ...garmentsPayload,
    })
    .then(({ data }) => data);

export const updateGarmentColor = (
  id: any,
  garmentColorPayload: GarmentColorRequest
): Promise<GarmentColorResponse> =>
  httpApi
    .put<GarmentColorRequest>("GarmentColors/" + id, {
      ...garmentColorPayload,
    })
    .then(({ data }) => data);

export const getGarmentColorById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`GarmentColors/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const deleteGarmentColor = async (
  id: number
): Promise<GarmentColorResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<GarmentColorResponse>(
    `GarmentColors/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<GarmentColorResponse>(`GarmentColors/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};
