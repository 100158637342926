import moment from "moment";
import { httpApi } from "api/http.api";
import {
  GarmentSizeType,
  GarmentSizeTypeRequest,
  GarmentSizeTypeResponse,
} from "domain/GarmentSizeType";

/**
 *
 * @returns GarmentSizeType[]
 */
export const getGarmentSizeTypeList = (
  is_fetch_all = true
): Promise<GarmentSizeType[]> =>
  httpApi
    .get<GarmentSizeType[]>("GarmentSizeTypes?is_fetch_all=" + is_fetch_all)
    .then(({ data }) => data);

export const addGarmentSizeType = (
  garmentsPayload: GarmentSizeTypeRequest
): Promise<GarmentSizeTypeResponse> =>
  httpApi
    .post<GarmentSizeTypeResponse>("GarmentSizeTypes", {
      ...garmentsPayload,
    })
    .then(({ data }) => data);

export const updateGarmentSizeType = (
  id: any,
  garmentSizeTypePayload: GarmentSizeTypeRequest
): Promise<GarmentSizeTypeResponse> =>
  httpApi
    .put<GarmentSizeTypeRequest>("GarmentSizeTypes/" + id, {
      ...garmentSizeTypePayload,
    })
    .then(({ data }) => data);

export const getGarmentSizeTypeById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`GarmentSizeType/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const deleteGarmentSizeType = async (
  id: number
): Promise<GarmentSizeTypeResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<GarmentSizeTypeResponse>(
    `GarmentSizeTypes/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<GarmentSizeTypeResponse>(`GarmentSizeTypes/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};
