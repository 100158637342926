import moment from "moment";
import { httpApi } from "api/http.api";
import {
  PriceListItemTypes,
  PriceListItemTypesRequest,
  PriceListItemTypesResponse,
} from "domain/priceListItemTypesInterface";

/**
 *
 * @returns PriceListItemTypes[]
 */
export const getPriceListItemTypesList = (): Promise<PriceListItemTypes[]> =>
  httpApi.get<PriceListItemTypes[]>("PriceListItemTypes").then(({ data }) => data);

export const addPriceListItemTypes = (
  garmentsPayload: PriceListItemTypesRequest
): Promise<PriceListItemTypesResponse> =>
  httpApi
    .post<PriceListItemTypesResponse>("PriceListItemTypes", {
      ...garmentsPayload,
    })
    .then(({ data }) => data);

export const updatePriceListItemTypes = (
  id: any,
  incomingSoilPayload: PriceListItemTypesRequest
): Promise<PriceListItemTypesResponse> =>
  httpApi
    .put<PriceListItemTypesRequest>("PriceListItemTypes/" + id, {
      ...incomingSoilPayload,
    })
    .then(({ data }) => data);

export const getPriceListItemTypesById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`PriceListItemTypes/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const deletePriceListItemTypes = async (
  id: number
): Promise<any> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<PriceListItemTypesResponse>(
    `PriceListItemTypes/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<PriceListItemTypesResponse>(`PriceListItemTypes/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};
