import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addGarmentSizeType,
  deleteGarmentSizeType,
  getGarmentSizeTypeList,
  updateGarmentSizeType,
} from "api/garmentSizeType.api";
import { notificationController } from "controllers/notificationController";
import {
  GarmentSizeType,
  GarmentSizeTypeRequest,
} from "domain/GarmentSizeType";

export interface GarmentSizeTypeSlice {
  garmentSizeTypeList: GarmentSizeType[] | null;
}

const initialState: GarmentSizeTypeSlice = {
  garmentSizeTypeList: null,
};

export const doGetGarmentSizeType = createAsyncThunk(
  "garmentSizeType/doGetGarmentSizeType",
  async (args: any) =>
    getGarmentSizeTypeList(args).then((res: any) => {
      return res;
    })
);

export const doAddGarmentSizeType = createAsyncThunk(
  "garmentSizeType/doAddGarment",
  async (professionalPayload: GarmentSizeTypeRequest, { dispatch }) =>
    addGarmentSizeType(professionalPayload).then((res) => {
      dispatch(doGetGarmentSizeType(false));
      return res;
    })
);
export const doUpdateGarmentSizeType = createAsyncThunk(
  "garmentSizeType/doUpdateGarment",
  async (professionalPayload: any, { dispatch }) =>
    updateGarmentSizeType(professionalPayload.id, professionalPayload).then(
      (res) => {
        dispatch(doGetGarmentSizeType(false));
        return res;
      }
    )
);

export const doDeleteGarmentSizeType = createAsyncThunk(
  "garmentSizeType/doDeleteGarmentSizeType",
  async (garmentId: any, { dispatch }) => {
    deleteGarmentSizeType(garmentId).then(
      (res) => {
        notificationController.error({
          message: "Size Type Deleted successfully.",
        });
        dispatch(doGetGarmentSizeType(false));
        return res;
      },
      (error) => {
        notificationController.error({
          message: error.toString(),
        });
      }
    );
  }
);

const garmentSizeTypeSlice = createSlice({
  name: "garmentSizeType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetGarmentSizeType.fulfilled, (state, action) => {
      state.garmentSizeTypeList = action.payload.data;
    });
  },
});

export default garmentSizeTypeSlice.reducer;
