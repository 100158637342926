import { getRFIDReader } from 'api/shippingV3.api'
import { RFIDReaderData } from 'domain/Shipment'
import { setRfidReaders, updateRfidScannerConnected } from 'store/slices/shippingDocumentSliceV3'
import { useAppDispatch } from './reduxHooks';


const useFetchRFIDReaders = () => {
    const dispatch = useAppDispatch();

    const fetchRFIDReaders = () => {
        getRFIDReader()
            .then((data: RFIDReaderData[]) => {
                const items = data.map((item: RFIDReaderData) => ({
                    label: item.description,
                    attached: item.attached,
                    attached_to_me: item.attached_to_me,
                    key: item.id,
                }))
                const isScannerConnected = items.some((item) => item.attached == "1")
                dispatch(updateRfidScannerConnected(isScannerConnected))
                dispatch(setRfidReaders(items));
            })
            .catch((err) => {
                console.error("Error fetching RFID reader:", err);
            });
    }

    return fetchRFIDReaders;
}

export default useFetchRFIDReaders