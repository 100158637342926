import moment from "moment";
import { httpApi } from "api/http.api";
import {
  OnsiteLaundry,
  OnsiteLaundryRequest,
  OnsiteLaundryResponse,
} from "domain/OnsiteLaundry";

/**
 *
 * @returns OnsiteLaundry[]
 */
export const getOnsiteLaundryList = (): Promise<OnsiteLaundry[]> =>
  httpApi.get<OnsiteLaundry[]>("OnSiteLaundry").then(({ data }) => data);

export const addOnsiteLaundry = (
  garmentsPayload: OnsiteLaundryRequest
): Promise<OnsiteLaundryResponse> =>
  httpApi
    .post<OnsiteLaundryResponse>("OnSiteLaundry", {
      ...garmentsPayload,
    })
    .then(({ data }) => data);

export const updateOnsiteLaundry = (
  id: any,
  onsiteLaundryPayload: OnsiteLaundryRequest
): Promise<OnsiteLaundryResponse> =>
  httpApi
    .put<OnsiteLaundryRequest>("OnsiteLaundry/" + id, {
      ...onsiteLaundryPayload,
    })
    .then(({ data }) => data);

export const getOnsiteLaundryById = (id: any): Promise<any> =>
  httpApi
    .get<any>(`OnsiteLaundry/${id}`)
    .then(({ data }) => data["data"][0])
    .then((data) => data);

export const deleteOnsiteLaundry = async (
  id: number
): Promise<OnsiteLaundryResponse> => {
  let time = moment().toISOString();
  let hmac_token: any = await httpApi.delete<OnsiteLaundryResponse>(
    `OnsiteLaundry/${id}`,
    {
      data: { hmac_time: time },
    }
  );

  return httpApi.delete<OnsiteLaundryResponse>(`OnsiteLaundry/${id}`, {
    data: { hmac_time: time, hmac: hmac_token?.data?.hmac },
  });
};
